import { DeleteFilled, FireFilled, MoreOutlined, RightOutlined } from '@ant-design/icons';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Button, Collapse, Dropdown, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from 'react-redux';

import { STANDARD_ERROR_MESSAGE } from '../../constants/message.js';
import UTILITY from '../../lib/utility_service';
import Utils from '../../utils';
import { Spiner } from '../Bloc';
import CustomFlowStripe from './CustomFlowStripe'

const clientId = Utils.PAYPAL_CLIENT_ID;


const paypal = require('../../assets/img/billing/paypal.png')
const credit = require('../../assets/img/billing/credit-card.png')


const stripePromise = loadStripe(Utils.stripe_pub_key);


export function PaymentMethodList(
  {
    paymentMethods,
    setSelectedPaymentMethod,
    payment,
  }
) {
  const [loadingAction, setLoandingAction] = useState({})
  const [loadingActionDelete, setLoadingActionDelete] = useState({})
  const { messageApi } = useSelector(app => app.core)

  function Method({ data }) {
    const items = [
      {
        label: 'Delete',
        key: '2',
        icon: <DeleteFilled />,
        danger: true,
        // disabled: !!data?.isPrimary,
      }
    ];
    if (!data.isPrimary && !items?.find(item => item.label === "Set as primary")) {
      items.push({
        label: 'Set as primary',
        key: '3',
        icon: <FireFilled />,
      })
    }
    async function handleMenuClick(e) {
      let result;
      if (e.key === "2") {
        setLoadingActionDelete({ [data.id]: true })
        result = await UTILITY.deletePaymentMethod({ paymentMethodId: data.id, delete: true })
        setLoadingActionDelete({})
      }
      if (e.key === "3") {
        setLoandingAction({ [data.id]: true })
        result = await UTILITY.deletePaymentMethod({ paymentMethodId: data.id, update: true })
        setLoandingAction({})
      }
      if (!result?.error) {
        messageApi.success('Done!')
      } else {
        messageApi.error(result?.message || STANDARD_ERROR_MESSAGE)
      }
      UTILITY.fetchPaymentMethod()
    }

    const menuProps = {
      items: items.reverse(),
      onClick: handleMenuClick,
    };
    return (
      <div
        className='border-[1px] hover:rounded-2xl hover:border-[1.7px] transition-500 transition-all  border-slate-300 dark:border-gray-500  my-3 cursor-pointer rounded px-4 py-3 flex justify-between items-center'
        onClick={() => {
          if (payment) setSelectedPaymentMethod(data)
        }}
      >
        <div className="flex gap-3 items-center">
          <img
            className="w-[40px]"
            alt="payment method icon"
            src={data?.type === 'paypal' ? paypal : credit}
          />
          <div>
            <span className="font-semibold">
              {data?.type === 'paypal' ? "PayPal" : "Credit Card"} </span>
            <br />
            <span> {data.email || '**** **** **** ' + data?.last4} </span>
          </div>
        </div>
        {payment ?
          <RightOutlined />
          :
          <Dropdown trigger={['click']} menu={menuProps}>
            {loadingAction[data.id] || loadingActionDelete[data.id] ?
              <Spiner fontSize={15} />
              :
              <MoreOutlined />
            }
          </Dropdown>
        }
      </div>
    )
  }

  return (
    <div>
      {paymentMethods.map((m, i) => {
        return <Method data={m} key={m.id} />
      })
      }
    </div>
  )
}

export const Stripe = ({ reload }) => {
  const [options, setOptions] = useState({})
  useEffect(() => {
    UTILITY.checkoutStripe().then((clientSecret) => {
        const localOp = {
          appearance: {/*...*/},
        }
        localOp.clientSecret = clientSecret
        setOptions(localOp)
      }
    );
  }, []);

  return (
    <>
      {options && options?.clientSecret ?
        <Elements stripe={stripePromise} options={options}>
          <CustomFlowStripe reload={reload} />
        </Elements>
        :
        <Spiner fontSize={24} />
      }
    </>
  )
}
 

export function CreateSubcription({ open, setOpen, payAndDeploy }) {
  const paymentMethods = useSelector(app => app.core?.paymentMethods?.filter(m => !!m.isValidated))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!paymentMethods) {
      setLoading(true)
      UTILITY.fetchPaymentMethod().then((data) => {
        setLoading(false)
      });
    }
  }, []);

  function createPaymentAndSubscription(paymentMethod) {
    return payAndDeploy(paymentMethod?.id)
  }
  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      centered
      closeIcon={false}
      className='mPadding'
      width={600}
      open={open}
    >
      <div className=''>
        <div className='bg-primary py-5 px-6 text-white'>
          <div className=''>
            <h4 className="font-bold text-base xl:text-xl">
              Select a payment method
            </h4>
          </div>
        </div>
        <div className="py-5 px-6">
          <div>
            <PaymentMethodList
              paymentMethods={paymentMethods.filter(method => !!method.isPrimary)}
              setSelectedPaymentMethod={createPaymentAndSubscription}
              payment={true}
            />

          </div>
          <Collapse
            // defaultActiveKey={['1']} 
            // ghost 
            items={
              [
                {
                  key: '1',
                  label: 'Choose another payment method',
                  children: paymentMethods.filter(method => !method.isPrimary).length ?
                    <PaymentMethodList
                      paymentMethods={paymentMethods.filter(method => !method.isPrimary)}
                      setSelectedPaymentMethod={createPaymentAndSubscription}
                      payment={true}
                    />
                    :
                    <div className="font-semibold text-primary text-sm 2xl:text-base">
                      <i>
                        (No backup payment method found!)
                      </i>
                    </div>
                }
              ]
            }
          />

          <div className="py-5 flex gap-2 justify-end  px-6">
            <Button size="large" onClick={() => {
              setOpen(false);
            }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}



function RenderPaypal({ setError, data, reload }) {
  const [{ isPending }] = usePayPalScriptReducer();
  const { messageApi } = useSelector(app => app.core)
  return (
    <>
      {isPending ? "Loanding ..." : null}
      <PayPalButtons
        style={{ layout: "horizontal" }}
        createVaultSetupToken={() => {
          return UTILITY.createVaultResp(data).then((result) => {
            console.log('Vault data here ', result)
            return result.id
          });
        }}
        onCancel={() => {
          setError('Operation cancelled !')
        }}
        onError={(err) => {
          console.log('error paypal ***', err)
          setError('An error occurred on PayPal. Please check if your PayPal account is correctly configured.')
        }}
        onApprove={async({ vaultSetupToken }) => {
          return UTILITY.createVaultPayment({ ...data, vaultSetupToken }).then((result) => {
            if (result.exist) {
              messageApi.success(result?.message)
            }
            return reload()
          });
        }}
      />
    </>

  )
}



const PaypalWrap = ({ setError, reload }) => {
  const [data, setData] = useState(null)
  async function onCreatePaypalPaymentmethod(data) {
    const body = {
      paypal: data
    }
    // const result = await UserMgt.createPaymentMethod(body)
  }
  useEffect(() => {
    UTILITY.checkoutPaypal().then((params) => setData(params));
  }, []);

  return (
    <div>
      {data ?
        <PayPalScriptProvider
          options={{
            "client-id": clientId,
            "data-user-id-token": data?.id_token,
            components: "buttons",
            intent: "capture",
            vault: true,
            currency: "CAD",
          }}
        >
          <RenderPaypal
            setError={setError}
            data={data}
            reload={reload}
            onCreatePaypalPaymentmethod={onCreatePaypalPaymentmethod}
          />
        </PayPalScriptProvider>
        :
        "Loanding..."
      }
    </div>
  )
}


const methodType = [
  {
    isPaypal: false,
    title: "Add a credit card",
    desc: "We accept a Visa, MasterCard, American Express, UnionPay and Discover"
  },
  {
    isPaypal: true,
    title: "Connect Paypal",
    desc: "Connect your paypal account for automatic payments"
  }
]


export function AddMethodModal({ open, setOpen, reload }) {
  const [paymentData, setPaymentData] = useState({})
  const [errorM, setError] = useState(null)

  function Method({ data }) {
    return (
      <div
        className='border-[1px]  border-slate-300 dark:border-gray-500  my-3 cursor-pointer rounded px-4 py-3 flex justify-between items-center'
        onClick={() => {
          if (data?.isPaypal) {
            setPaymentData({ ...paymentData, type: "paypal" })
          } else {
            setPaymentData({ ...paymentData, type: "credit_cart" }) 
          }
        }}
      >
        <div className="flex gap-3 items-center">
          <img className="w-[40px]" alt="payment method icon" src={data?.isPaypal ? paypal : credit} />
          <div>
            <span className="font-semibold"> {data.title} </span>
            <br />
            <span> {data.desc} </span>
          </div>
        </div>

        <RightOutlined />
      </div>
    )
  }

  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      centered
      closeIcon={false}
      className='mPadding'
      width={600}
      open={open}
    >
      <div className=''>
        <div className='bg-primary py-5 px-6 text-white'>
          <div className=''>
            <h4 className="font-bold text-base xl:text-xl">
              Add a new payment method
            </h4>
            <p className='text-sm xl:text-base pt-2'>
              {!paymentData?.type ?
                "Select a Payment method type to add"
                :
                paymentData?.type === "credit_cart" ?
                  "Fill the form "
                  :
                  "Connect your PayPal"
              }
            </p>
          </div>
        </div>
        <div className="max-h-[82vh] overflow-y-auto ">
          <div className="overflow-y-auto text-sm 2xl:text-base">
            <div className="w-full px-6">
              {errorM ?
                <div className="pt-2">
                  <Alert
                    type="error"
                    showIcon
                    message={errorM}
                  />
                </div>
                :
                null
              }
              {!paymentData?.type ?
                <div className="py-5">
                  {methodType?.map((method, k) => (
                    <Method data={method} key={k} />
                  ))}
                </div>
                :
                <div className="pt-3" >
                  <span
                    className="font-semibold cursor-pointer flex items-center"
                    onClick={() => {
                      setPaymentData({})
                      setError(null)
                    }}
                  >
                    <FaArrowLeft /> &nbsp; Choose a different payment method
                  </span>
                  {paymentData?.type === "credit_cart" ?
                    <div className="py-3 credit">
                      <Stripe
                        reload={() => {
                          setOpen(false)
                          reload()
                          setPaymentData({});
                          setError(null);
                        }}
                      />
                    </div>
                    :
                    <div className="py-3 credit">
                      <span className="font-bold ">Let's set it up</span>
                      <br />
                      <p>
                        Sign in to PayPal by clicking on the button below and we will
                        add it as a recurring payment method to your account for feature use.
                      </p>
                      <div className="pt-3">
                        <Alert
                          type="info"
                          showIcon
                          message={<span className="font-semibold ">Informational Notes</span>}
                          description={<p>
                            <b>Notes:</b>&nbsp;
                            In order to connect PayPal as a saved payment method, you must initiate a transaction
                            of <b>$5.00</b> to verify your account, this charge will be applied to your a first bill and only need to be setup once.
                          </p>
                          }
                        />
                      </div>
                      <div className="pt-5 w-full mx-auto">
                        <PaypalWrap
                          setError={setError}
                          reload={() => {
                            setOpen(false)
                            reload()
                            setPaymentData({});
                            setError(null);
                          }}
                        />
                      </div>
                    </div>

                  }
                </div>
              }
            </div>
          </div>
          <div className="py-5 flex gap-2 justify-end  px-6">
            <Button size="large" onClick={() => {
              setError(null);
              setPaymentData({});
              setOpen(false);
            }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}


export function PaymentMethodWorker({ open, setOpen, payAndDeploy }) {
  const paymentMethods = useSelector(app => app.core?.paymentMethods?.filter(m => !!m.isValidated))
  const { messageApi } = useSelector(app => app.core)

  async function reload() {
    const data = await UTILITY.fetchPaymentMethod()
    setOpen(false)
    if (data && data?.paymentMethods?.length) {
      payAndDeploy(data?.paymentMethods?.[0]?.id)
    } else {
      messageApi.error(STANDARD_ERROR_MESSAGE)
    }
  }
  useEffect(() => {
    if (!paymentMethods) {
      UTILITY.fetchPaymentMethod().then((data) => {
      });
    }
  }, []);

  return (
    <div>
      {!paymentMethods || !paymentMethods?.length ?
        <AddMethodModal
          open={open}
          setOpen={setOpen}
          reload={reload}
          fromPayment={true}
        />
        :
        <CreateSubcription
          open={open}
          setOpen={setOpen}
          payAndDeploy={payAndDeploy}
        />
      }

    </div>
  )
}