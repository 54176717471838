import { Button, Form, Input, Tooltip } from 'antd';
import React from 'react'
import { IoIosCopy } from "react-icons/io";
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

import { CardLayout } from 'layouts/CardLayout';

import s1 from '../../../assets/img/share/s1.svg'
import s2 from '../../../assets/img/share/s2.svg'
import s3 from '../../../assets/img/share/s3.svg'
import s4 from '../../../assets/img/share/s4.svg'
import s5 from '../../../assets/img/share/s5.svg'
import { OkButton } from '../../../components/OkButton';

export default function GetReward() {

    const [textTtoooltip, setTool] = React.useState("Copy to clipboard")
    const onFinish = (values) => {
        console.log(values);
    };
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
    };

    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
    };
    
    const shareUrl = "https://dash.keepsec.ca/register"

    const onCopy = () => {

        navigator.clipboard.writeText(shareUrl);
        setTool("Copied")
    }
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div> 
                    <CardLayout className="px-6 py-7 mt-2  flex items-center justify-center relative ">
                        <div className='w-full'>
                            <span className="text-xl text-slate-800 font-semibold">
                                Invite a friend through email
                            </span>

                            <div className='pt-5 w-full'>
                                <Form
                                    {...layout}
                                    name="nest-messages"
                                    onFinish={onFinish}
                                    className='w-full'
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name={['Email']}
                                        rules={[
                                            {
                                            required: true,
                                            },
                                        ]}
                                        >
                                        <Input className='w-full h-10' type='email' placeholder='Enter email address'/>
                                    </Form.Item>
                                    <OkButton title="Invite" />

                                    {/* <Button className='h-10 w-28 mt-5 ' Sloading={creating} htmlType="submit">
                                        Invite
                                    </Button> */}
                                </Form> 
                            </div>
                        </div>
                    </CardLayout>

                    <CardLayout className="px-6 py-7 mt-2  flex items-center justify-center relative">
                        <div className='w-full'>
                            <span className="text-xl text-slate-800 font-semibold">
                                Share through social media
                            </span>
                            <div className='px-6 mt-2 mx-14 py-4 flex gap-6 justify-center items-center bg-gray-100'>
                                <span>
                                    {shareUrl}
                                </span>
                                <Tooltip title={textTtoooltip} color={"#011a43"}>
                                    <IoIosCopy onClick={onCopy} className='text-xl text-primary cursor-pointer' />
                                </Tooltip>
                            </div>
                            
                            <div className='pt-5 w-full flex gap-5 flex-wrap justify-center share'>
                                <FacebookShareButton url={shareUrl} children={<img src={s1} className='w-6' />} />
                                <TwitterShareButton url={shareUrl} children={<img src={s2} className='w-6' />} />
                                <LinkedinShareButton url={shareUrl} children={<img src={s3} className='w-6' />} />
                                <WhatsappShareButton url={shareUrl} children={<img src={s4} className='w-6' />} />
                                <PinterestShareButton url={shareUrl} children={ <img src={s5} className='w-6' />} />
                            </div>
                        </div>
                    </CardLayout>
                </div>  

                <div> 
                    <CardLayout className="px-6 py-7 mt-2  flex items-center justify-center relative">
                        <div className='w-full'>
                            <span className="text-xl text-slate-800 font-semibold">
                                Criteria for new referrals
                            </span>

                            <div className='pt-5 w-full'>
                                <p>
                                    1. <b>New users only.</b> The person you're sharing the link to cannot have any existing account.
                                    <br/>
                                    <br/>
                                    2. Rewards are only eligible for first-time purchases.
                                    <br/>
                                    <br/>
                                    3. The referred client does not refund and is <b>active for at least 30 days.</b>
                                    <br/>
                                    <br/>
                                    You will receive a 20% payment rounded of the purchased services plan price in form of tokens.
                                    <br/>
                                    <br/>
                                    The referred person will get a 5% discount on the service until cancelation. 
                                    <br/>
                                    Once you have collected tokens from your referrals, you wil be able to redeem them for items in the store.
                                    <br/>
                                    <br/>
                                    To be able to withdraw your tokens in money you will need a least 50 tokens, one token is equal to $1 CAD.
                                </p>
                                <br/>
                                <br/>
                                <a href='#' target='_blank' className='w-36'><Button  primary className='w-36 text-primary font-bold'>Terms of service</Button></a>
                            </div>
                        </div>
                    </CardLayout>
                </div>
            </div>
        </div>
    );
}

