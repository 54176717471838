import Utils from '../../../utils'
import deploymentHelper from './DeployHelper'

let onSetDeploymentState = () => {}
function replaceWith(
    replaceThis,
    withThis,
    mainString
) {
    return mainString.split(replaceThis).join(withThis)
}


function onDeploymentStateChanged(object){
    console.log('Deployment state change', object)
    onSetDeploymentState(object)
}


function startDeployProcess(template, values, functionState) {
    let stringified = JSON.stringify(template)
    onSetDeploymentState = functionState
    for (
        let index = 0;
        index < template.caproverOneClickApp.variables.length;
        index++
    ) {
        const element = template.caproverOneClickApp.variables[index]
        stringified = replaceWith(
            element.id,
            values[element.id] || '',
            stringified
        )
    }

    try {
        template = JSON.parse(stringified)
    } catch (error) {
        onDeploymentStateChanged({
            steps: ['Parsing the template'],
            error: `Cannot parse: ${stringified}\n\n\n\n${error}`,
            currentStep: 0,
        })
        return
    }

    // Dependency tree and sort apps using "createAppsArrayInOrder"
    // Call "createDeploymentStepPromises" for all apps.
    // populate steps as string[]
    // create promise chain with catch -> error. Each promise gets followed by currentStep++ promise
    // Start running promises,

    const apps = createAppsArrayInOrder(template)
    console.log('apps apps', apps)
    if (!apps) {
        onDeploymentStateChanged({
            steps: ['Parsing the template'],
            error: 'Cannot parse the template. Dependency tree cannot be resolved. Infinite loop!!',
            currentStep: 0,
        })
    } else if (apps.length === 0) {
        onDeploymentStateChanged({
            steps: ['Parsing the template'],
            error: 'Cannot parse the template. No services found!!',
            currentStep: 0,
        })
    } else {
        const steps = []
        for (let index = 0; index < apps.length; index++) {
            const appToDeploy = apps[index]
            steps.push(
                ...createDeploymentStepPromises(
                    appToDeploy.appName,
                    appToDeploy.service
                )
            )
        }

        const stepsTexts = ['Parsing the template']
        for (let index = 0; index < steps.length; index++) {
            stepsTexts.push(steps[index].stepName)
        }

        let currentStep = 0
        const onNextStepPromiseCreator = function () {
            return new Promise(function (resolve) {
                currentStep++
                onDeploymentStateChanged(
                    Utils.copyObject({
                        steps: stepsTexts,
                        error: '',
                        currentStep,
                        successMessage:
                            currentStep >= stepsTexts.length
                                ? template?.caproverOneClickApp
                                    .instructions.end
                                : undefined,
                    })
                )
                resolve()
            })
        }

        let promise = onNextStepPromiseCreator()

        for (let index = 0; index < steps.length; index++) {
            const element = steps[index]
            promise = promise
                .then(element.stepPromise)
                .then(onNextStepPromiseCreator)
        }

        promise.catch(function (error) {
            console.log('error error', error)
            onDeploymentStateChanged(
                Utils.copyObject({
                    steps: stepsTexts,
                    error: `Failed: ${error}`,
                    currentStep,
                })
            )
        })
    }
}


function createAppsArrayInOrder(template) {
    const apps = []

    let numberOfServices = 0
    const servicesMap = template?.services
    Object.keys(servicesMap).forEach(function (key) {
        numberOfServices++
    })

    let itCount = 0
    while (apps.length < numberOfServices) {
        if (itCount > numberOfServices) {
            // we are stuck in an infinite loop
            return undefined
        }
        itCount++

        Object.keys(servicesMap).forEach(function (appName) {
            for (let index = 0; index < apps.length; index++) {
                const element = apps[index]
                if (element.appName === appName) {
                    // already added
                    return
                }
            }

            let service = servicesMap[appName]

            const dependsOn = service.depends_on || []

            for (let index = 0; index < dependsOn.length; index++) {
                const element = dependsOn[index]
                let dependencyAlreadyAdded = false
                for (let j = 0; j < apps.length; j++) {
                    if (element === apps[j].appName) {
                        dependencyAlreadyAdded = true
                    }
                }

                if (!dependencyAlreadyAdded) return
            }

            apps.push({
                appName,
                service,
            })
        })
    }

    return apps
}




function createDeploymentStepPromises(
    appName,
    dockerComposeService
) {
    const promises = []

    promises.push({
        stepName: `Registering ${appName}`,
        stepPromise: function () {
            return deploymentHelper.createRegisterPromise(
                appName,
                dockerComposeService
            )
        },
    })

    promises.push({
        stepName: `Configuring ${appName} (volumes, ports, environmental variables)`,
        stepPromise: function () {
            return deploymentHelper.createConfigurationPromise(
                appName,
                dockerComposeService
            )
        },
    })

    promises.push({
        stepName: `Deploying ${appName} (might take up to a minute)`,
        stepPromise: function () {
            return deploymentHelper.createDeploymentPromise(
                appName,
                dockerComposeService
            )
        },
    })

    return promises
}


export default{
    startDeployProcess
}






































