import {  useNavigate } from "react-router-dom"
import { Steps, Spin,  Alert, Space } from 'antd'

//import '../../Step.css';
//import 'antd/dist/antd.css';

const Step = Steps.Step  

export function DeployProgress(props) {
    const navigate = useNavigate()

    function createSteps() {
        const steps = props.deploymentState?.steps
        const stepsInfo = []
        console.log('steps steps steps', steps)
        for (let index = 0; index < steps.length; index++) {
            stepsInfo.push({
                text: (
                    <p className="flex gap-2 items-center dark:text-sky-300 ">
                        <span className="flex items-center justify-center">
                            {index === props.deploymentState.currentStep &&
                                !props.deploymentState.error ? (
                                    <Spin />
                            ) : (
                                <div />
                            )}
                        </span>
                        <span className="text-[13px] font-[500] leading-4 xl:text-sm 2xl:text-base">
                            {steps[index]}
                        </span>
                    </p>
                ),
                key: steps[index],
                icon: undefined,
            })
        }

        return stepsInfo.map((s) => {
            return <Step key={s.key} icon={s.icon} title={s.text} />
        })
    }

    function isRunning() {
        const { successMessage, error } = props.deploymentState
        return !successMessage && !error
    }

    return (
        <>
            <div className="px-3 md:px-20 h-auto  webapp">

                <h1 className="font-bold text-xl xl:text-2xl 2xl:text-3xl pt-3 text-primary dark:text-darkTextColor">Deploying {props.appName} ({props.displayName})</h1>
                <p className="pt-5 font-semibold text-[13px] xl:text-sm 2xl:text-base">
                    This process takes a few minutes to
                    complete. DO NOT refresh this page and DO
                    NOT navigate away!
                </p>
                <div style={{ height: 20 }} />
                <Steps
                    status={
                        !!props.deploymentState?.error
                            ? 'error'
                            : undefined
                    }
                    direction="vertical"
                    current={
                        props.deploymentState?.currentStep
                    }
                >
                    {createSteps()}
                </Steps>
                <div className="pt-10">
                    {props.deploymentState.error &&
                        <div>
                            <Alert
                                message="Error"
                                description={props.deploymentState.error}
                                type="error"
                                showIcon
                            />
                            <div className="pt-10">
                                <button
                                    className="px-10 py-3 text-2sm font-bold text-white transition duration-150 ease-in-out bg-primary border border-white rounded focus:outline-none md:block hover:bg-primary hover:border-primary"
                                    onClick={()=>props.setDeploymentState(null)}
                                >
                                    Go Back &amp; Try Again
                                </button>
                            </div>  
                        </div> 
                    }
                    {props.deploymentState.successMessage  &&
                        <div>
                            <Alert
                                message="Success"
                                description={props.deploymentState.successMessage || "Deployment success !"}
                                type="success"
                                showIcon
                            />

                            <div className="pt-10">
                                <button
                                    className="px-10 py-3 text-2sm font-bold text-white transition duration-150 ease-in-out bg-primary border border-white rounded focus:outline-none md:block hover:bg-primary hover:border-primary"
                                    onClick={()=>navigate('/app-platform/kps/'+props.server_id+'/')}
                                >
                                    Go to list app page 
                                </button>
                            </div>  
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
