import { MdMoreVert } from "react-icons/md";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { Toast } from '../components/Notify';
import  UserMgt  from '../lib/user_managment';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import Emerald from '../assets/img/plan/Emerald.png'  
import Diamond from '../assets/img/plan/Diamond.png'  
import Ruby from '../assets/img/plan/Ruby.png'   
import Sapphire from '../assets/img/plan/Saphire.png'

import Jade from "../assets/img/blue/jade_img.png"
import Topaz from "../assets/img/blue/topaz_img.png"
import Amethyst from "../assets/img/blue/amethyst_img.png"
import Tanzanite from "../assets/img/blue/tanzanite_img.png"

import Bronze from '../assets/img/mini/bronze.png'  
import gold from '../assets/img/mini/gold.png'  
import silver from '../assets/img/mini/silver.png'  
import plutonium from '../assets/img/mini/plutonium.png'
import { CancelButton, OkButton } from "./OkButton";

const plan_imgs = {
    Emerald, 
    Diamond, 
    Ruby, 
    Sapphire,
    Amethyst, 
    Topaz, 
    Jade, 
    Tanzanite,
    Bronze,
    Gold: gold,
    Silver: silver,
    Plutonium: plutonium
}



export  function SubscriptiobCard({subscription}){

	const navigate = useNavigate()
    const [loandingcancel, setL] = useState(false)
    const [loandingActivate, setR] = useState(false)

    function onShow(){
        return window.$("#Cancelbill").modal('show')
    }
    function onHide(){
        return window.$("#Cancelbill").modal('hide') 
    }
   
    async function onCancel(){ 
        setL(true)
        const force = subscription?.PaylSubscriptionStatus == "PAYMENT_FAILED"
        const result = await UserMgt.update_subcription(subscription.PaypalSubscriptionId, subscription.Id, "suspend", force)
        console.log('result paypal cancel subscription', result)
        setL(false)
        if(result &&result.status === "error"){
                
            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })
        }else{  

             Toast.fire({
                icon: 'success',
                title: result.message
            })
            onShow()
            await UserMgt.get_user_souscription()

        }
    }
    async function onReactive(payload){
        
        setR(true)
        const result = await UserMgt.update_subcription(subscription.PaypalSubscriptionId, subscription.Id, "activate")
        console.log('result paypal cancel subscription', result)
        setR(false)
        if(result &&result.status === "error"){
                
            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })
        }else{  

             Toast.fire({
                icon: 'success',
                title: result.message
            })
            return await UserMgt.get_user_souscription()
            
        }
    }

    function onLink(){
        if(!subscription?.IsMini) return  navigate('/compute/vps/manage/'+subscription?.VpsId+"?act=up")
    }

	return(
		<div className="pt-10 bg-grey block  rounded-lg p-6 shadow-lg   dark:bg-bgPagedark dark:text-darkTextColor">
           <Alertcancel />
            <div>
                <div className='flex justify-between dark:border-gray-800 border-b-2 pb-5'>
                    <div className='flex gap-4'>
                        <div className="text-left">
                            <span className='text-xl font-semibold text-primary dark:text-darkTextColor'>{subscription?.OpenstackInstanceName}</span> <br/>
                            <span>{subscription?.OpenstackFlavorName || subscription?.openstackInstanceFlavor}</span>
                        </div>
                    </div>
                    <div className='flex gap-4 -mt-5'>
                       
                        <Menu className="szh-menu-container dark:bg-darkSiderbar -mt-6" menuButton={()=><MenuButton><MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/></MenuButton>} transition>
                         
                           
                        </Menu>
                    </div>
                </div>
                <div className='flex justify-between items-center pt-2'>
                    <div className=''>
	                    <div className='items-center flex pb-4 pt-2'>
	                    	<img src={plan_imgs[subscription.VpsTypeName]} alt="" className='w-10 h-10 6.' />
	                        <p className="pl-3 text-2xl font-bold">{subscription?.VpsTypeName}</p> 
	                    </div>

                        <span className="text-primary dark:text-darkTextColor"><span className="font-semibold"> $</span>  {subscription.VpsTypePrice} / Month</span> <br/>
                        <span className="text-xs">Renewal on {new Date(subscription.NextPaymentDate).toLocaleDateString()} </span> 
                    </div>
                    <div className=''>

                        <p className='text-right'>
                            {["activate", "ACTIVE", "ACTIVATED", "PAYMENT_FAILED"].includes(subscription.PaylSubscriptionStatus) ?
                                <>
                                    {subscription.Package_Type === "VPS" && subscription.PaylSubscriptionStatus != "PAYMENT_FAILED" ? 
                                        <span>
                                            <OkButton click title="Upgrade" fun={onLink} server={subscription}/>
                                            <br/>
                                        </span>

                                        :subscription.PaylSubscriptionStatus == "PAYMENT_FAILED" ?
                                            <div>
                                                <span className="text-red-700 font-extrabold">{subscription.PaylSubscriptionStatus}</span>
                                                <br/>
                                            </div>
                                        :
                                        null


                                    }
                                    <CancelButton 
                                        fun={onCancel}
                                        disabled={loandingcancel}
                                        title='Cancel'
                                        danger
                                    />
                                </>
                                :
                                <div className="text-center">
                                    <span className="text-red-700 font-extrabold">{subscription.PaylSubscriptionStatus}</span>
                                    <br />
                                    <div className="mt-3"/>
                                    {subscription.PaylSubscriptionStatus === "SUSPENDED" &&
                                    <OkButton customclassName="mt-3" title="Reactivate" click={true} action={"activate"} fun={onReactive} loanding={loandingActivate}/>
                                    }
                                </div>
                            }


                       
                        </p>

                   </div>
                </div>
            </div>
        </div>
	)
}




export function Alertcancel(){
    const [loanding, setloading] = useState(false)

    function onCancel(){
        return window.$("#Cancelbill").modal('hide')
    }

  
    return(

        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={"Cancelbill"} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              
              

                <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                    <span className="text-3xl">Info</span>

                    <p className="pt-5">
                        <span className="text-orange-600 font-semibold">Warning: </span> 
                        The server will be deleted at the end of the renewal, please contact the support if you want it to be deleted now.
                        <br/>
                    </p>
                    <div className="pt-10 flex items-center justify-center">
                        
                        <OkButton click title="Ok, Understood" fun={onCancel} loanding={false}/>
                        
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}
