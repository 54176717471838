import { DownOutlined } from '@ant-design/icons';
import { Divider, Input, InputNumber, Popconfirm, Radio, Select, Tooltip } from 'antd';
import { Button, Modal, Popover, Table } from 'antd';
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';

import hdd from '../../assets/img/blue/hdd.png'
import ssd from '../../assets/img/blue/ssd.png'
import { Spiner } from '../../components/Bloc'
import K8S from '../../lib/k8s';
import STORAGE from '../../lib/storage';
import UserMgt from '../../lib/user_managment';
import Utils from '../../utils'

function DetachModal({volume, setOpen, open, reloadVolumeList}){
	const [ongoing, setOngoing] = useState({})
	const {messageApi} = useSelector(app => app.core )

	const onDetach = async(instanceId) => {
		const data = {
			action: "detach",
			vlumeId: volume?.id,
			serverId: instanceId
		}
		setOngoing({[instanceId]: true})
		const result = await STORAGE.mkAction(data)
		await reloadVolumeList()
		setOngoing({})
		// setOpen(false)
		if(result && !result?.error){
			messageApi.open({
		        type: 'success',
		        content: 'Server were detached!',
		    });

		}else{
			messageApi.open({
		        type: 'error',
		        content: result?.message || 'An error occurs, please try again later or contact us.',
		    });
		}
	}

	const columns = [
	  {
	    title: 'Service Name',
	    dataIndex: 'DisplayName',
	    key: '',
	  },
	  {
	    title: 'Mounted path',
	    dataIndex: 'mountPoint',
	    key: '',
	    render: (text) => <a>{text}</a>
	  },
	  {
	    title: 'Action',
	    dataIndex: '',
	    key: 'action',
	    render: (_, record) => (
	    	<Button 
                className="bg-red-500" 
                danger
                loading={ongoing[record.OpenstackInstanceId]}
                style={{color: "white"}}
                // size="large" 
                // type="primary"  
                onClick={()=>onDetach(record?.OpenstackInstanceId)}
            >
                Detach
            </Button>
	    )
	  },
	];
	return(
		<Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={900}
	        	open={open}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Detach instances from volume name {volume?.name}
                        </h4>
                        <p className='text-sm xl:text-base'>
                            {/*Pick new size to increase. */}
                        </p>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
						<Table dataSource={volume?.servers} columns={columns} />;
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => {
	                          setOpen(false); 
	                        }}
	                    >
	                        Cancel
	                    </Button>
            	</div>
            </div>
	    </Modal>
	)
}
export function ModalIncreaseSize({open, setOpen, vlume, reloadVolumeList}){
	const [creating, setCreation] = useState(null)
	const [data, setData] = useState({})
	const [sizes, setSizeP] = useState({})
	const {messageApi} = useSelector(app => app.core )

	async function onDefined(){
		const dataResize = {
			action: "reSize",
			vlumeId: vlume?.id,
			...data
		}
		setCreation(true)
		const result = await STORAGE.mkAction(dataResize)
		if(result && !result?.error){
			// await Utils.sleep(500)
			await reloadVolumeList()
			setOpen(false)
			messageApi.open({
		        type: 'success',
		        content: 'Volume size upgraded!',
		    });

		}else{
			messageApi.open({
		        type: 'error',
		        content: result?.message || 'An error occurs, please try again later or contact us.',
		    });
		}
		setCreation(false)
	}
	return(
		<Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={900}
	        	open={open}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Increase volume nammed {vlume?.name}  size
                        </h4>
                        <p className='text-sm xl:text-base'>
                            Pick new size to increase. 
                        </p>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6">						
						<SizeCard setSizeP={setSizeP} data={data} setData={setData} />
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => {
	                					setData({})
	                          setOpen(false); 
	                        }}
	                    >
	                        Cancel
	                    </Button>
	                    <Button 
	                    	loading={creating} 
	                    	className="flex items-center gap-2" 
	                    	size="large" type="primary"  
	                    	onClick={() => {
	                            onDefined()
	                        }}
	                    >
	                        Set new size
	                    </Button>
            		
            	</div>
            </div>
	    </Modal>
	)
}

export function AttachVolumeModal({open, setOpen, vlume, reloadVolumeList, instances}){
	const [creating, setCreation] = useState(null)
	const [data, setData] = useState({})

	const list = useSelector(app => {
		const  kubernetes  = app.k8s?.kubernetes || []
		console.log('kuberneteskubernetes ', kubernetes)
		let k8sInstace = []
		for (let i = 0; i < kubernetes.length; i++) {
			const clusters =  kubernetes[i]?.clusters
			for (let j = 0; j < clusters.length; j++) {
				k8sInstace = k8sInstace.concat(clusters[j]?.instances)
			}
		}
		console.log('k8sInstace k8sInstace ', k8sInstace)
        return (instances || app.vps?.list?.filter(s => !s.service?.task_name !=="vpsmini") || []).concat(k8sInstace)
    })

	const {messageApi} = useSelector(app => app.core )

	useEffect(()=>{ 
        loadVps() 
        
    }, [])

    async function loadVps(){
        !list && await UserMgt.get_user_vps() &&  await K8S.getK8sInstance() 

    }

    async function onAttach(){
		const dataAttach = {
			action: "attach",
			vlumeId: vlume?.id,
			...data
		}
		setCreation(true)
		const result = await STORAGE.mkAction(dataAttach)
		if(result && !result?.error){
			// await Utils.sleep(500)
			await reloadVolumeList()
			setOpen(false)
			messageApi.open({
		        type: 'success',
		        content: 'Volume were attached!',
		      });

		}else{
			messageApi.open({
        type: 'error',
        content: result?.message || 'An error occurs, please try again later or contact us.',
      });
		}
		setCreation(false)
	}

	useEffect(()=>{
		setData({})
	}, [])
	return(
		<Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={500}
	        open={open}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Attach volume nammed {vlume?.name} 
                        </h4>
                        <p className='text-sm xl:text-base'>
                            Select new instance to attach. 
                        </p>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6 py-6">
                		<span className="font-bold">Select new instance here</span>
                		<Select
						    // showSearch
							size="large"
							// mode="multiple"
							value={data?.serverId}
							className="w-full rounded-none mt-2"
								// dropdownStyle={{borderRadius: "0px"}}
						    placeholder="Select a Service"
						    // optionFilterProp="children"
						    onChange={(selected) => {
						    	setData({...data, serverId: selected})
						    }}
						    // onSearch={onSearch}
						    // filterOption={filterOption}
						    options={list &&list.filter(vps => vps.VpsStatus !== "Deploying" 
						    		&& vps.VpsStatus !== "Pending" 
						    		&& vps.location?.name === vlume?.location?.name 
						    		&& !vlume?.servers?.map(inst => inst?.Id).includes(vps?.Id)
						    	).map(vps=>{
						    		return {value: vps.OpenstackInstanceId, label: vps?.DisplayName}
						    	})
						    }
						    required
						/>						
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => {
	                		  setData({})
	                          setOpen(false); 
	                        }}
	                    >
	                        Cancel
	                    </Button>
	                    <Button 
	                    	loading={creating} 
	                    	className="flex items-center gap-2" 
	                    	size="large" type="primary"  
	                    	onClick={() => {
	                            onAttach()
	                        }}
	                    >
	                        Attach
	                    </Button>
            		
            	</div>
            </div>
	    </Modal>
	)
}

export function AttachVpsToVolumeModal({open, setOpen, reloadVolumeList, instance}){
	const [creating, setCreation] = useState(null)
	const [data, setData] = useState({})
	const [volumes, setVols] = useState(null)

	const {messageApi} = useSelector(app => app.core )

    async function onAttach(){
		const dataAttach = {
			action: "attach",
			serverId: instance?.OpenstackInstanceId,
			...data
		}
		setCreation(true)
		const result = await STORAGE.mkAction(dataAttach)
		if(result && !result?.error){
			// await Utils.sleep(500)
			await reloadVolumeList()
			setOpen(false)
			messageApi.open({
		        type: 'success',
		        content: 'Volume were attached!',
		      });

		}else{
			messageApi.open({
        type: 'error',
        content: result?.message || 'An error occurs, please try again later or contact us.',
      });
		}
		setCreation(false)
	}

	async function listStorageVolume(){
		// setLoading(true)
        const data = await STORAGE.list()
        setVols(data?.volumes)
		// setLoading(false)
    }


	useEffect(()=>{ 
        listStorageVolume() 
    }, [])


	return(
		<Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={500}
	        open={open}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            Attach instance to volume 
                        </h4>
                        <p className='text-sm xl:text-base'>
                            Select new volume to attach. 
                        </p>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6 py-6">
                		<span className="font-bold">Select new volume here</span>
                		<Select
						    // showSearch
							size="large"
							value={data?.vlumeId}
							className="w-full rounded-none mt-2"
								// dropdownStyle={{borderRadius: "0px"}}
						    placeholder="Select a Service"
						    // optionFilterProp="children"
						    onChange={(selected) => setData({...data, vlumeId: selected})}
						    // onSearch={onSearch}
						    // filterOption={filterOption}
						    options={volumes &&volumes.filter(v => !v.serverId && v.location?.id === instance?.location?.id).map(volume=>{
					    		return {value: volume.id, label: volume?.name}
					    	})
						    }
						    required
						/>						
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => {
	                		  setData({})
	                          setOpen(false); 
	                        }}
	                    >
	                        Cancel
	                    </Button>
	                    <Button 
	                    	loading={creating} 
	                    	className="flex items-center gap-2" 
	                    	size="large" type="primary"  
	                    	onClick={() => {
	                            onAttach()
	                        }}
	                    >
	                        Attach
	                    </Button>
            		
            	</div>
            </div>
	    </Modal>
	)
}

export function SizeCard({setData, data, setSizeP}){
	const [sizes, setSize] = useState(null)
	async function getVolumeSize(){
		const data = await STORAGE.getVolumeSize()
		if(!data?.error){ 
			setSize(data?.sizes?.sort((a, b) => -b.id + a.id))
			setSizeP(data?.sizes?.sort((a, b) => -b.id + a.id))
		}
	}

	useEffect(()=>{
  		getVolumeSize()
  	}, [])

	function border(id, inspect){
		return id === inspect ? "border-primary bg-[#011a430d] " : "border-gray-200"
	}

	function getSizes(){
		const newSizes = sizes.filter(
			size => {
				if(!!size?.isCustom){
					return true
				}else{
					if(data?.type === "hdd") return !size.isSSD
					else return !!size.isSSD
				}
			}
		)
		return  newSizes
	}

	const renderCustPrice = () => {
		let p = data.customSize * 10 / 100
		if(data?.type === "hdd") p = p/2
	    if(data.customSize){
	    	return {
		    	val: p,
		    	hour: (p / 730.001).toFixed(2)
		    }
	    }
	    return {
	    	val: "__",
	    	hour: "__"
	    }
	};
	const renderCurency = (veryMin) => (
        <span className={`${!veryMin ? "text-xs": "text-[11px]"}  font-bold`}>
            {Utils.renderCurencyCode()}
        </span>
    )
	const price = (size) => size?.price || renderCustPrice().val
	return(
		<div className=" w-full">
			{sizes ?
				<div className=" w-full">
					<div className="mt-7 pb-5">
						<span className="font-semibold text-base 2xl:text-xl">
							SELECT VOLUME SIZE
						</span>
					</div>

					<Radio.Group 
						onChange={(e)=>setData({...data, size: e.target.value})} 
						value={data?.size} 
						className="w-full"
						required
					>
					<div className="flex flex-wrap justify-between ">
						{getSizes()?.map(size =>{
								return(
									<div key={size?.id} className="w-[205px] cursor-pointer" onClick={() => setData({...data, sizeId: size?.id})}>
										<div 
											className={`
												border
												border-2
												${border(size?.id, data?.sizeId)}
												px-6
												py-2
												w-full
											`}
										>
									    	<Radio className="hidden" value={size?.id}></Radio>
											<div className="text-sm w-full  2xl:text-base">
												<p className='font-bold text-center'>
		                              				<i className='-mt-5'>{renderCurency(true)}</i> 
		                              				<span className='text-center text-2xl'>
		                              					{!isNaN(price(size)) ? Utils.priceConvert(price(size)).toFixed(2) : price(size)}
		                              				</span>
		                              				/mo
													<br/>
													<span className='font-medium'>
														{renderCurency(true)} 
														{
															!size?.isCustom? 
																(Utils.priceConvert(size?.price / 730.001)).toFixed(2) 
																 
															: 
															!isNaN(renderCustPrice().hour) ?  Utils.priceConvert(renderCustPrice().hour).toFixed(2) :  renderCustPrice().hour
														}/hour 
													</span>
												</p>
											</div>
										</div>
										<div 
											className={`
												border-r-2
												border-l-2
												border-b-2
												${border(size?.id, data?.sizeId)}
												px-6
												h-11
												flex
												items-center
												w-full
											`}
										>
											<div className="text-sm w-full  2xl:text-base">
												{!size?.isCustom?
													<p className='font-bold text-center'>
		                                				{size?.gb}GB
													</p>
													:
													<Tooltip title="Minimum value is 10GB">
														<InputNumber 
															min={1}
															className="w-11/12"
															placeholder="Enter size in GB"
															onChange={(val) => setData({...data, customSize: val})}  
														/>
													</Tooltip>
												}
											</div>
										</div>
									</div>
								)
							})}
					</div>
			    </Radio.Group>
				</div>
				:
				<div className='flex justify-center py-10'>
            <Spiner fontSize={30} />
        </div>
      }
		</div>

	)
}

export function TableFunction({data, setEditVolume, reloadVolumeList, instances, isVps}){
	const [attachModalVisible, setAttachModalVisible] = useState(null)
	const [dettachModalVisible, setDettachModalVisible] = useState(null)

	const {messageApi} = useSelector(app => app.core )


	// const onDelete = async(vId) => {
	// 	const data = {
	// 		action: "forceDelete",
	// 		vlumeId: vId
	// 	}
	// 	const result = await STORAGE.mkAction(data)
	// 	reloadVolumeList()
	// 	if(result && !result?.error){
	// 		messageApi.open({
	// 	        type: 'success',
	// 	        content: 'Volume were deleted!',
	// 	    });

	// 	}else{
	// 		messageApi.open({
	// 	        type: 'error',
	// 	        content: result?.message || 'An error occurs, please try again later or contact us.',
	// 	    });
	// 	}
	// }
	const onFormat = async(vId) => {
		const data = {
			action: "format",
			vlumeId: vId
		}
		const result = await STORAGE.mkAction(data)
		reloadVolumeList()
		if(result && !result?.error){
			messageApi.open({
		        type: 'success',
		        content: result?.message || "Operaton is ongoing!",
		    });

		}else{
			messageApi.open({
		        type: 'error',
		        content: result?.message || 'An error occurs, please try again later or contact us.',
		    });
		}
	}
	const onDetach = async(volume) => {
		if(!isVps){
			return setDettachModalVisible(volume)
		}
		const data = {
			action: "detach",
			vlumeId: volume?.id
		}
		const result = await STORAGE.mkAction(data)
		reloadVolumeList()
		if(result && !result?.error){
			messageApi.open({
		        type: 'success',
		        content: 'Volume were detached!',
		    });

		}else{
			messageApi.open({
		        type: 'error',
		        content: result?.message || 'An error occurs, please try again later or contact us.',
		    });
		}
	}

	const Content = ({record}) =>{
	    return(
	        <div className="w-[10rem] text-center py-2 ">

	        	<div className="">
	        		<Popconfirm
			          placement="top"
			          title={"Format "+record?.name+" confirmation"}
			          description={"Do you realy want to format the volume named "+record?.name}
			          okText="Yes, I do"
			          onConfirm={() => onFormat(record?.id)}
			          cancelText="No, I don't"
			        >
			        	<Tooltip 
	                        title={!record?.servers?.length?
	                          	`Attach volume "${record?.name}" first before format it`
	                          	:
	                          	`Click to format.`
	                        }
	                    >
					        <Button  
								type="text"
								className="font-semibold text-primary w-full"
								disabled={!record?.servers?.length}
							>
								Format {record?.name}
							</Button>
						</Tooltip>
			        </Popconfirm>

	        		
	        	</div>
	        	<div className=""> 
	        		{
	        			record?.servers?.length || record?.mountPoint ?

				        <Button  
							type="text"
							className="font-semibold text-primary w-full"
			          		onClick={() => onDetach(record)}
						>
							Detach
						</Button>
	        			:
	        			null
					}
					{!record?.type?.includes('crypt') || (record?.type?.includes('crypt') && record?.servers?.length ) ?
						<Button  
							type="text"
							className="font-semibold text-primary w-full"
							onClick={() => setAttachModalVisible(record)}
						>
							Attach
						</Button>
						:
						null
					}
	        	</div>
	        	<div  className="">
	        		
		        		<Button  
							type="text"
							// disabled={record?.servers?.length || record?.mountPoint}
							onClick={()=> setEditVolume && setEditVolume(record)}
							className="font-semibold text-primary w-full"
						>
							Increase size
						</Button>
	        	</div>
	        	<div  className="">
	        		<Button  
						type="text"
						className="font-semibold text-primary w-full"
					>
						Take snapshot
					</Button>
	        	</div>
	        	<div  className=" pb-1">
	        		<Button  
						type="text"
						className="font-semibold text-primary w-full"
					>
						Config instructions
					</Button>
	        	</div>
	        	<div  className=" pt-1 border-t border-gray-100">
	        		<Tooltip 
                        title={record?.servers?.length || record?.mountPoint?
                          	`Detach volume "${record?.name}" first before delete`
                          	:
                          	`Delete this volume.`
                        }
                    >
		        		<Button  
		        			className="w-full font-semibold text-red-500 hover:text-red-500 hover:font-bold"
							type="text"
							disabled={record?.servers?.length}
							onClick={() =>Utils.dispatchToDelete(record)}
						>
							Delete 
						</Button>
                    </Tooltip>
	        	</div>
	        </div>
	    )
	}

	const col1 = [
        { 
        title: 'Name',
        dataIndex: '',
        render: (_, obj) => <div className="flex gap-2"> 
        	<img src={(obj?.type).includes("ssd") ? ssd : hdd} className="w-8 h-8" />
        	<span className="">
        		<b>{obj?.name}</b>
        		<br/>
        		{obj?.size?.isCustom ?
        			obj?.customSize
        			:
        			obj?.size?.gb
        		}GB
        		{" - "}
        		{
        			obj?.fileSystem
        		}
        		{" - "}
        		{obj?.config === "auto"?
        		"Auto config"
        		:
        		"Manual config"
        		}
        	</span>
        </div>,
      },
      {
        title: 'Services',
        dataIndex: '',
        render: (_, obj) => <div>
		        	{!isVps ?
			            <div>
				        	{obj?.servers?.length ?
					            <div>
						            {obj?.servers?.map(serv =>{
						            	return(
								            <span>
								            	<b>{serv?.mountPoint}</b>
								            	{" On "}
								          		<b>{serv?.DisplayName}</b>
								          		<br/>
								          	</span>
						            	)
						            })
						            }
					          	</div>
					          	:
					          	null
				        	} 
			          	</div>
			          	:
			          	<div>
			          		{obj.mountPoint}
			          	</div>
		        	} 

        </div>,
      },

      {
        title: 'Date',
        render: (_, obj) => <div> 
          {Utils.formatDate(obj?.createdDate, 'Do MMMM YYYY')}
        </div>,
        dataIndex: '',
      },
      {
        title: '',
        key: 'action',
        render: (_, record) => (
        	<div> 
	          	<Popover content={() =><Content record={record} />} title="">
		            <div className="w-7  flex items-end">   
		                More <DownOutlined className=" text-primary text-xl cursor-pointer" />
		            </div>
		        </Popover>
	        </div>
        ),
      },
    ];

	return(
		<div> 
			<AttachVolumeModal 
				open={attachModalVisible}
				setOpen={setAttachModalVisible}
				reloadVolumeList={reloadVolumeList}
				vlume={attachModalVisible}
				instances={instances}
			/>
			<DetachModal 
				open={dettachModalVisible}
				setOpen={setDettachModalVisible}
				reloadVolumeList={reloadVolumeList}
				volume={dettachModalVisible}
			/>
			<Table  
			      columns={col1} 
			      dataSource={data} 
			 />
		</div>
	)
}


