import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddButton } from "../../../components/AddButton";
import { ListAppWrapper, Spiner } from '../../../components/Bloc';
import { K8sCard } from '../../../components/k8s/K8sInstance';
import K8S from '../../../lib/k8s';
import { pages } from "../../preview/pages";
import { Preview } from "../../preview/PreviewSection";

console.log('RUN ');
const max = 5;

export default function ListKsKubHyBr() {
    const dispatch = useDispatch()

    const { kubernetes } = useSelector(app => app.k8s)

    console.log('kubernetes kubernetes ', kubernetes)
    const [start, setStart] = useState(0);
    const [page, setPage] = useState(1);
    const [toView, setToView] = useState([])
    const [check, setCheck] = useState({ index1: true })
    const { state } = useSelector(app => app.vps)

    async function loadK8s() {
        const r = await K8S.getK8sInstance()
        return r?.k8s
    }

    const onForward = () => {
        const newStart = start + max
        setStart(newStart)
        onAnimate(newStart)
        setPage(page + 1)
        try {

            const index = page <= 5 ? page + 1 : page - 5
            setCheck({ ['index' + index]: true })
        } catch (e) {
            console.log('can check', e)
        }
    }

    const setPage2 = (p) => {
        setPage(p + 1)

        const newStart = p * max > kubernetes.length ? kubernetes.length - max * p : max * p
        setStart(newStart)
        onAnimate(newStart)
        try {
            const index = p <= 5 ? p + 1 : p - 5
            setCheck({ ['index' + index]: true })
        } catch (e) {
            console.log('can check', e)
        }

    }

    const onGoBack = () => {
        const newStart = start == max ? 0 : start - max;
        setStart(newStart)
        onAnimate(newStart)
        setPage(() => {
            if (newStart == 0) return 1
            return page - 1
        })

        const index = newStart == 0 ? 1 : page > 5 ? page - 6 : page - 1
        setCheck({ ['index' + index]: true })
    }
    const onAnimate = async (init) => {

        const k8s = kubernetes || await loadK8s()

        const rangedK8s = k8s ? k8s.sort((a, b) => b.id - a.id) : []

        const v = []


        const v2 = rangedK8s.slice(init, init + max)

        for (let i = 0; i < v2.length; i++) {

            v.push(v2[i]);
            setToView(v)

            //}
        }
    }

    useEffect(() => {
        onAnimate(start, true)
        // loadK8s()
    }, [kubernetes])

    return (
        <>
            {toView && toView.length ?
                <ListAppWrapper check={check}>
                    <div className="px-3 -mt-6 2xl:-mt-3 relative">
                        <div className="container mx-auto max-w-full ">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 px-4 mb-16 gap-4 items-center">
                                {toView.slice(0, 2).map((k8, i) => <K8sCard onAnimate={() => onAnimate(start)} key={k8.Id} k8s={k8} />)}

                                <AddButton
                                    showPagintion
                                    onForward={onForward}
                                    link="/kubernetes/kskubhybr/deploy"
                                    title={"Deploy new"}
                                    canGoBaack={start >= max}
                                    onGoBack={onGoBack}
                                    list={kubernetes}
                                    max={max}
                                    setPage={setPage2}
                                    page={page}
                                    canGoForward={kubernetes && start + max < kubernetes.length}
                                />
                                {toView.slice(2).map((k8, i) => <K8sCard onAnimate={() => onAnimate(start)} key={k8.Id} k8s={k8} />)}
                            </div>

                        </div>
                    </div>
                </ListAppWrapper>
                :
                <Preview
                    page={pages['khks'] || {}}
                />
            }
            {!kubernetes &&
                <div className='flex justify-center'>
                    <Spiner fontSize={40} />
                </div>
            }
        </>
    )
}