import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Input, Space } from 'antd';
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';

import { AddMethodModal, PaymentMethodList } from '../../components/billing/Payment'
import { Spiner } from '../../components/Bloc'
import UTILITY from '../../lib/utility_service'

export default function PaymentMethods(){
	const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false)
	const [loading, setLoading] = useState(false)
	
	const { paymentMethods } = useSelector(app => app.core)
	useEffect(() => {
		if(!paymentMethods){
			setLoading(true)
			UTILITY.fetchPaymentMethod().then(() => setLoading(false));
		}
	}, []);

	function reload(){
		// setLoading(true)
		UTILITY.fetchPaymentMethod()//.then(() => setLoading(false));
	}


	function AddButton({mxAuto}){
		return(
			<Button 
				// className={` px-3 font-semibold h-10 w-[170px] 2xl:h-12 ${mxAuto && 'mx-auto'} `} 
				// style={{borderRadius: "0px"}} 
				type="default"
				icon={<PlusOutlined />}
				onClick={
					()=>setOpenPaymentMethodModal(true)
				}
			>
				Add Payment Method
			</Button>
		)
	}
	return(
		<div className='w-full h-full '>
			<AddMethodModal
				open={openPaymentMethodModal}
				reload={reload}
				setOpen={setOpenPaymentMethodModal}
			/>
			{loading ?
				<div className='flex justify-center'>
	                <Spiner fontSize={40} />
	            </div>
				:
				<div>
					<div className="flex w-full justify-between items-end text-primary" >
						
						<span className="text-md 2xl:text-xl font-semibold">
							Payment Methods
						</span>
						<AddButton />
					</div>
					{paymentMethods && paymentMethods?.length ?
						<div className="">
							<div className="pt-4 text-sm 2xl:text-base">
								<span className="text-primary font-semibold text-sm 2xl:text-base">Primary</span>
								<p className="mt-1  text-md 2xl:text-md">
									The primary payment method is automatically charged at the close of each billing cycle
									or can be used to prepaid your bill.
								</p>
							</div>
							<div className="pt-4">
								<PaymentMethodList 
									paymentMethods={paymentMethods.filter(method => !!method.isPrimary && !!method.isValidated)}
								/>
							</div>

							<div className="pt-4 text-sm 2xl:text-base">
								<span className="text-primary font-semibold text-sm 2xl:text-base">Backup</span>
								<p className="mt-1  text-md 2xl:text-md">
									When the primary payment method fails, a backup payment method pays the balance automatically.
								</p>
							</div>

							<div className="pt-4">
								<PaymentMethodList 
									paymentMethods={paymentMethods.filter(method => !method.isPrimary && !!method.isValidated)}
								/>
								{!paymentMethods.filter(method => !method.isPrimary && !!method.isValidated).length ?
									<div className="font-semibold text-primary  text-md 2xl:text-md">
										<i>
											(No backup payment method found!)
										</i>										
									</div>
									:
									null
								}
							</div>
						</div>
						:
						<div className="pt-4">
							<div className="">
								<Alert
							      message="Informational Notes"
							      description="You currently don't have any saved payment methods."
							      type="info"
							      showIcon
							    />
								{/*<div className="border w-full border-primary border-1 py-14 flex flex-col justify-center">
									<img 
										src={require('../../assets/img/billing/payment-metod.webp')} 
										className="w-[430px] mx-auto my-2" 
									/>
									<p className="text-center py-5">
										<span className="font-semibold text-base 2xl:text-xl">
											Look like you don't have any payments method
										</span>	
										<br/>
										<span className="text-sm 2xl:text-base">
											Volume are heighly available units of bloc storage that you can attach to
											Droplet to increase its space.
										</span>	
									</p>
									<AddButton mxAuto />
								</div>*/}
							</div>
						</div>
					}

					<div  className="pt-6">
						<span className="text-sm 2xl:text-xl font-semibold text-primary">
							Promos
						</span>
						<div className="pt-2">
							<span className="text-primary font-semibold text-sm 2xl:text-base">Promo Code</span>
							<p className=" text-md 2xl:text-md">
								If you have a promo code, please enter it below to receive your credit.
							</p>
							<div className='pt-3'>
								<Space.Compact style={{ width: '500px' }}>
									<Input size='large' defaultValue="Add new promo code" />
									<Button size='large' className='ml-0' type="primary">Apply Code</Button>
								</Space.Compact>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	)
}


