import { BsInfoCircle } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import VPSapi from '../../lib/vps_service';
import { BsFillLightbulbFill } from "react-icons/bs";
import { Toast } from '../../components/Notify';
import { Block } from '../../components/Bloc';
import UserMgt from '../../lib/user_managment';
import { Button as AntButton, Input, Space } from 'antd';
import Custom from '../../assets/img/plan/Custom.png'  
import { VpsPack } from "../../components/vps/Packages";
import Paypal from "components/PaypalModal";
import { Alert } from 'antd';


export default function Order() {
    const { flavors } = useSelector(app => app.vps)
    const { images } = useSelector(app => app.vps)
    const { vps_packages } = useSelector(app => app.vps)
    const user = useSelector(app => app?.user?.user)
    const [packSelected, setPack] = useState(null)
    const [choice, setChoice] = useState("VPS")
     const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [custum, setCustum] = useState(false)
    const [link, setlink] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)

    const [vps_params, setVpsParams] = useState({
        server_name: "",
        flavor: "",
        image: "", 
        promoCode: "",
        full_name: user?.FirstName,
        email: user?.EmailAddr,
        addr: user?.Address
    })


    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)
    const [paypalPack, setPackPaypal] = useState(null)


    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }
    async function loadFlavors() {
        !flavors && VPSapi.flavor_list()
    }
    async function loadImages() {
        !images && VPSapi.image_list()
    }
    async function getVpsTypes() {
        !vps_packages && UserMgt.get_vps_types()
    }
   
    useEffect(() => {
        (async () => {
            await getVpsTypes()
            await loadFlavors()
            await loadImages() 
        })()

    }, [])


    async function onOrder(dataPayment) {
        
        try{
            console.log('vps_params vps_params vps_params', vps_params)
            const object = {
                    // vpsTypeId: !custum ? packSelected.Id : null, 
                    IsAddOn: choice === "add-on", 
                    IsCustom: custum, 
                    promoCode: vps_params?.promoCode, 
                    NoService: true,
                    ...vps_params,
                    ...dataPayment,
            }
            console.log('log onOrder start', object)
            setCreating(true)
            const resul = await UserMgt.create_payment(object)
            console.log('>> result onOrder', resul)
            setCreating(false)
            const links = resul?.result?.links
            console.log('links links links', links);
            const link = links && links.length && links.find(l => l.rel === "approve")
            if(resul && resul.subscriptionId || link){
                Toast.fire({
                    icon:  'success' ,
                    title: !link ? 'Done !' : "You need to follow this link to approve transaction"
                }) 
                if(link){
                    window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
                    setlink(link?.href)
                }
            }else{
                Toast.fire({ 
                    icon: 'error',
                    title: resul?.message || "An error occurs, please contact us !"
                })
            }
        }catch(e){
            console.error('error create subscription', e)
            setCreating(false)
            Toast.fire({ 
                icon: 'error',
                title: "An error occurs, please contact us !"
            })
        }
    }

    function onClaculFlavor(pack, f){
        setPack(pack)
        setCustum(false)
    }
    function calCulFinalPrice(){
        if(custum){
            return parseFloat(200 * (1 - (codeApplied ? codeApplied.Percent / 100 : 0))).toFixed(2)
        }else if(packSelected){
            return parseFloat(packSelected.Price).toFixed(2)
        } else{
            return 0;
        }
    }

    function applyCode(code, codeString){
        setCodeApplied(code)
        setVpsParams({...vps_params, promoCode: codeString})
    }

    function onDismiss(){
        setCreating(false)
        setPaypal(false)
        window.$(".modalPaypal").modal('hide')

    }
    function createPaymentAndDeploy(paymentID, dataPayment){
        setPaypal(false)
        const dataPayment2 = {
            ...dataPayment,
            amountPaid:  calCulFinalPrice(),

        }
        window.$(".modalPaypal").modal('hide')
        console.log('dataPayment2 dataPayment2', dataPayment2)
        return  onOrder(dataPayment2)   

    }

    function onPay(ev) {
        ev.preventDefault()
        if(link){
           return  window.open(link, "Confirm", "width=500,height=500, fullscreen=yes")
        }
        if(!packSelected && !custum){
            Toast.fire({
                icon: 'error',
                title:  "you have to select package !"
            })
            return 
        }
        
        setCreating(true)
        setPaypal(true)
        if(custum){
           // return window.$("#P-9A404273LF8870222MSI6FBI").modal('show')
            return onOrder({})
        }
        return window.$("#"+packSelected?.PaypalPackId).modal('show')
    }

    const concertPack = vps_packages && vps_packages
                            .filter(p => p.Package_Type === choice && !p.IsMini && !p.IsSec  && !p.IsMiniSec)
                            .sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    console.log('concertPack concertPack concertPack', concertPack)

    return (
        <>
            {concertPack &&
                <Paypal
                    createPaymentAndDeploy={createPaymentAndDeploy} 
                    paypal={paypal} 
                    creating={creating}  
                    concertPack={[...concertPack, {Name: "Custom", PaypalPackId: "P-9A404273LF8870222MSI6FBI"}]}
                    onDismiss={onDismiss} 
                    finalPrice={calCulFinalPrice}
                    withPromo={custum} 
                /> 
            }

            <div className="px-3 md:px-8 dark:text-darkTextColor ">
                <div className="container max-w-full">
                    <CalculPromo applyCode={applyCode} />
                    <div className='mx-auto'>
                    <div className="flex gap-4 justify-center pb-5">
                      
                        <div className="form-check">
                          <input 
                              className="
                                  form-check-input 
                                  appearance-none 
                                  rounded-full h-4 w-4 
                                  border border-gray-300 bg-white 
                                  checked:bg-blue-600 checked:border-blue-600 
                                  focus:outline-none transition duration-200 mt-1 align-top 
                                  bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                  type="radio" 
                                  name="flexRadioDefault" 
                                  id="flexRadioDefault1"
                                  value={"VPS"}
                                  onChange={()=>setChoice('VPS')}
                                  checked = {choice === "VPS"}
                            />
                          <label className="form-check-label inline-block dark:text-darkTextColor" for="flexRadioDefault1">
                            VPS Instance 
                          </label>
                        </div>

                        <div className="form-check">
                          <input 
                              className="
                                  form-check-input 
                                  appearance-none 
                                  rounded-full h-4 w-4 
                                  border border-gray-300 bg-white 
                                  checked:bg-blue-600 checked:border-blue-600 
                                  focus:outline-none transition duration-200 mt-1 align-top 
                                  bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                  type="radio" 
                                  name="flexRadioDefault" 
                                  id="flexRadioDefault2"
                                  value="KPS_CLOUD"
                                  onChange={()=>setChoice('KPS_CLOUD')}
                                  checked = {choice === "KPS_CLOUD"}
                            />
                          <label className="form-check-label inline-block dark:text-darkTextColor" for="flexRadioDefault2">
                            KPS Cloud
                          </label>
                        </div>

                        <div className="form-check">
                          <input 
                              checked = {choice === "add-on"}
                              className="
                                  form-check-input 
                                  appearance-none 
                                  rounded-full h-4 w-4 
                                  border border-gray-300 bg-white 
                                  checked:bg-blue-600 checked:border-blue-600 
                                  focus:outline-none transition duration-200 mt-1 align-top 
                                  bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                  type="radio" 
                                  name="flexRadioDefault" 
                                  id="flexRadioDefault3"
                                  value="add-on"
                                  onChange={()=>setChoice('add-on')}
                            />
                          <label className="form-check-label inline-block dark:text-darkTextColor" for="flexRadioDefault3">
                            Add-on
                          </label>
                        </div>

                      
                    </div>

                        <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor'>Choose your {choice==="add-on" ? " Add-on" :  " subscription"}  plan</p>
                        {vps_packages ?
                            <form onSubmit={onPay} className="">
                                
                                <div className="pb-48 md:pb-42">
                                        <div className="px-5 md:px-20 pt-5 ">
                                            {false && 
                                                <Alert
                                                    className="mx-auto"
                                                    message={link}
                                                    type="success" 
                                                    showIcon 
                                                    action={
                                                        <Space direction="vertical">
                                                            <AntButton onClick={() => {
                                                                    window.open(link, "Confirm", "width=500,height=500, fullscreen=yes")
                                                                }
                                                            } 
                                                            className='px-5' 
                                                            type="primary"
                                                        >
                                                                Confirm subscription
                                                            </AntButton>
                                                        </Space>
                                                    }
                                                />
                                            }
                                        </div>

                                        <div className="mx-auto  flex flex-wrap gap-2  justify-center  md:w-11/12 mt-5">
                                            <VpsPack 
                                                concertPack={concertPack} 
                                                setSelected={setSelected} 
                                                creating={creating} 
                                                clicked={clicked} 
                                                setClicked={setClicked} 
                                                onClaculFlavor={onClaculFlavor} 
                                                setPackPaypal={function(){}} 
                                                selected={selected} 
                                            />

                                            <div  className=" mt-5 ">
                                                <div 
                                                    onClick={() => {
                                                        if (!creating) {
                                                            setCustum(true);
                                                            setPack(null);
                                                            setClicked(null);
                                                            window.$("#OrderPromoModal").modal('show')
                                                            // onOrder({})
                                                            
                                                        }    
                                                    }}
                                                    className={'w-60 cursor-pointer rounded-xl dark:bg-bgPagedark ' +
                                                        ("hover:bg-white  hover:shadow-2xl ") + "" + ((custum && !selected) ? "bg-white shadow-2xl" : 'bg-neutral-100 dark:hover:shadow-slate-700')}>

                                                    {(custum && !clicked) &&
                                                        <svg className="div_check checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
                                                            <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    }
                                                    <div className='pt-14 pb-14 text-center text-primary dark:text-darkTextColor'>
                                                        <div className="flex">
                                                            <div className="flex gap-1 items-center mx-auto">
                                                                <img src={Custom} alt="" className='w-10 h-10 6.' />
                                                                <span className='font-bold text-2xl'> {"Custom"}</span>
                                                            </div>
                                                        </div>
                                                        <p className='font-bold'>
                                                            Price:  <span className='text-center invisible text-3xl'>{" "}</span><i> (to be defined) </i>
                                                        </p>
                                                        <p className='font-bold text-xs invisible'>hour</p>

                                                        <div className='mx-auto px-28 py-2'>
                                                            <div className='border-t-4 rounded-full border-t-primary'/>
                                                        </div>
                                                        <p className='font-bold pt-3'> CPU:  <i> on demand </i> </p>
                                                        <p className='font-bold pt-3'>RAM: <i>  on demand </i> </p>
                                                        <p className='font-bold pt-3'>SSD:  <i> on demand </i> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                </div>


                                <div className='mx-auto'>
                                    <div className={'fixed bottom-0 dark:opacity-70 left-0 dark:text-darkTextColor dark:bg-bgPagedark shawdow w-screen mx-auto md:ml-10 h-48 md:h-42'}>
                                        <div className='container'>
                                            <div className='grid grid-cols-1' >
                                                
                                                <div className='text-center text-primary dark:text-darkTextColor pt-7'>
                                                    <span className='text-xl'>Summary:</span> 
                                                    <br/>
                                                    {packSelected || codeApplied ?
                                                    <span className='text-2xl font-bold'>
                                                        <span className='text-base font-bold'>Total: &nbsp;</span>
                                                        $ {(parseFloat(calCulFinalPrice()) * (1 + 14.975 / 100)).toFixed(2)} / month (include TPS + TVQ)
                                                    </span> :
                                                    <span>(empty)</span>
                                                    }
                                                    
                                                </div>
                                                <div className="mx-auto">
                                                    {true&&
                                                        <div className=''>
                                                            <button className="flex mt-4 mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                                //onClick={()=>onOrder()}
                                                                disabled={creating}
                                                                type="submit"
                                                            >
                                                                {!creating ?
                                                                    <span className='mx-auto flex  items-center'>
                                                                        <BsFillLightbulbFill className='text-2xl' />
                                                                        {" Order"}
                                                                    </span>
                                                                    :
                                                                    <span className='mx-auto flex gap-1 items-center'>
                                                                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                        {" Ongoing "}
                                                                    </span>

                                                                }
                                                            </button>
                                                        </div>
                                                    }
                                                    <div className='h-2'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                               
                            </form> 
                            :
                            <div className='flex justify-center pt-48'>
                                <svg className="animate-spin  -ml-1mr-3 h-12 w-12 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    );
}


function CalculPromo(props){

    const [promo, setPromo] = useState('')
    const [loanding, setL] = useState(false)

    async function applyCode(){
        setL(true)
        const r = await UserMgt.check_promo_code(promo)
        console.log('r && (!r.code?.IsUsed || r.code?.IsMulti)', r.code)
        setL(false)
        if(r && r.code &&  (!r.code?.IsUsed || r.code?.IsMulti)){
            props.applyCode(r?.code, promo)
            //window.$("#"+packSelected?.PaypalPackIdFree).modal('show') 
            window.$("#OrderPromoModal").modal('hide')
        }else{
            return Toast.fire({
                icon: 'error',
                title: "Promo code is not valid !"
            })
        }
    }
    function onCancel() {
        setL(false)
        window.$("#OrderPromoModal").modal('hide')
    }
    return (
        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={"OrderPromoModal"} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    
                    <div className="dark:bg-bgPagedark dark:text-darkTextColor modal-body relative py-6 text-center">
                        <span className="text-3xl">
                            <BsInfoCircle className="text-5xl mx-auto" />
                        </span>

                        <p className="pt-5">
                            Please enter your subscription code

                            <br/>
                            <br/>
                            <Space.Compact
                                style={{
                                width: '80%',
                                }}
                            >
                                <Input className='border-2 text-black border-primary h-10' placeholder='Promo code here' onChange={(e) => setPromo(e.target.value)} value={promo} />
                            </Space.Compact>

                        </p>
                        <div className="pt-10 gap-3 text-center flex justify-center">
                            <AntButton className='font-medium hover:text-primary hover:bg-white   px-10 flex items-center rounded-full h-10' loading={loanding} type="primary" onClick={applyCode}>Apply</AntButton>
                                
                            <button type="button" className="
                                border-2
                                border-primary
                                text-primary dark:text-darkTextColor
                                hover:text-white
                                inline-block 
                                px-10
                                py-2.5 
                                hover:bg-primary
                                font-medium 
                                text-base 
                                leading-tight 
                                rounded-full 
                                shadow-md 
                                hover:shadow-lg 
                                focus:bg-primary
                                focus:shadow-lg 
                                focus:outline-none 
                                focus:ring-0 
                                active:bg-primary
                                active:shadow-lg 
                                transition 
                                duration-150 ease-in-out"
                                onClick={()=> onCancel()}
                            >
                                Cancel
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

