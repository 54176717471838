import { useNavigate } from 'react-router-dom';
import { OkButton } from "../OkButton";
import kwaf from "../../assets/img/kwaf/kwaf.webp"
import vullify1 from '../../assets/img/vullify/vullify1.png'
import { CardServiceLayout, MiniCardServiceLayout, ServiceOptions } from 'layouts/ServiceLayout'
import { LinkOutlined } from '@ant-design/icons';





export function SecurityServerCard({server}) {
    const is = (s) => server?.VpsStatus === s

    const navigate = useNavigate()

    function onManage(){
        if(!is('up')) return
        if(server?.service?.task_name === "kwaf"){
            return navigate("/security/kwaf/"+server?.Id+"/connect")
        }
        if(server?.service?.task_name === "vullify"){
            return navigate("/security/vullify/"+server?.Id+"/connect")
        }
    }
    const isKwaf = server?.service?.task_name === "kwaf"
    return (
        <CardServiceLayout server={server}>
            <div> 
                <div className='flex items-start justify-between border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex 2xl:gap-4 gap-2'>
                        <img src={server?.service?.task_name==="vullify"? vullify1 :kwaf} alt="" className={isKwaf && 'w-16 h-16'} />
                        <div>
                            <span className='2xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>
                                {server?.DisplayName || server?.OpenstackInstanceName}
                            </span> <br/>
                            <span className="2xl:text-base text-xs">{"vullify"}</span>
                        </div>
                    </div> 
                        
                    <OkButton title="Connect" click fun={() =>  onManage()}   server={server} loanding={false}/>
                        
                </div>
                <div className='flex justify-between pt-2 2xl:pt-4 text-sm 2xl:text-base'>
                    <div className=''>
                        <span className="font-bold text-primary">
                            {
                                server?.VpsStatus  === "up"? 
                                "Running" : 
                                server?.VpsStatus
                            }
                        </span>
                    </div>
                    <div className=''>
                        <p className='text-right'>
                            <span>{server?.OpenstackInstanceExtIp}</span> <br/>
                            <span>{server?.location?.name || "Montreal, Zone A"}</span> 
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>

    )

}




export function SecurityServerMiniCard({server}){
    const is = (s) => server?.VpsStatus === s

    const navigate = useNavigate()

    function onManage(){
        if(server?.service?.task_name === "kwaf"){
            return navigate("/security/kwaf/"+server?.Id+"/connect")
        }
        if(server?.service?.task_name === "vullify"){
            return navigate("/security/vullify/"+server?.Id+"/connect")
        }
    }
    return(
        <MiniCardServiceLayout server={server}>
            <div className='flex justify-between items-start'>
                <div className='flex 2xl:gap-3 gap-1'>
                    <img 
                        src={
                            server?.service?.task_name==="vullify"? vullify1 :kwaf
                        } 
                        alt="" 
                        className={server?.service?.task_name==="vullify" ? '2xl:h-14 h-10' :"2xl:w-14 w-10"} 
                    />
                    <div>
                        <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>
                            {server?.DisplayName}
                        </span> 
                        <br/>
                        <span className="2xl:text-sm font-semibold inline-flex text-xs">
                            
                            {server?.OpenstackInstanceExtIp}
                        </span>
                    </div> 
                </div>
                <div className='flex flex-row-reverse gap-[1px]  justify-end justify-end'>
                    <ServiceOptions
                        options={[
                            {label: "Connect",  cunstomFun: ()=> onManage(), display: !is('up') && "none"},
                            // {label: "User dashboard", href: server?.domain, display: !is('up') && server?.service?.task_name!=="kwaf" && "none"},
                            {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
                        ]}
                        server={server}
                    />
                    {server?.service?.task_name==="vullify" ?
                        <a  
                            href={is('up') ? "http://"+server.domain : "#"}
                            target="_blank"
                            className="bg-primary py-[3px] px-[6px] rounded flex items-center justify-center"
                        >
                            <LinkOutlined className=" text-sm text-[#fff]" />
                        </a>
                        :
                        <a  
                            onClick={onManage}
                            className="bg-primary py-[3px] px-[6px] rounded flex items-center justify-center"
                        >
                            <LinkOutlined className=" text-sm text-[#fff]" />
                        </a>

                    }

                </div>
            </div>
        </MiniCardServiceLayout>
    )
}