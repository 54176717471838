import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptiobCard }  from '../../components/BillSection'

import { MdPayments } from "react-icons/md";
import { ThemeContext } from '../../theme/ThemeContext'; 
import kspoint from "../../assets/img/kspoint.png"
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../components/TabSelector";
// import PaymentMethods from "./PaymentMethods"

import UserMgt from '../../lib/user_managment';
import AddFund from 'components/AddFund';
import AddFunBottom from 'components/billing/AddFunBottom';

import { Payments, Overview } from './BillingSection'


const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export default function Bill() {
    const { subscriptions } = useSelector(app => app.user)
    const { payments } = useSelector(app => app.user)
    const { theme } = useContext(ThemeContext);
    const [creating, setCreating] = useState(false)
    const [fund, setFund] = useState(null)

    const [selectedTab, setSelectedTab] = useTabs([
        "Overview",
        "Subscriptions",
        "History",
        "Info",
        "Credit",
        // "Method"
     ]);




    useEffect(()=>{
        load();
        //UserMgt.get_user_payment() 
    }, [])

    async function load(){
        await UserMgt.get_user_souscription();
    }

    return (
       <div>
            <div className=' dark:text-darkTextColor '>
                <div className="px-3 md:px-8  ">
                    <div className="pt-2">
                        <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            <TabSelector
                              isActive={selectedTab === "Overview"}
                              onClick={() => setSelectedTab("Overview")}
                            >
                              Overview
                            </TabSelector>
                            {/*<TabSelector
                              isActive={selectedTab === "Subscriptions"}
                              onClick={() => setSelectedTab("Subscriptions")}
                            >
                              Subscriptions
                            </TabSelector>*/}
                            <TabSelector
                              isActive={selectedTab === "History"}
                              onClick={() => setSelectedTab("History")}
                            >
                              History
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Info"}
                              onClick={() => setSelectedTab("Info")}
                            >
                              Payment Info
                            </TabSelector>
                            {/*<TabSelector
                              isActive={selectedTab === "Method"}
                              onClick={() => setSelectedTab("Method")}
                            >
                              Payment Method
                            </TabSelector>*/}
                            <TabSelector
                              isActive={selectedTab === "Credit"}
                              onClick={() => setSelectedTab("Credit")}
                            >
                              Credit
                            </TabSelector>
                           
                          </nav>

                          <TabPanel hidden={selectedTab !== "Subscriptions"}>
                              <div className="text-center ">
                                    <h3 className="text-xl font-semibold"></h3>
                                    <div className="mx-auto gap-4 justify-center pb-5  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3    mt-10">
                                        {subscriptions && subscriptions.map((s, k) =>{
                                                return <SubscriptiobCard subscription={s} key={k} />
                                            })
                                        }
                                     </div>
                                </div>
                          </TabPanel>

                        <div className="pt-3 w-full ">
                            <TabPanel hidden={selectedTab !== "Overview"}>
                                <div>
                                    <Overview payments={payments} /> 

                                </div> 
                            </TabPanel>
                            <TabPanel hidden={selectedTab !== "History"}>
                                <div>
                                    <Payments payments={payments} /> 

                                </div> 
                            </TabPanel>
                           {/* <TabPanel hidden={selectedTab !== "Method"}>
                                <div className=""> 
                                    <PaymentMethods setup /> 
                                </div> 
                            </TabPanel>*/}
                            <TabPanel hidden={selectedTab !== "Info"}>
                                <div className="">
                                    <span className="font-bold text-primary text-xl 2xl:text-2xl" >
                                        No Payment Due
                                    </span>
                                    <br/>
                                    <p>
                                        You account is paid in full and  no payement is due at this time.
                                        <br/>
                                        <div className="flex gap-8 pt-5 cursor-pointer select-none">
                                            <div className="flex gap-2 items-center  select-none text-primary dark:text-darkTextColor">
                                                <MdPayments className=" text-xl"/>
                                                <p className="text-xl">Make a Payment</p> 
                                            </div>

                                            <div className="flex gap-2 items-center cursor-pointer select-none text-primary dark:text-darkTextColor">
                                                <MdPayments className=" text-xl"/>
                                                <p className="text-xl">View last invoice</p> 
                                            </div>
                                        </div>
                                    </p>   
                                </div>
                            </TabPanel>
                            <TabPanel hidden={selectedTab !== "Credit"}>
                                <div className="pb-48 ">
                                    <div className="flex items-center billPoint">
                                        <img src={kspoint} alt="" className='2xl:w-10 2xl:h-10 w-8 h-8' />
                                        <svg className="svg0">
                                            <text className={theme !== "dark"? "svgText " : " svgTextDark"} x="10" y="45">Credit:2</text>
                                        </svg>
                                    </div>
                                    <AddFund title="Choose credit amount" creating={creating} noCostCalculator setFund={setFund} />
                                    <AddFunBottom creating={creating} fund={fund} />

                                 
                                </div> 
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
