import { Checkbox } from 'antd';
import { BsFillLightbulbFill } from "react-icons/bs";

export default function AddFunBottom(props){
    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };
    return(
        <div className='mx-auto'>
            <div className={'fixed bottom-0 dark:opacity-70 left-0 dark:text-darkTextColor dark:bg-bgPagedark shawdow w-screen mx-auto md:ml-10 pb-5'}>
                <div className='container  md:pl-72 px-10   2xl:ml-0 flex justify-center'>
                    <div className='' >
                        
                        <div className='text-primary dark:text-darkTextColor pt-4'>
                            <span className='text-xl'>Summary:</span> 
                            <br />

                            {props.fund ?
                                <span>
                                    {!props.fund?.custom ?
                                        <span className='text-base font-bold'>
                                            {props.fund?.point} KsPoint &nbsp;
                                            <span className="text-2xl">$ {(parseFloat(props.fund.price) * (1 + 14.975 / 100)).toFixed(2)} </span>
                                        </span> 
                                        :
                                        <span className='text-base font-bold'> 
                                        KsPoint and  Price: <i>(To be defined) </i> 
                                        </span> 
                                    }

                                </span> 
                                :
                                <span>(empty)</span>
                            }
                             
                        </div>
                        <div className='flex flex-wrap mx-auto   gap-8   items-center ' >
                            <div className="w-72 ">
                                <div className="">
                                    {/*!paypal*/ true &&
                                        <div className=''>
                                            <button className="flex mt-4  text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                type="submit"
                                                disabled={props.creating}
                                            >
                                                {!props.creating ?
                                                    <span className='mx-auto flex  items-center text-sm xl:text-base '>
                                                        <BsFillLightbulbFill className='2xl:text-2xl text-xl' />
                                                        {" Pay with paypal"}
                                                    </span>
                                                    :
                                                    <span className='mx-auto flex gap-1 items-center'>
                                                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        {!props.paypal ? " Paying" : " Waiting for paypal"}
                                                    </span>

                                                }
                                            </button>
                                        </div>
                                    }

                                    <div className='h-3' />
                                </div>

                            </div>
                            <div className="w-72">

                                <div>
                                    <div className="w-full   ">
                                        <Checkbox onChange={onChange}>
                                            I agree to the{" "}
                                            <a target="_blank" href="https://www.keepsec.ca/legal">
                                                Terms of Service
                                            </a>
                                        </Checkbox>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
