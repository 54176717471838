import { useState } from "react";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { GrCaretPrevious, GrCaretNext } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import next from '../assets/img/next.png'
import prev from '../assets/img/prev.png'

export function AddButton({link, width, danger, title, duck, click, fun, onForward, canGoBaack, canGoForward, onGoBack, list, showPagintion, max, setPage, page} ) {
    const [hover, setHover] = useState("")
    function onHover(){
        setHover("animate-bounce")
    }
    function onMouseOut(){
        setHover("")
    }
    let numberPage = 0;
    if(true){
        numberPage = list && Math.ceil(list.length / max) < 5 ? Math.ceil(list.length / max) : 5
    }
    if(duck){
        return (
            <NavLink to={!click && link}>
                <div className={`mx-auto my-auto dark:bg-bgPagedark ${width || "w-32"} h-28 rounded-xl  shadow-lg m-8 bg-white cursor-pointer transform transition duration-500 hover:scale-110`} 
                    onMouseOver={onHover} 
                    onMouseOut={onMouseOut}
                    onClick={click ? fun : ()=>{}}
                >
                    <div className="mx-auto my-auto pt-4">
                        <HiOutlinePlusCircle className={`mx-auto my-auto text-4xl ${danger ? "text-red-700" : "text-primary dark:text-darkTextColor"}  ${hover}`} />
                        <p className={`text-center  ${danger ? "text-red-700" : "text-primary dark:text-darkTextColor"} mx-auto font-bold`}>{title ||"Add Plan"}</p>
                    </div>
                </div>
            </NavLink>
        );
    }
    return (
        <div>
            <div className="flex gap-2 justify-center">
                {showPagintion && 
                    <div onClick={canGoBaack? onGoBack : ()=>{}} className="flex  px-4  items-center dark:bg-bgPagedark   rounded-xl  shadow-lg  bg-white cursor-pointer transform transition duration-500 hover:scale-110">
                        <img src={prev} className="text-5 mx-auto" />
                    </div>
                }
                <NavLink to={link} className="2xl:w-56 w-40  my-auto">
                    <div className=" dark:bg-bgPagedark py-8  rounded-xl  shadow-lg  bg-white cursor-pointer transform transition duration-500 hover:scale-110" 
                        onMouseOver={onHover}
                        onMouseOut={onMouseOut}
                    >
                        <div className="mx-auto my-auto">
                            <HiOutlinePlusCircle className={'mx-auto my-auto 2xl:text-8xl text-5xl text-primary dark:text-darkTextColor '+hover} />
                            <p className={"text-center dark:text-darkTextColor text-sm 2xl:text-base mx-auto font-bold"}>{title ||"Add Plan"}</p>
                        </div>
                    </div>
                </NavLink>
                {showPagintion && 
                    <div onClick={canGoForward? onForward : ()=>{}} className="flex   px-4 items-center dark:bg-bgPagedark py-8  rounded-xl  shadow-lg  bg-white cursor-pointer transform transition duration-500 hover:scale-110">
                        <img src={next} className="text-5 mx-auto" />
                    </div>
                }
            </div>

            <div className="flex gap-1 text-sm 2xl:text-base mt-3 justify-center items-center"> 
                <span className="text-primary  dark:text-darkTextColor font-bold">Prev &nbsp;</span>
                {[...Array(numberPage).keys()].map((v) => (
                
                    <div key={v} onClick={()=>setPage(v)} className="2xl:h-10 2xl:w-10 w-8 h-8 flex text-center transform transition duration-500 hover:scale-110 items-center dark:bg-bgPagedark   rounded-xl  shadow-lg  bg-white cursor-pointer transform transition duration-500">
                        <span className={"mx-auto font-bold "+ (page== v+1 && " font-extrabold")}>{v+1}</span>
                    </div>
                ))}

                {[...Array(5-numberPage).keys()].map((v) => (
                
                    <div key={v+numberPage} className="2xl:h-10 2xl:w-10 w-8 h-8 flex text-center transform transition duration-500 hover:scale-110 items-center dark:bg-bgPagedark   rounded-xl  shadow-lg  bg-white  transform transition duration-500">
                        <span className={"mx-auto font-bold"}>{v+numberPage+1}</span>
                    </div>
                ))}

                <span className="text-primary dark:text-darkTextColor font-bold">&nbsp;Next </span>

            </div>
         
        </div>
    );
}








