import {  useState } from 'react';
import {IoIosArrowDropdown, IoIosArrowDropup} from "react-icons/io";

export default function AppBuildLog(props){

	const [isWrapped, setIsWrapped] = useState(true)
    const [expandedLogs, setExpandedLogs] = useState(true)

	const app = props?.app
	let willUnmountSoon = false

    const rootDomain = props?.rootDomain


    function onExpandLogClicked() {
        setExpandedLogs(!expandedLogs)
    }
    
    return(
    	<div className=" ">
        	<button 	
        		onClick={() => onExpandLogClicked()} 
        		className="flex items-center mt-5 h-10 border-2 dark:border-gray-800 rounded justify-center px-4  dark:border-gray-800 border-r"
        	>
                {expandedLogs ?
                	<IoIosArrowDropdown /> : <IoIosArrowDropup />
                }
                {expandedLogs ?
                	"View app build logs" : "Hide App Build Logs"
                }
            </button>
            <div className="pt-10">
	            {!expandedLogs &&
		            <textarea
		                id="buildlog-text-id"
		                className="px-4 py-2 w-full dark:bg-bgDark2 rounded-xl dark:text-darkTextColor dark:border-gray-800  border-2 h-48" 
		                style={{
		                    whiteSpace: isWrapped
		                        ? 'pre-line'
		                        : 'pre',
		                }}
		                value={props.buildLogs}
		                readOnly
		            />
		        }
		    </div>
        </div>
   	)
}    