import { FaLeanpub } from "react-icons/fa";
import { Badge, Typography, Button, Table, Tooltip, Popconfirm,
	Select, Modal 
} from 'antd';
import {
  DeleteFilled
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import UserMgt from "lib/user_managment";
import K8S from 'lib/k8s';
import Utils from 'utils'

import { useState, useEffect } from 'react';


const { Paragraph, Text  } = Typography;

export function Overview({vpc}){
	return(
		<div>
			<div className="flex justify-between items-start">
				<span className="font-bold text-primary text-base xl:text-xl 2xl:text-2xl">
					Overview
				</span>

				<a href={null} className="flex items-center font-medium gap-1  ">
					<span className="text-sm 2xl:text-base">Learn</span> 
					<FaLeanpub className="text-xl 2xl:text-3xl"/>
				</a>
			</div>
			<div className="flex flex-wrap mt-3">
				<div className="w-full md:w-[500px]">
					<div className="border-b pb-3 border-b-[2px] border-gray-200 w-full">
						<p className="font-semibold text-primary text-sm py-2  2xl:text-base">
							DESCRIPTION
						</p>
						{vpc?.isDefault ? 
							<Badge  
								count={" DEFAULT "} 
							/> 
							: 
							null
						}
						<p className="text-sm 2xl:text-base mt-5">
							{
								vpc?.network?.description 
								|| 
								"This is set as default VPC for the "+vpc?.location?.name+" Region"
							}
						</p>
					</div>

					<div className="">
						<p className="font-semibold text-primary text-sm py-4  2xl:text-base">
							NETWORK DETAILS
						</p>

						<p className="leading-5 font-semibold">
							<Text copyable>
								{vpc?.network?.subnet}
						    </Text>
						    <br />
						    <span className="text-xs xl:text-sm"> IP Range </span>
					    </p>
					    <br />
					    <p className="leading-5 font-semibold">
							<Text>
								{255 - vpc?.ressources?.length}
						    </Text>
						    <br />
						    <span className="text-xs xl:text-sm"> Available address </span>
					    </p>
					    <br />
					    <p className="leading-5 font-semibold">
							<Text>
								{vpc?.ressources?.length}
						    </Text>
						    <br />
						    <span className="text-xs xl:text-sm"> Ressources </span>
					    </p>
					</div>
				</div>

				<div>
					<div className="md:pl-10">
						<p className="font-semibold text-primary text-sm py-2  2xl:text-base">
							VPC NETWORK RESSOURCES
						</p>
						<p className="leading-10" >
							<a className="text-sm 2xl:text-base mt-5">
								Docs
							</a>
							<br/>
							<a className="text-sm 2xl:text-base mt-5">
								API Docs
							</a>
							<br/>
							<a className="text-sm 2xl:text-base mt-5">
								Tutoriel
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export function Ressources({vpc, loadNetwork}){
	const [open, setOpen] = useState(false)

	return(
		<div>
			<div className="flex justify-between items-start">
				<span className="font-bold text-primary text-base xl:text-xl 2xl:text-2xl">
					Ressources
				</span>
				<span className="flex gap-4 items-center">
					<Button 
						className="px-4  font-semibold  rounded-full" 
						type="primary"
						onClick={
							()=>setOpen(true)
						}
					>
						Add Service
					</Button>

					<a href={null} className="flex items-center font-medium gap-1  ">
						<span className="text-sm 2xl:text-base">Learn</span> 
						<FaLeanpub className="text-xl 2xl:text-3xl"/>
					</a>
				</span>

			</div>

			<div className="pt-5">
				<div className={`${vpc?.ressources?.length && 'hidden'}`} >
					<div className="border border-gray-300 border-1 py-8 flex flex-col justify-center">
						<p className="text-center py-5">
							<span className="font-semibold text-base 2xl:text-xl">
								You have no ressource in this network
							</span>		
						</p>
						<img 
							src={require('../../../assets/img/networking/cloud-service.png')} 
							className="xl:w-28 w-24 mx-auto" 
						/>
					</div>
				</div>

				<div className={`${!vpc?.ressources?.length && 'hidden'}`} >
					<div>
						<p className="font-semibold text-sm 2xl:text-base">
							All communication between these ressources via private IP is secure.
							<br/>
							Traffic to public IPs must be secured with a&nbsp;
							<a href={null} className="cursor-pointer">
								<u>Firewall</u> 
							</a>
						</p>

						<div className="pt-4">
							<p className="font-semibold text-primary text-sm py-2  2xl:text-base">
								SERVICES
							</p>
							<RessourceTable loadNetwork={loadNetwork} vpc={vpc} />
							<AddService 
								loadNetwork={loadNetwork} 
								vpc={vpc} 
								open={open} 
								setOpen={setOpen} 
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


function RessourceTable({vpc, loadNetwork}){
	const [object, setObject] = useState({})
    const {messageApi} = useSelector(app => app.core )

	const onDeletePort = async (vps) => {
		setObject({...object, [vps.Id]: true})
		try {
			const data = {
				portId: vps?.openstackPortId,
				server_id: vps?.Id,
				vpcId: vpc?.id
			}
			const result  = await UserMgt.onPortCreate(data, true)
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: 'Done !'
				});
				await loadNetwork()
			}else{
				messageApi.open({
					type: 'error',
					content: result?.message || 'An error occurs, please try again or contact an admin!'
				});
			}
		} catch (error) {
			console.error('Error createPort', error)
		}
		setObject({...object, [vps.Id]: null})
	}

	const columns = [
	  {
	    title: 'Name',
	    render: (_, obj) => <span className="font-semibold">{obj?.DisplayName}</span>,
	  },
	  {
	    title: 'Public IP', 
	    render: (_, obj) => <span className="font-semibold">
	    	{obj?.OpenstackInstanceExtIp ?
	    		<Text copyable>{obj?.OpenstackInstanceExtIp }</Text>
	    		:
	    		<i>(Not defined)</i>
	    	}
	    	</span>,
	  },
	  {
	    title: 'Private Ip',
	    render: (_, obj) => <span className="font-semibold"><Text copyable>{obj?.privateIp}</Text></span>,

	  },
	  {
        title: '',
        key: 'action',
        render: (_, record) => (
        	<div> 
        		<Popconfirm
		          placement="top"
		          title={"Action confirmation"}
		          description={"Do you realy want to service named "+record?.DisplayName +" in zone ?"}
		          okText="Yes, I do"
		          onConfirm={() => onDeletePort(record)}
		          cancelText="No, I don't"
		        >
		        	<Tooltip title={"Remove "+record?.DisplayName+" in this zone"}>
				        <Button danger  shape="circle" icon={<DeleteFilled />} />
				    </Tooltip>
		        </Popconfirm>
	        </div>
        ),
      },
	];

	return  <Table columns={columns} dataSource={vpc?.ressources} />;
}



export function AddService({open, setOpen, vpc, loadNetwork}){
	const [creating, setCreating] = useState(null)
	const [data, setData] = useState({})

	const list = useSelector(app => {
		const  kubernetes  = app.k8s?.kubernetes || []
		let k8sInstace = []
		for (let i = 0; i < kubernetes.length; i++) {
			const clusters =  kubernetes[i]?.clusters
			for (let j = 0; j < clusters.length; j++) {
				k8sInstace = k8sInstace.concat(clusters[j]?.instances)
			}
		}
		const kwaf = app.kwaf.listKwaf || []
		const list2 = (app.kslb.list || []).concat(kwaf)
        const list1 =  app.vps?.list?.filter(s => s?.service?.task_name==="vps").concat(k8sInstace)
    	return list1.concat(list2)
    })

	const {messageApi} = useSelector(app => app.core )

	useEffect(()=>{ 
        loadVps() 
        
    }, [])

    async function loadVps(){
        !list && await UserMgt.get_user_vps() &&  await K8S.getK8sInstance() 

    }


    const onCreatePort = async () => {
		setCreating(true)
		try {
			const result  = await UserMgt.onPortCreate({server_id: data?.server_id, vpcId: vpc?.id})
			console.log('Result Port ', result)
			if(result && !result.error){
				messageApi.open({
					type: 'success',
					content: 'Done !'
				});
				setData({})
				await loadNetwork()
				await Utils.sleep(500) 
				setOpen(false)
			}else{
				messageApi.open({
					type: 'error',
					content: result?.message || 'An error occurs, please try again or contact an admin!'
				});
			}
		} catch (error) {
			console.error('Error createPort', error)
		}
		setCreating(false)
	}

	useEffect(()=>{
		setData({})
	}, [])
	return(
		<Modal
	        destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={500}
	        open={open}
	    >
	        <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                           Adding new service in Zone
                        </h4>
                        <p className='text-sm xl:text-base'>
                            Select new instance to add. 
                        </p>
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                	<div className="w-full px-6 py-6">
                		<span className="font-bold">Select new instance here</span>
                		<Select
						    // showSearch
							size="large"
							// mode="multiple"
							value={data?.server_id}
							className="w-full rounded-none mt-2"
								// dropdownStyle={{borderRadius: "0px"}}
						    placeholder="Select a Service"
						    // optionFilterProp="children"
						    onChange={(selected) => {
						    	setData({...data, server_id: selected})
						    }}
						    // onSearch={onSearch}
						    // filterOption={filterOption}
						    options={list &&list.filter(vps => vps.VpsStatus === "up"
						    		&& vps.location?.name === vpc?.location?.name 
						    		&& !vpc?.ressources?.map(inst => inst?.Id).includes(vps?.Id)
						    	).map(vps=>{
						    		return {value: vps.Id, label: vps?.DisplayName}
						    	})
						    }
						    required
						/>						
					</div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
	                	<Button size="large" onClick={() => {
	                		  setData({})
	                          setOpen(false); 
	                        }}
	                    >
	                        Cancel
	                    </Button>
	                    <Button 
	                    	loading={creating} 
	                    	className="flex items-center gap-2" 
	                    	size="large" type="primary"  
	                    	onClick={() => {
	                            onCreatePort()
	                        }}
	                    >
	                        Add service
	                    </Button>
            		
            	</div>
            </div>
	    </Modal>
	)
}