import { LIST_FLAVORS, LIST_IMAGES, LIST_VPS, VPS_PACKAGES } from "../action/vpsActions";


const defaultState = {
    list: null,
    flavors: null,
    images: null,
    vps_packages: null,
    state: false
};
  
  
  export default function vps(state = defaultState, action) {
    switch (action.type) {
      case 'LOANDING':
        return { ...state, loading: action.stop? false : !state.loading };
      case LIST_VPS:
        return { ...state, list: action.list };
      case LIST_FLAVORS:
        return { ...state, flavors: action.list };
      case LIST_IMAGES:
        return { ...state, images: action.list };
      case VPS_PACKAGES:
        return { ...state, vps_packages: action.data };
      case "@DEPLOYED_VPS":
        return { ...state, state: action.state };
   
      default:
        return {...state};
    }
  }
  