import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from "components/Bloc";

import volume from "../../../assets/img/storage/volume.png"
import { Spiner } from '../../../components/Bloc'
import { ModalIncreaseSize, TableFunction } from '../../../components/volume/VolumeSection'

export default function StorageVps({ server, loadVps }) {
   
    const navigate = useNavigate()
    // const [loadingData, setLoading] = useState(false)
    // const [volumes, setVols] = useState(null)
    const [editedVolume, setEditVolume] = useState(null)
    
    // async function listStorageVolume(){
    //     setLoading(true)
    //     try{
    //         const data = await STORAGE.list()
    //         setVols(data?.volumes.filter(v => v.serverId === server?.Id))
    //     }catch(e){
    //         console.eror('Error load volume storage ', e)
    //     }
    //     setLoading(false)
    // }

    useEffect(() => {
        // listStorageVolume()  
    }, [])

    return (
        <div className='w-full '>
            <div className='flex justify-between items-start w-full'>
                <div className='pb-4'>
                    <div className="">
                        
                        <PageTitle text="Add new Volume Block Storage" />
                    </div>
                    <p className="xl:text-sm 2xl:text-base pt-2">
                        Volumes are highly available units of block storage that you can attach to a 
                        VPS to increase its available space.
                    </p>
                </div>
                {server?.volumes && server?.volumes.length ?
                    <Button 
                        className="w-32 font-semibold h-10 2xl:h-12 " 
                        style={{borderRadius: "0px"}} 
                        type="primary"
                        onClick={
                            ()=>navigate('/storage/volume/deploy?selected='+server?.Id)
                        }
                    >
                        Create Volume
                    </Button>
                    :
                    null
                }
            </div>

            {!false ?
                <div>
                    <ModalIncreaseSize 
                        open={editedVolume} 
                        setOpen={setEditVolume} 
                        vlume={editedVolume} 
                        reloadVolumeList={loadVps} 
                    />
                	{server?.volumes && server?.volumes.length ?
                        <TableFunction 
                            setEditVolume={setEditVolume}  
                            data={server?.volumes}
                            reloadVolumeList={loadVps}
                            isVps
                        />
                         :
                        <div>
                            <div className="border border-primary border-1 py-8 flex flex-col justify-center">
                                <img src={volume} className="w-28 mx-auto" />
                                <p className="text-center py-5">
                                    <span className="font-semibold text-base 2xl:text-xl">
                                        Looks like you don't have any block storage volumes
                                    </span> 
                                    <br/>
                                    <span className="text-sm 2xl:text-base">
                                    Volumes are highly available units of block storage that you can attach to a 
                        VPS to increase its available space.
                                    </span> 
                                </p>
                                <Button 
                                    className="mx-auto w-32 font-semibold h-10 2xl:h-12 " 
                                    style={{borderRadius: "0px"}} 
                                    type="primary"
                                    onClick={
                                        ()=>navigate('/storage/volume/deploy?selected='+server?.Id)
                                    }
                                >
                                    Add Volume
                                </Button>
                            </div>
                        </div>
                    }
                </div> 
                :
                <div className='flex justify-center'>
                    <Spiner fontSize={40} />
                </div>
            }
        </div> 
    );
}
