import { AiFillSetting, AiOutlineLink } from "react-icons/ai";
import {AddButton} from "../../components/AddButton"
import {OkButton} from "../../components/OkButton"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Toast } from '../../components/Notify';
import CAPTAIN from '../../lib/libCaprover';
import { updateHeadLink } from "reducer/action/coreActions";
import { LinkOutlined } from "@ant-design/icons"
import { Button  } from 'antd';

export default function WebApp() {
    const { server_id } = useParams()
    const { name } = useParams()
    const {servers} = useSelector(app => app.webapp)
    const [appDefs, setAppDef] = useState([])
    const [rootDomain, setRootDomain] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
   

    async function getAllApps2(){
        const result = await CAPTAIN.getAllApps2()
        if(!result ||  !result.data) {
            return navigate('/')
        }

        const appDefs = result?.data?.appDefinitions.map(app =>{
            let versionFound = app.versions.filter(
                (v) => v.version === app.deployedVersion
            )

            let lastDeployTime = ''

            if (versionFound.length === 0) {
                console.log(
                    `App ${app.appName} has invalid deployVersion=${
                        app.deployedVersion
                    }, versions:${JSON.stringify(app.versions)}`
                )
            } else {
                lastDeployTime = versionFound[0].timeStamp || ''
            }

            return { ...app, lastDeployTime }
        })
        console.log('appDefs appDefs appDefs', appDefs)
        setAppDef(appDefs)
        setRootDomain(result?.data?.rootDomain)
    }
    const headLinks = [
        {
            item: 'kps',
            link: '/app-platform/kps',
            alt: "KPS short link"
        },
        {
            label: "Webs-apps",
            second: true
        }
    ]
    useEffect(()=>{
        dispatch(updateHeadLink(headLinks))
        getAllApps2() 
        return () =>  dispatch(updateHeadLink([]))
    }, [])

    const onShowModal = () => window.$("#amazing-app").modal('show')
       
    return (
        <div className="dark:bg-black   pb-5">
       
            {/* <div className="bg-gradient-to-b  from-primary to-light-blue-700 pt-14 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                       
                </div>                    </div>

            </div> */}
            <CreatAppModal getAllApps2={()=>getAllApps2()} />
 
            <div className="px-3 md:px-5  pb-10">
                <div className="container mx-auto max-w-full"> 
                    <div className="flex flex-wrap gap-3 px-4 mb-16  items-start">

                        {appDefs && appDefs.sort((a, b)=> new Date(b.lastDeployTime).getTime() - new Date(a.lastDeployTime).getTime()).map((app, i) =>  <AppCard navigate={navigate} server_id={server_id} rootDomain={rootDomain} key={i} app={app} />)}
                       
                       
                        <div>
                            <AddButton click fun={onShowModal} title="Custom App" duck /> 
                            <div className="h-1"/>
                            <AddButton link={"/app-platform/kps/"+server_id+"/add"} title="One-Click Apps" duck /> 
                        </div>

                        


                    </div>
                </div>
            </div>
        </div>
    );
}


export function CreatAppModal(props){
    const [loanding, setloading] = useState(false)
    const [appName, setAppName] = useState("")
    const [hasPersistency, setHasPersistency] = useState(false) 

    function onCancel(){
        return window.$("#amazing-app").modal('hide')
    }

    const registerNewApp = async () => { 
        setloading(true)
        try{
            const result = await CAPTAIN.registerNewApp(
                appName,
                hasPersistency,
                true
            )
            if(result && result.description){
                Toast.fire({
                    icon: 'success',
                    title: result.description
                })
                setAppName("")
                setHasPersistency(false)
                props.getAllApps2()
                onCancel()
            }else{
                Toast.fire({
                    icon: 'error',
                    title: "An error occured, if this persist, please tell us"
                })
            }
            
            console.log('result register new app', result)
        }catch(e){
             Toast.fire({
                icon: 'error',
                title: "An error occured, if this persist, please tell us"
            })
        }
        setloading(false)
    }

    //  async function onRebootMethod(id){
    //     setloading(true)
    //     console.log('start reboot vps')
    //     const result = await VPSapi.reboot({vps_id: id})
    //     console.log('result onReboot', result)
    //     setloading(false)
    //     onCancel()
    //     if(result &&result.status === "success"){
    //             return Toast.fire({
    //                 icon: 'success',
    //                 title: result.details
    //             })
    //     }else{  

    //         Toast.fire({
    //             icon: 'error',
    //             title: result?.message || "An error occurs, if his percist, please tell us"
    //         })
            
    //     }

    // }
    return(

        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={"amazing-app"} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              
              

                <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                    <span className="text-3xl">New custom app</span>

                    <div className="px-20 pt-5">
                            <input className=" dark:bg-black dark:border-black dark:text-darkTextColor
                                text-gray-700 w-full shadow border rounded border-gray-300 focus:outline-none focus:shadow-outline py-1 px-3 mb-3" 
                                type="text" 
                                value={appName}
                                required
                                placeholder="my-amazing-app"
                                onChange={(e) =>setAppName(e.target.value)}
                            />

                            <div className="form-check flex">
                                <input 
                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white dark:bg-black checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" 
                                    type="checkbox" 
                                    value="" 
                                    id="appCheck" 
                                    checked={hasPersistency}
                                    onChange={(e)=>{
                                        console.log('erre',e)
                                        setHasPersistency(e.target.checked)
                                    }}
                                 />
                                <label className="form-check-label " htmlFor="appCheck">
                                    Has persistent data
                               </label> 
                            </div>

                    </div>
                    <div className="pt-10 flex gap-4 justify-center">
                        <button type="button" className="
                            border-2
                            border-primary
                            text-primary dark:text-darkTextColor
                            hover:text-white
                            inline-block 
                            px-10
                            py-2.5 
                            hover:bg-primary
                            font-medium 
                            text-base 
                            leading-tight 
                            rounded-full 
                            shadow-md 
                            hover:shadow-lg 
                            focus:bg-primary
                            focus:shadow-lg 
                            focus:outline-none 
                            focus:ring-0 
                            active:bg-primary
                            active:shadow-lg 
                            transition 
                            duration-150 ease-in-out"
                            onClick={()=> onCancel()}
                        >
                            Cancel
                        </button>
                        {"  "}

                        <OkButton  title="Create" click loanding={loanding} fun={registerNewApp} /> 
                        
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}

function AppCard({app, rootDomain, server_id, navigate}) {
    const link = app.envVars.find(v => v.key === "APP_BASE_URL")

    function onConfig(){
        //navigate('/kps/deploy/'+server_id+'/'+app.appName+'/config')
    }


    return (
        <div className="py-6 px-3 min-w-[230px]  bg-grey block  rounded-lg shadow-lg   dark:bg-bgPagedark dark:text-darkTextColor">
            <div className='flex justify-center'>
                <a 
                    href={!app.notExposeAsWebApp ?
                        `http${app?.hasDefaultSubDomainSsl ? 's' : ''}://${app?.appName}.${rootDomain}`
                        :
                        "javascript:;"
                    }   
                    {...{target: !app.notExposeAsWebApp ? "_blank" : ""}} 
                    className="font-bold text-center text-sm xl:text-base 2xl:text-xl" 
                > 
                    <span className="text-primary text-center inline dark:text-darkTextColor ">
                        {app.appName}&nbsp;
                        {!app.notExposeAsWebApp ?
                            <LinkOutlined />
                            :
                            null
                        }
                    </span>
                </a>
            </div>
            <div className="text-center">
                <img src={require('../../assets/img/blue/KS-logo-round.png')} className="w-10 2xl:w-14 mx-auto" alt=""/>
                
                <Link  to={'/app-platform/kps/'+server_id+'/'+app.appName+'/config'}>
                    <Button 
                        className="mx-auto mt-3 px-4 flex justify-center items-center  rounded-xl" 
                        type="primary"
                    >
                        <AiFillSetting className='text-xl' /> &nbsp;
                        Config
                    </Button>

                </Link>

            </div>
        </div>
    )

}

