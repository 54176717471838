import { useEffect } from 'react'
import { BsFillLightbulbFill } from "react-icons/bs";
import { BsCheckCircleFill } from "react-icons/bs"

import { Spiner } from 'components/Bloc';

import Utils from '../../utils';

export default function PromoBottom(props){
    const myObserver = new ResizeObserver(entry => {
        if(props.setBottomCalculedHeight){
            props.setBottomCalculedHeight(window.$('#PromoBotomAdd').height())
        }
    });
    useEffect(() =>{
        const someEl = document.getElementById('PromoBotomAdd');
        myObserver.observe(someEl); 
        // return myObserver.disconnect();
    }, [])
  
    function calculVat(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return (Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (14.975 / 100)).toFixed(2)
    }


    const renderCurency = (veryMin) => (
        <span className={`${!veryMin ? "text-xs": "text-[11px]"}  font-bold`}>
            {Utils.renderCurencyCode()}
        </span>
    )

    const priceDisplaying = () => {
        return Utils.priceConvert((parseFloat(props.packSelected?.Price) * (1 - (props.codeApplied ? props.codeApplied?.Percent / 100 : 0))) * (1 + 14.975 / 100)).toFixed(2)
    }

    return(
        <div className='mx-auto'>  
            <div id="PromoBotomAdd" className={' fixed py-3 bottom-0 z-[5] dark:opacity-70 left-0  md:left-60 2xl:left-72 right-3 dark:text-darkTextColor dark:bg-bgPagedark   shawdow  '}>
                
                <div className='flex flex-wrap gap-4 items-end justify-center'>
                    
                    <div className='w-84 text-left text-sm 2xl:text-base text-primary dark:text-darkTextColor'>
                        <span className='text-base 2xl:text-xl font-bold'>Summary:</span> 
                        {!props?.isKube ?
                            <>
                                <>
                                    <br/>
                                    Location:{" "}
                                    <span className='font-semibold'>
                                        <>{props.vps_params?.locationO?.name || "(Not defined)" }</>
                                    </span>
                                </>
                                {!props?.isKps && !props?.isSecurity && !props?.isNetwork ?
                                    <>
                                        <br/>
                                        Image:{" "}
                                        <span className='font-semibold'>
                                             <>{props.vps_params?.image || "(Not defined)" }</>
                                        </span>
                                    </>
                                    :
                                    null
                                } 
                                <br/>
                                Plan:{" "}
                                <span className='font-semibold'>
                                    <>{
                                        props.packSelected? 
                                        <>
                                            {props.packSelected?.Name}
                                            &nbsp;-&nbsp;
                                            {renderCurency(true)}
                                            {Utils.priceConvert(props.packSelected?.Price).toFixed(2)}
                                            /month
                                        </>
                                            : 
                                            "(Not defined)" 
                                        }
                                    </>
                                </span> 
                            </>
                            :
                            null
                        }
                        <br/>
                        TPS + TVQ:{" "}
                        <span className='font-semibold'>
                            {calculVat() ? 
                                <>
                                    {renderCurency(true)}
                                    {calculVat()}
                                </> 
                                : 
                                "(Not defined)"
                            }
                        </span> 
                        <br/>

                        <p className='text-2xl font-bold'>
                            {props.codeApplied && <span  className='text-base font-bold'>Discount: &nbsp;{props.codeApplied?.Percent}% <br/> </span>}
                            <span className='text-base font-bold'>Final price: &nbsp;</span>
                            {!isNaN(priceDisplaying()) ?
                                <>
                                    {renderCurency()} 
                                    {priceDisplaying()} 
                                    <span className='font-semibold text-base'>
                                    / month (include TPS + TVQ)
                                    </span>
                                </>
                                :
                                <span className='text-base font-semibold'> (Not defined) </span>
                            }
                        </p>

                    </div>
                    <div className=''>
                        <div className=''>
                            <label
                                className="
                                        block
                                        uppercase
                                        tracking-wide
                                        text-gray-700 text-xs
                                        dark:text-darkTextColor
                                        font-bold
                                        mb-2
                                    "
                                htmlFor="trialCode"
                            >
                                Promo code
                            </label>
                            <div className="flex h-12">
                                <input
                                    className="
                                        appearance-none
                                        block
                                        w-full
                                        bg-gray-200
                                        text-gray-700
                                        border
                                        rounded
                                        py-3
                                        px-4
                                        leading-tight
                                        dark:bg-bgPagedark
                                        dark:text-darkTextColor
                                        focus:outline-none focus:bg-white
                                    "
                                    id="trialCode"
                                    value={props?.vps_params.promoCode}
                                    name="promoCode"
                                    onChange={props?.handleChange}
                                    required
                                    disabled={props?.creating || props.codeApplied}
                                    placeholder='Please enter  your promocode code'
                                />
                                {!props?.codeApplying ?
                                    <div onClick={()=> !props.codeApplied && !props?.creating && props?.onApplyCode() } className="flex px-4  cursor-pointer bg-gray-200 dark:bg-bgPagedark items-center justify-center  border">
                                        <BsCheckCircleFill className="text-2xl text-primary dark:text-darkTextColor"/>
                                    </div>
                                    :
                                    <Spiner fontSize={28} />
                                }
                            </div>
                        </div>

                        <button className="
                            flex 
                            mx-auto 
                            2xl:text-xl 
                            xl:text-base 
                            text-sm  
                            w-60 
                            xl:w-64  
                            2xl:w-72 
                            text-center
                            items-center
                            shadow
                            bg-primary
                            focus:shadow-outline 
                            focus:outline-none 
                            text-white 
                            font-bold 
                            2xl:py-4 2x:px-4 
                            xl:py-3 xl:px-3
                            py-2 px-2
                            rounded
                            "
                            type="submit"
                            disabled={props.creating}
                        >
                            {!props.creating ?
                                <span className='mx-auto flex  items-center'>
                                    <BsFillLightbulbFill className='text-2xl' />
                                    { props.soon ? " Coming soon " : "Deploy Now"}
                                </span>
                                :
                                <span className='mx-auto flex gap-1 items-center'>
                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {!props.paypal ? " Deploying" :  " Waiting for paypal"}
                                </span>

                            }
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}
