import {  useState, useEffect } from 'react';
import CAPTAIN from '../../../lib/libCaprover';
import Utils from '../../../utils';
import { useSelector } from 'react-redux';

import { Toast } from '../../../components/Notify';
import { OkButton } from '../../../components/OkButton';
import { InfoCircleOutlined } from "@ant-design/icons"
import { Tooltip, Button, Row, Col, Input, Checkbox, Modal  } from 'antd';

const Search = Input.Search


function BasicAuthModal(props){
	const auth = props?.app?.httpAuth

	useEffect(()=>{
    	props?.setTempBasicAuth({
            dialogHttpPass: auth ? auth.password || '' : '',
            dialogHttpUser: auth ? auth.user || '' : '',
        })
	}, [])

	return(
		<Modal
        destroyOnClose={true}
        footer={null}
        centered
        closeIcon={false}
        className='mPadding'
        width={500}
        open={props?.basicAuthModalDisplay}
    >
        <div className=''>
            <div className='bg-primary py-5 px-6 text-white'>
                <div className=''>
                    <h4 className="font-bold text-base xl:text-xl">
                        Edit HTTP Basic Auth
                    </h4>
                    <p className='text-sm xl:text-base'>
                        {/*Select new instance to attach. */}
                    </p>
                </div>
                
            </div>
            <div className="overflow-y-auto ">
            	<div className="w-full px-6 py-6">
            		<p>
                        HTTP Basic authentication is the simplest
                        way to enforce access controls to
                        web resources.
                    </p>
                    <p>
                        You can use it to restrict
                        access to HTTP apps, especially those you
                        create via the One-Click app deployments such as
                        phpMyAdmin, etc.
                    </p>
                    <p>
                        <Input
                            placeholder="username"
                            type="text"
                            defaultValue={props?.tempBasicAuth?.dialogHttpUser}
                            onChange={(event) =>
                                props?.setTempBasicAuth({
                                	...props?.tempBasicAuth, 
                                    dialogHttpUser: (
                                        event.target.value || ''
                                    ).trim(),
                                })
                            }
                        />
                    </p>
                    <p className="pt-3">
                        <Input
                            placeholder="password"
                            type="text"
                            defaultValue={props?.tempBasicAuth?.dialogHttpPass ? "[hidden]" : ""}
                            onChange={(event) =>
                                props?.setTempBasicAuth({
                                	...props?.tempBasicAuth,
                                    dialogHttpPass: (
                                        event.target.value || ''
                                    ).trim(),
                                })
                            }
                        />
                    </p>					
				</div>
            </div>
            <div className="py-5 flex gap-2 justify-end  px-6">
               
                	<Button size="large" onClick={() => {
                          props?.setOpenBasicAuth(false); 
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                    	className="flex items-center gap-2" 
                    	size="large" type="primary"  
                    	onClick={() => {
                            props?.onConfirmBasicAuth()
                        }}
                    >
                        Confirm
                    </Button>
        		
        	</div>
        </div>
    </Modal>
	)
}

export default function Http(props){
	const [loadhttp, setLoadhttp] = useState(false)
	const [newDomain, setNewDomain] = useState(false)
	const [tempBasicAuth, setTempBasicAuth] = useState({})
	const {messageApi} = useSelector(app => app.core )
	const [globalLoading, setGlobalLoading] = useState(false)
	const [httpsSubdomainEnabled, setHttpsSubdomainEnabled] = useState({})
	const [httpsSubdomainRemoved, setHttpsSubdomainRemoved] = useState({})
	const [app, setApp] = useState(null)
	const [basicAuthModalDisplay, setOpenBasicAuth] = useState(null)
    const rootDomain = props?.apiData?.rootDomain
    const basicAuthUsername = props?.apiData?.appDefinition.httpAuth
            ? props?.apiData?.appDefinition.httpAuth.user
            : ''

	useEffect(()=>{
	    setApp(props?.apiData?.appDefinition)
	}, [props?.apiData?.appDefinition])

    const enableDefaultHttps = () => {
        setLoadhttp(true)

        return Promise.resolve()
            .then(function () {
                return CAPTAIN.enableSslForBaseDomain(
                    app?.appName
                )
            })
            .then(function (data) {
				console.log('data data data enabled https', data)
				if(!data?.error || [100, 101, 102].includes(data?.status)){
			        messageApi.success(
			            'HTTPS is now enabled for your app'
			        )
		        }else{
		        	messageApi.error(
			            data?.description || data?.message || "An error occurs, please try again or contact us!"
			        )
		        }
            })
            .then(function (error) {
                setLoadhttp(false)
				if(error) return
                props.reFetchData()
            })
            .catch((e)=>{
            	console.log('error https', e)
	                Toast.fire({
	                    icon: 'error',
	                    title: 'An error occurs, please contact us !'
	                })
	                setLoadhttp(false)
               	}
            )
    }

    async function onConfirmBasicAuth() {
    	const newApiData = Utils.copyObject(props.apiData)
        const enteredUser = tempBasicAuth.dialogHttpUser
        const enteredPassword = tempBasicAuth.dialogHttpPass

        if (!enteredUser || !enteredPassword) {
            newApiData.appDefinition.httpAuth = undefined
        } else {
            newApiData.appDefinition.httpAuth = newApiData.appDefinition
                .httpAuth || { user: '' }

            newApiData.appDefinition.httpAuth.user = enteredUser
            newApiData.appDefinition.httpAuth.password = enteredPassword
        }
        props.updateApiData(newApiData)
        setOpenBasicAuth(false)
        return props.onUpdateConfigAndSave()
    }
    async function onRemoveCustomDomainClicked(customDomain) {
        setHttpsSubdomainRemoved({[customDomain]: true})
        const globalData = {
        	path: "/user/apps/appDefinitions/removecustomdomain",
        	data: {
                appName: app?.appName,
                customDomain,
            }
        }
        const result = await CAPTAIN.globalPoster(globalData);
        props.reFetchData()
        setHttpsSubdomainRemoved({})
        console.log(' result result ', result)
        if(!result?.error || [100, 101, 102].includes(result?.status)){
	        messageApi.success(
	            'Your custom domain is successfully removed!'
	        )
        }else{
        	messageApi.error(
	            result?.description || result?.message || "An error occurs, please try again or contact us!"
	        )
        }
	}
    //test.geekcorp.ca
    async function onEnableCustomDomainSslClicked(customDomain) {
        setHttpsSubdomainEnabled({[customDomain]: true})
        const globalData = {
        	path: "/user/apps/appDefinitions/enablecustomdomainssl",
        	data: {
                appName: app?.appName,
                customDomain,
            }
        }
        const result = await CAPTAIN.globalPoster(globalData);
        setHttpsSubdomainEnabled({})
        console.log(' result result ', result)
        if(!result?.error || ![100, 101, 102].includes(result?.status)){
	        messageApi.success(
	            'HTTPS is successfully activated for your domain!'
	        )
        }else{
        	messageApi.success(
	            result?.description || "An error occurs, please try again or contact us!"
	        )
        }
		props.reFetchData()
    }

    function onEditDefaultNginxConfigClicked() {
        const newApiData = Utils.copyObject(props.apiData)
        newApiData.appDefinition.customNginxConfig = props.apiData?.defaultNginxConfig
        props.updateApiData(newApiData)
    }

    function createCustomNginx() {
        const customNginxConfig = app?.customNginxConfig
        if (!customNginxConfig) {
            return (
                <div>
                    <Button
                        type="default"
                        onClick={() => onEditDefaultNginxConfigClicked()}
                    >
                        Edit Default Nginx Configurations
                    </Button>
                </div>
            )
        }

        return (
            <div>
                <p>
                    Templates are built using EJS template pattern. Do not
                    change the areas between <code>&lt;%</code> and{' '}
                    <code>%&gt;</code> , unless you really know what you're
                    doing! To revert to default, simply remove all the content.
                </p>
                <Input.TextArea
                    style={{
                        fontFamily: 'monospace',
                    }}
                    onChange={(e) => {
                        // const newApiData = Utils.copyObject(this.props.apiData!)
                        // newApiData.appDefinition.customNginxConfig =
                        //     e.target.value
                        // this.props.updateApiData(newApiData)
                    }}
                    rows={17}
                    defaultValue={customNginxConfig}
                />
            </div>
        )
    }
    async function onConnectNewDomainClicked(customDomain) {
    	setNewDomain(true)
        const globalData = {
        	path: "/user/apps/appDefinitions/customdomain",
        	data: {
                appName: app?.appName,
                customDomain,
            }
        }
        const result = await CAPTAIN.globalPoster(globalData);
		props.reFetchData()
        setNewDomain(false)
        console.log(' result result ', result)
        if(result?.error || ![100, 101, 102].includes(result?.status)){
        	messageApi.error(
	            result?.description || result?.message || "An error occurs, please try again or contact us!"
	        )
        }else{
	        messageApi.success(
	            'New domain is now successfully connected!'
	        )
        }
    }
    function createCustomDomainRows() {
        const customDomains = app?.customDomain || []

        const rows =  []
        customDomains.forEach((c) => {
            const row = (
                <Row key={c.publicDomain} style={{ marginTop: 15 }}>
                    <Button.Group size="small">
                        <Tooltip
                            title={
                                c.hasSsl
                                    ? 'Already activated'
                                    : 'Click to activate HTTPS for this domain'
                            }
                        >
                            <Button
                                // disabled={c.hasSsl}
                                onClick={() => {
                                	if(!c.hasSsl){
	                                    onEnableCustomDomainSslClicked(
	                                        c.publicDomain
	                                    )
                                	}
                                }}
                                loading={httpsSubdomainEnabled?.[c.publicDomain]}
                                type="primary"
                            >
                                {c.hasSsl ? "HTTPS enabled" : "Enable HTTPS"}
                            </Button>
                        </Tooltip>
                        <Button
                            style={{ marginRight: 20 }}
                            loading={httpsSubdomainRemoved?.[c.publicDomain]}

                            onClick={() => {
                                onRemoveCustomDomainClicked(c.publicDomain)
                            }}
                        >
                            Remove
                        </Button>
                    </Button.Group>

                    <a 
                    	className="text-blue-500 md:ml-[15px]" 
                    	href={`http${c.hasSsl? "s":""}://${c.publicDomain}`} 
                    	target="_blank" 
                    	rel="noopener noreferrer"
                    >
                        {c.publicDomain}
                    </a>
                </Row>
            )
            rows.push(row)
        })

        return rows
    }

	return(
		<div className=" ">
			<p>
                Your app is internally available as{' '}
                <code className="text-red-500 bg-red-50">srv-captain--{app?.appName}</code> to other Captain
                apps. In case of web-app, it is accessible via{' '}
                <code className="text-red-500 bg-red-50">{`http://srv-captain--${app?.appName}`}</code> from
                other apps.
            </p>

            <br/>
            <BasicAuthModal 
            	app={app}
            	setTempBasicAuth={setTempBasicAuth}
            	basicAuthModalDisplay={basicAuthModalDisplay}
            	tempBasicAuth={tempBasicAuth}
            	setOpenBasicAuth={setOpenBasicAuth}
            	onConfirmBasicAuth={onConfirmBasicAuth}
            />


            <div className="" >
            	{app &&
		            <Checkbox
		                defaultChecked={
		                    app?.notExposeAsWebApp
		                }
		                onChange={(e) => {
		                    const newApiData = Utils.copyObject(props?.apiData)
		                    newApiData.appDefinition.notExposeAsWebApp =
		                        !!e.target.checked
		                    props.updateApiData(newApiData)
		                }}
		            >
		                Do not expose as web-app
		            </Checkbox>
            	}
	            <Tooltip title="Use this if you don't want your app be externally available.">
	                <InfoCircleOutlined />
	            </Tooltip>
	        </div>
		    {!app?.notExposeAsWebApp &&
		    	<div>
				    <p className="pt-6">Your app is publicly available at:</p>

				    <br/>
				    
				    <Row>
	                    <Button.Group size="small">
	                        <Tooltip
	                            title={
	                                app?.hasDefaultSubDomainSsl
	                                    ? 'Already activated'
	                                    : 'Click to activate HTTPS for this domain'
	                            }
	                        >
	                            <Button
	                                onClick={() => {
	                                	if(app?.hasDefaultSubDomainSsl){
	                                		messageApi.success('SSL Certificate is already activated')
	                                	}else{
	                                    	enableDefaultHttps()
	                                	}
	                                }}
	                                type="primary"
	                                loading={loadhttp}
	                            >
	                                {app?.hasDefaultSubDomainSsl ?
	                                	"HTTPS enabled"
	                                	:
	                                	"Enable HTTPS"
	                                }
	                            </Button>
	                        </Tooltip>
	                    </Button.Group>

	                    <a
	                        href={`http${app?.hasDefaultSubDomainSsl ? 's' : ''}://${
	                            app?.appName
	                        }.${rootDomain}`}
	                        target="_blank" rel="noopener noreferrer"

	                    >
	                        <span
	                            className="text-blue-500 md:ml-[15px]"
	                        >
	                            {`http${app?.hasDefaultSubDomainSsl ? 's' : ''}://${
	                                app?.appName
	                            }.${rootDomain}`}
	                        </span>
	                    </a>

				    </Row>
				    <div>
				    	{createCustomDomainRows()}
				    </div>
				    <br/>
				    <Row>
	                    <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                            <Search
                                placeholder="www.the-best-app-in-the-world.com"
                                enterButton="Connect New Domain"
                                onSearch={(value) =>{
                                    onConnectNewDomainClicked(value)
                                }}
                                loading={newDomain}
                            />
	                    </Col>
	                    &nbsp;&nbsp;&nbsp;
	                    <Tooltip title="Make sure the new domain points to this IP, otherwise verification will fail.">
	                        <span>
	                            <InfoCircleOutlined style={{ marginTop: 9 }} />
	                        </span>
	                    </Tooltip>
	                </Row>

	                <br />
	                	{createCustomNginx()}
	                <br />
	                <div className="md:w-[300px] w-full">
	                	{app && 

	                        <Tooltip title="HTTP port inside the container. Default is 80. Change only if the app is running in a different port. This is used only for HTTP apps, not databases.">
	                            <Input
	                                addonBefore={`Container HTTP Port `}
	                                type="number"
	                                defaultValue={
	                                    app?.containerHttpPort
	                                        ? app?.containerHttpPort + ''
	                                        : ''
	                                }
	                                onChange={(e) => {
	                                    const newApiData = Utils.copyObject(
	                                        props.apiData
	                                    )
	                                    newApiData.appDefinition.containerHttpPort = Number(e.target.value)
	                                    props.updateApiData(newApiData)
	                                }}
	                            />
	                        </Tooltip>
	                	}
	                </div>

	                <br />

	                {typeof app?.forceSsl !== "undefined" &&
		                <Row>
		                    <Checkbox
		                        defaultChecked={app?.forceSsl}
		                        onChange={(e) => {
		                            const newApiData = Utils.copyObject(
		                                props.apiData
		                            )
		                            newApiData.appDefinition.forceSsl =
		                                !!e.target.checked
		                            props.updateApiData(newApiData)
		                        }}
		                    >
		                        Force HTTPS by redirecting all HTTP traffic to HTTPS
		                    </Checkbox>
		                    <Tooltip title="Forcing HTTPS causes domains without HTTPS to malfunction. Make sure you enable HTTPS for the domain you want to use, before enabling Force HTTPS option.">
		                        <InfoCircleOutlined />
		                    </Tooltip>
		                </Row>
		            }
	                <br />
	                {typeof app?.websocketSupport !== "undefined" &&
		                <Row>
		                    <Checkbox
		                        defaultChecked={app?.websocketSupport}
		                        onChange={(e) => {
		                            const newApiData = Utils.copyObject(
		                                props.apiData
		                            )
		                            newApiData.appDefinition.websocketSupport =
		                                !!e.target.checked
		                            props.updateApiData(newApiData)
		                        }}
		                    >
		                        Websocket Support
		                    </Checkbox>
		                    <Tooltip title="Adds the upgrade proxy headers to NGINX config.">
		                        <InfoCircleOutlined />
		                    </Tooltip>
		                </Row>
	                }
	                <br />

	                <div className="flex items-center">
	                    <Button
	                        style={{ marginRight: 20 }}
	                        type="default"
	                        onClick={() => setOpenBasicAuth(true)}
	                    >
	                        Edit HTTP Basic Auth
	                    </Button>
	                    <div className="h-[10px] md:h-[0px] " />
	                    <span>
	                        Current State:{' '}
	                        <b>{!basicAuthUsername ? 'inactive' : 'active'}</b>{' '}
	                        {basicAuthUsername
	                            ? `[user: ${basicAuthUsername}@password: <HIDDEN>]`
	                            : ''}
	                    </span>
	                </div>

		            <h3 className="text-xl font-semibold"></h3>
		            <div className="mx-auto gap-4 justify-center pb-5  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3    mt-10">
		                
		             </div>
		        </div>
		    }
        </div>
	)
}






