import React, { useState, useEffect } from "react";
import { OkButton } from "../../../components/OkButton";
import { NetworkRender } from "../../../components/Bloc";
import UserMgt from '../../../lib/user_managment';
import LB from '../../../lib/loadBalancer';
import { message } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../../../components/TabSelector";
import { updateHeadLink } from "reducer/action/coreActions";
import { useDispatch, useSelector } from "react-redux";
import { Spiner } from '../../../components/Bloc'
import Utils from '../../../utils'
import kslb from '../../../assets/img/networking/icon.png'
import setting from '../../../assets/img/networking/setting.png'
import { FaLeanpub } from "react-icons/fa";
import { Button } from 'antd';
import { Services, Rules } from './ManageKsLbSection';
import { Delete } from "../../../components/Modals";



const tabs = [
	// "Rules",
	"Service",
	// "Networking",
	"Delete",
]
export default function KSLbManange(){
	const { id } = useParams()
	const [server, setvps] = useState(null)
	const [loading, setLoadind] = useState(false)
	const [loadBalancer, setLoadBalancer] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [selectedTab, setSelectedTab] = useTabs(tabs);
	const {messageApi} = useSelector(app => app.core )



   async function onDelete() {
		Utils.dispatchToDelete(server, '/compute/kslb')
	}

	const loadVps = async () => {
        const vps = await UserMgt.getUniqueVps(id) 
		console.log('vps vps ', vps)
        if(!vps?.error){
            setvps(vps?.server)
            // const result = await LB.create({Id: id})
            return vps?.server
        }else{
            messageApi.open({
                type: 'error',
                content: 'An error occured, if this persist, please tell us',
            });
            return navigate(-1)
        }
    }
    const loadLoadBalancer = async (Id) => {
		setLoadind(true)
    	const loadBalancerServId = Id || server?.Id
    	const result = await LB.listLoadBalancer(Id)
    	setLoadBalancer(result?.lb)
		setLoadind(false)
        
    }


    useEffect(()=>{
        loadVps().then((server) => {
            const headLinks = [
                {
                    link: "/networking/kslb",
                    item: 'kslb',
                    alt: "kslb short link"
                },
                {label: server?.DisplayName, arrow: true}
    
            ]
            dispatch(updateHeadLink(headLinks))
            loadLoadBalancer(server?.Id)   
        })
        return () => dispatch(updateHeadLink([]))
    }, [])

	return(
		<div>
			<div style={{display: !server && "none"}} className=" dark:text-darkTextColor container mx-auto max-w-full px-3 md:px-8 pb-10">
				<div className="md:flex gap-6 items-center">
	                <div className="md:w-1/12 md:flex hidden">
	                   <img
	                      src={kslb}
	                      alt=""
	                      className="w-20 h-20  2xl:w-28 2xl:h-28"
	                   />
	                </div>
	                <div className="md:w-11/12 w-full ">
	                   <div>
	                      
	                      <div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
	                         <div>
	                         <img
	                            src={kslb}
	                            alt=""
	                            className="w-14 h-14 md:hidden"
	                         />
	                            <span className="2xl:text-3xl text-xl xl:text-2xl">
	                               {server?.DisplayName}
	                            </span>{" "}
	                            <br />
	                            <span className="text-sm 2xl:text-base">
	                            {server?.type?.OpenstackFlavorName}</span>{" "}
	                            <br />
	                            <span className="text-sm 2xl:text-base">
	                               {server?.location?.name || "Montreal, Zone A"}
	                            </span>{" "}
	                            <br />
	                         </div>

	                         <div className="">
	                            <div className="flex flex-col md:flex-row gap-2 md:gap-4">
	                               
	                               <OkButton 
		                               loanding={false} 
		                               click server={server} 
		                               fun={() => navigate('/networking/kslb/'+server?.Id+'/load-balance/new')} 
		                               title={"Add Services"} 
	                               />
	                            </div>
	                            <p className="text-right pt-1 text-sm 2xl:text-base">State: <span className="font-bold">{server?.VpsStatus === "up" ? "Running" : server?.VpsStatus === "down" ? "Stopped" : server?.VpsStatus} </span></p>
	                         </div>

	                      </div>
	                   </div>

	                   <div className="grid grid-cols-1 md:grid-cols-2 text-sm 2xl:text-base">
	                      <div className="text-left">
	                         <p className="text-left pt-2">
	                            Public hostname:{" "}
	                            <span className="font-bold text-primary">
	                            	{server?.domain}
	                            </span>{" "}
	                            <br />
	                            Private hostname:{" "}
	                            <span className="font-bold  text-primary">
	                               {server?.OpenstackInstanceName }
	                            </span>{" "}
	                            <br />
	                         </p>
	                      </div>
	                      <div className="text-left md:text-right">
	                         <p className="text-left md:text-right pt-2">
	                            Public IP:{" "}
	                            <span className="font-bold text-primary">
	                               {server?.OpenstackInstanceExtIp}
	                            </span>{" "}
	                            <br />
	                            InterconnectKS IP:{" "}
	                            <span className="font-bold  text-primary">
	                               {server?.netmaker?.map((net, i) => {
	                               	return (i != 0 ? ", ": "")+net?.node?.address?.IP
	                               })}
	                            </span>{" "}
	                            <br />
	                            VPC IP:{" "}

	                            <span className="font-bold  text-primary">
	                            	<NetworkRender
										vpc={server?.vpc}
									/>
	                            </span>{" "}
	                            
	                         </p>
	                      </div>
	                   </div>
	                </div>
	            </div>

	            <div className="mt-2">
	            	<nav className="flex border-b border-gray-300 dark:border-gray-800">
	                	{tabs.map(tab =>
                    		<TabSelector 
                              isActive={selectedTab === tab}
                              onClick={() => setSelectedTab(tab)}
                            >
                            	{tab}
                            </TabSelector>
	                	)}
	                </nav>
				</div>
				<div className="mt-3">
                	<TabPanel hidden={selectedTab !== "Service"}>
						
						<Services loading={loading} reloadBalance={loadLoadBalancer} server={server} loadBalancer={loadBalancer} />
                	</TabPanel>
					{/*<TabPanel hidden={selectedTab !== "Rules"}>
						<Rules server={server} />
                	</TabPanel>*/}
                	<TabPanel hidden={selectedTab !== "Networking"}>
						{/*<Services server={server} />*/}
                	</TabPanel>
                	<TabPanel hidden={selectedTab !== "Delete"}>
						<div className="flex gap-2 items-center">
                            <p className="font-bold text-primary text-xl 2xl:text-2xl">
                               Delete this instance
                            </p>
                         </div>
                         <p className="2xl:text-base text-sm pt-2">
                            Deleting this instance will permanently destroy it including all of its data.
                         </p>
                         <p className="2xl:text-base text-sm font-bold pt-2">
                            Are you sure you want to delete this instance?
                            <br />
                            <br />
                            <Delete
                               id={"Delete" + server?.Id}
                               server={server}
                            />
                            <OkButton
                               danger
                               loanding={false} 
                               click
                               server={server}
                               fun={()=>onDelete()}
                               title={"Delete"}
                            />
                         </p>
                	</TabPanel>
				</div>
			</div>

			<div style={{display: server && "none"}} className='flex h-[400px] items-center justify-center'>
            	<Spiner fontSize={50} />
            </div>
		</div>
	)
}