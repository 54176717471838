import { useEffect, useState } from 'react';
import { BsFillLightbulbFill } from "react-icons/bs";
import { useSelector } from 'react-redux';

import { PageTitle } from "components/Bloc";
import { VpsMiniPack, VpsPack } from 'components/vps/Packages';

import { Toast } from '../../../components/Notify';
import { Loader, OkButton } from '../../../components/OkButton';
import UserMgt from '../../../lib/user_managment';
import VPSapi from '../../../lib/vps_service';

export default function UpgradeVps({server, vpsId, loadVps, isMini}) {

    const { flavors } = useSelector(app => app.vps)
    const { vps_packages } = useSelector(app => app.vps)

    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [creating, setCreating] = useState(false)
    const [created, setCreated] = useState(false)
    const [packSelected, setPack] = useState(null)
    const [paypalLink, setPaypalLink] = useState("")



    function onClaculFlavor(pack) {
        setPack(pack)

    }

    async function loadFlavors() {
        !flavors && VPSapi.flavor_list() 
    }
   
     async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }

    const onSleep = (likk) => {
        setTimeout(()=>{
            window.open(likk, '_blank')
            window.$("#Noticedbill").modal('hide')
        }, 9000)


    }
    const onUpgrade = async (e) => {
        e.preventDefault()
        setCreating(true)
        let result;
        try{
            const data = {
                vpsId: vpsId,
                plan_id: packSelected.PaypalPackId,
                newPlanId: packSelected?.Id,
                openstackInstanceId: server.OpenstackInstanceId,
            }
            result = await UserMgt.onUpdate(data)
            console.log('result result ', result)
        }catch(e){
            console.log('error on upgrade', e) 
        } 
        setCreating(false)
        if(result && !result?.error){ 
                // Toast.fire({
                //     icon: 'success',
                //     title: result.server
                // })
                if(result?.paypal){
                    const likk = (result?.data?.links.find(l => l.rel == "approve"))?.href
                    setPaypalLink(likk)
                    // await UserMgt.get_user_vps();
                    // loadVps()
                    setCreated(false)
                    onSleep(likk)
                    return window.$("#Noticedbill").modal('show')
                }else{
                    loadVps(true)
                    Toast.fire({
                        icon: 'success',
                        title: result.message || "Server upgraded."
                    })

                }
        }else{ 

            Toast.fire({
                icon: 'error',
                title: result?.message || "An error occured, if this persist, please tell us"
            })
            
        }
        
    }



     useEffect(() => {
        loadFlavors()
        getVpsTypes()
       
        
    }, [])

    let actualVpsType = {}
    let resultVPS = []
    if(server && server.VpsTypeId && vps_packages){ 
        actualVpsType = vps_packages.find(p => p.Id === server.VpsTypeId) 
        resultVPS =  vps_packages.filter(p => {
            const shouldTrue = p.service?.task_name === (isMini ? "vpsmini" :  "vps")
            return shouldTrue 
                && 
                parseInt(p.Price) !== parseInt(actualVpsType.Price) 
                &&  
                parseInt(p.Price) > parseInt(actualVpsType.Price)
        }).sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    } 

    return (
        <div className=' '>
            <NoticeModal link2={paypalLink} />
            {vps_packages && !created ?
                <form onSubmit={onUpgrade} className="">
                    
                    <div className="flex gap-2 items-center">
                        <PageTitle text="Choose your upgrade plan" />
                    </div>

                    <div className="mx-auto gap-2  justify-center  pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2   md:w-11/12 mt-10">
                        {isMini ?
                            <VpsMiniPack
                                concertPack={resultVPS || []}
                                setSelected={setSelected}
                                creating={creating}
                                clicked={clicked}
                                setClicked={setClicked}
                                onClaculFlavor={onClaculFlavor}
                                setPackPaypal={()=>{}}
                                selected={selected} 
                                noMxauto
                            />
                            :
                            <VpsPack
                                concertPack={resultVPS || []}
                                setSelected={setSelected}
                                creating={creating}
                                clicked={clicked}
                                setClicked={setClicked}
                                onClaculFlavor={onClaculFlavor}
                                setPackPaypal={()=>{}}
                                selected={selected}
                                noMxauto
                            />

                        }

                        {resultVPS && resultVPS.length === 0 ? <span className=" text-2xl font-semibold dark:text-darkTextColor">No upgrade plan available !</span> : null}
                    </div>


                    {resultVPS && resultVPS.length > 0 ? 

                        <div className="mx-auto">
                            <div className=''>
                                <button className="flex mt-4 mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                    type="submit"
                                    disabled={creating}
                                >
                                        {!creating ?
                                            <span className='mx-auto flex  items-center'>
                                                <BsFillLightbulbFill className='text-2xl' />
                                                {" Upgrade now"}
                                            </span>
                                            :
                                            <span className='mx-auto flex  items-center'>
                                                <Loader white />
                                            </span>
                                        }
                                </button>
                            </div>
                        </div>
                        :
                        null
                    }
                </form>
                :
                <div className='flex justify-center pt-48'>
                    <svg className="animate-spin  -ml-1 mr-3 h-12 w-12 text-primary dark:text-darkTextColor mx-auto" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            }
        </div> 
    );
}

function NoticeModal({link2}){
    const [loanding, setloading] = useState(false)

    function onCancel(){
        return window.$("#Noticedbill").modal('hide')
    }

  
    return(

        <div className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={"Noticedbill"} tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              
              

                <div className="dark:bg-bgPagedark modal-body relative py-6 text-center">
                    <span className="text-3xl">Info</span>

                    <p className="pt-5">
                        Wait a few seconds to be redirected on PayPal website to approve upgrade.
                    </p>
                    <div className="pt-10 flex justify-center">
                        
                        <OkButton ready title="Ok, Understood" fun={onCancel} loanding={false}/>
                        
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}
