import { LoadingOutlined, QuestionOutlined } from "@ant-design/icons";
import { Button as AntButton, Input, Space } from "antd";
import { Spin } from "antd";
import { Tooltip } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { AiFillDelete } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";
import { FaMinusCircle } from "react-icons/fa";
import { IoIosCopy } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { updateHeadLink } from "reducer/action/coreActions";

import apache from "../../../assets/img/blue/apache.png";
import blueprint from "../../../assets/img/blue/blueprint-KS.png";
import nginx from "../../../assets/img/blue/nginx.png";
import wordpress from '../../../assets/img/blue/wordpress-logo.png'
import debian from "../../../assets/img/debian.png";
import ubuntu from "../../../assets/img/ubuntu.png";
import { InputPassAdd, InputTextAdd, LabelAdd, PageTitle, Spiner } from '../../../components/Bloc';
import BlueprintCart from '../../../components/BlueprintCart'
import { Delete } from "../../../components/Modals";
import { ModalConfirm } from "../../../components/Modals";
import { Toast } from "../../../components/Notify";
import { OkButton } from "../../../components/OkButton";
import { TabSelector } from "../../../components/TabSelector";
import UserMgt from "../../../lib/user_managment";
import Utils from "../../../utils";
import UpgradeVps from '../manage/UpgradeVps'
import RebuildMini from './RebuildMini'

const imgs = {
   ubuntu,
   debian,
};
const tab = ["ubuntu", "debian"];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function VpsMiniManage() {
   const { id } = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch()

   const [visibleCustomDOmain, showCustomDomain] = useState(false);
   const [textTtoooltip, setTool] = React.useState("Copy to clipboard");
   const [textTtoooltip2, setTool2] = React.useState("Copy to clipboard");
   const [server, setvps] = useState(null);
   const [onRun, setonRun] = useState({});
   const [customDomainFind, setRunCustomDomainList] = useState(false);
   const [deletedRun, setDeletedDomain] = useState({});

   const [updatingPassword, setUpdatingPassword] = useState(false)

   const [update, setUpdate] = useState({
      password: null,
      confirmation: null
   })

   const {messageApi} = useSelector(app => app.core )
   const [domains, setDomainsArray] = useState([]);
   const [mkActOb, setRunMkACtion] = useState({});

   const shareUrl = "ssh -p " + server?.SshPort + " " + server?.SshUsername + "@" + server?.OpenstackInstanceExtIp;
   const shareUrl2 = "sftp -oPort=" + server?.SshPort + " " + server?.SshUsername + "@" + server?.OpenstackInstanceExtIp;

   const [selectedTab, setSelectedTab] = useTabs([
      "Overview",
      "Console",
      // "Preview",
      "Delete",
      "Rebuild",
      "Files",
      "Upgrade",
      "Reset Password",
   ]);

   const handleChange = (ev) => {
        const { name, value } = ev.target
        return setUpdate({...update, [name]: value})
    }

   async function loadVps(initial = false) {
      const vps = await UserMgt.getUniqueVps(id, true)
      console.log('server server  ', vps)
      if(!vps?.error){
         setvps(vps?.server);
         setvps(vps?.server);
         listCustomDomain(vps?.server);
         return vps?.server
      }else{
         navigate(-1)
      }
   }

   const onUpdatePassword = async () => {
        try {
         if(
            update.password && 
            (String(update.password).trim()).length >= 6 
         ){
            if (Utils.testPassworsVps(update.password)) {
                  return messageApi.open({
                      type: 'error',
                      content: "Password should not contains empty space"
                  })
              }

            if(update.password === update.confirmation){
               setUpdatingPassword(true)
               const data = {
                  // action: "reset_password",
                  password: update.password
               } 
               const result = await UserMgt.onVpsAction("reset_password", server?.Id,  data)
               setUpdatingPassword(false)
               if(!result.error){
                  messageApi.open({
                     type: 'success',
                     content: 'Password updated.',
                  });
                  await loadVps(true)
               }else{
                  messageApi.open({
                     type: 'error',
                     content: result?.message || 'An error occurs, please try again or contact us.',
                  });
               }
            }else{
               messageApi.open({
                  type: 'error',
                  content: 'The new password field and confirmation field values should be the same.',
               });
               return
            }
         }else{
            messageApi.open({
               type: 'error',
               content: 'The new password must be at least 6 characters.',
            });
            return
         }
      } catch (error) {
         console.log('Update password error ', error)
      }
    }


   function confirmBlueprint(item) {
      return window.$("#" + item).modal('show')
   }
   async function listCustomDomain(server) {
      setRunCustomDomainList(true);
      const result = await UserMgt.get_custom_domain(
         server?.OpenstackInstanceName
      );
      setDomainsArray(result?.domains);
      console.log("result result result Custom doamain", result);
      setRunCustomDomainList(false);
      showCustomDomain(false);
   }
   async function onRemoveCustomDOmain(domain) {
      setDeletedDomain({ [domain]: true });
      const d = {
         domain: domain
      }
      const result = await UserMgt.onVpsAction("delete_custom_domain", id, d);
      console.log("result result result deleted", result);
      setDeletedDomain({});
      if (!result.error) {
         Toast.fire({
            icon: "success",
            title: result?.message,
         });
         return listCustomDomain(server);
      }
      Toast.fire({
         icon: "error",
         title: "An error occured, if this persist, please tell us",
      });
   }


   async function mkAct(payload, data) {
      console.log('START STOP', payload)
      setRunMkACtion({ [payload]: true })
      const result = await UserMgt.onVpsAction(payload, server?.Id)
      setRunMkACtion({})
      console.log('START STOP', result)
      if (result && !result.error) {
         loadVps(true)
         // else await UserMgt.get_user_vps()
         Toast.fire({
            icon: 'success',
            title: result.details || result.message
         })
         if(payload === "stop") return navigate("/compute/vpsmini")
      } else {

         Toast.fire({
            icon: 'error',
            title: result?.message || "An error occured, if this persist, please tell us"
         })

      }
      

   }
   async function onRbuild(payload, data) {
      setRunMkACtion({ [payload]: true })
      console.log('start rebuild vps')
      const result = await UserMgt.rebuildVps(payload, server?.OpenstackInstanceName, data)
      console.log('result  rebuild', result)
      setRunMkACtion({})
      if (result && (result.status === "success" || result.statusCode === "200")) {
         loadVps(true)
          Toast.fire({
            icon: 'success',
            title: result.details || result.message
         })
      } else {

         Toast.fire({
            icon: 'error',
            title: result?.message || "An error occured, if this persist, please tell us"
         })

      }
      return result && (result.status === "success" || result.statusCode === "200")

   }

   // async function onDeleteMethod(id) {
   //    if (!window.confirm("Do you realy want to delete this instance?"))
   //       return;

   //    setonRun({ delete: true });
   //    console.log("start del vps ", { server_id: id });
   //    const result = await VPSapi.del({ server_id: id });
   //    console.log("result ondel", result);
   //    setonRun({ delete: null });

   //    if (result && result.status !== "error") {
   //       return Toast.fire({
   //          icon: "success",
   //          title: result.details,
   //       });
   //    } else {
   //       Toast.fire({
   //          icon: "error",
   //          title:
   //             result?.details ||
   //             "An error occured, if this persist, please tell us",
   //       });
   //    }
   // }

   useEffect(() => {
      loadVps().then(vps =>{

         const headLinks = [
               {
                  link: "/compute/vpsmini",
                  item: 'vpsmini',
                  alt: "VPS short link"
               },
               {label: vps?.DisplayName, arrow: true},
            ]
         dispatch(updateHeadLink(headLinks))
      }); 
   }, []);

   async function onDelete() {
		Utils.dispatchToDelete(server, '/compute/vpsmini')
	}

   const onCopy = () => {
      navigator.clipboard.writeText(shareUrl);
      setTool("Copied");
   };
   const onCopy2 = () => {
      navigator.clipboard.writeText(shareUrl2);
      setTool2("Copied");
   };

   return (
      <>
         <ModalConfirm loadVps={loadVps} concernBlue="blueprint-wordpress" server={server} item="Wordpress" vpsmini credentials />
         <ModalConfirm loadVps={loadVps} concernBlue="blueprint-nginx" server={server} item="Nginx" vpsmini />
         <ModalConfirm loadVps={loadVps} concernBlue="blueprint-apache" server={server} item="Apache" vpsmini />
         <div className=" dark:text-darkTextColor pb-10">
            <div className="container mx-auto max-w-full px-3  md:px-8">
               {server && (
                  <div>
                     <div className="md:flex gap-6 items-center">
                        <div className="md:w-1/12 md:flex hidden">
                           <img
                              src={
                                 imgs[
                                 tab.find((k) =>
                                    server?.OpenstackInstanceImageName?.toLowerCase().includes(
                                       k
                                    )
                                 )
                                 ]
                              }
                              alt=""
                              className="w-14 h-14 xl:w-16 xl:h-16 2xl:w-20 2xl:h-20"
                           />
                        </div>
                        <div className="md:w-11/12 w-full ">
                           <div>
                              
                              <div className="flex justify-between items-end border-b-2 border-slate-300 dark:border-gray-800 pb-3 pt-2">
                                 <div>
                                 <img
                                    src={
                                       imgs[
                                       tab.find((k) =>
                                          server?.OpenstackInstanceImageName?.toLowerCase().includes(
                                             k
                                          )
                                       )
                                       ]
                                    }
                                    alt=""
                                    className="w-14 h-14 md:hidden"
                                 />
                                    <span className="2xl:text-3xl text-xl xl:text-2xl">
                                       {server?.DisplayName || server?.OpenstackInstanceName}
                                    </span>{" "}
                                    <br />
                                    <span className="text-sm 2xl:text-base">
                                       {server?.type?.OpenstackFlavorName}
                                    </span>{" "}
                                    <br />
                                    <span className="text-sm 2xl:text-base">
                                       {server?.type?.Option?.split(",")[0]}
                                    </span>{" "}
                                    <br />
                                 </div>

                                 <div className="">
                                    <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                                       {/*<OkButton click server={server} loanding={false} 
                                          fun={() => {
                                             // const op = 
                                             window.open("https://keepsec.ca/vpsmini/preview?serve="+server?.VpsMiniUrl, "Preview", "width=2000,height=2000, fullscreen=yes")
                                             // op.location.href='https://keepsec.ca';
                                          }}
                                        title={"Preview"} 
                                        />*/}
                                       {server?.VpsStatus !== "down" && <OkButton loanding={mkActOb['stop']} click server={server} fun={() => mkAct('stop')} title={"Stop"} />}
                                       {server?.VpsStatus !== "up" && <OkButton loanding={mkActOb['start']} click server={server} fun={() => mkAct('start')} title={"Start"} />}
                                       <OkButton click server={server} loanding={mkActOb['reboot']} fun={() => mkAct('reboot')} title={"Reboot"} />
                                    </div>
                                    <p className="text-right pt-1 text-sm 2xl:text-base">State: <span className="font-bold">{server?.VpsStatus === "up" ? "Running" : server?.VpsStatus === "down" ? "Stopped" : server?.VpsStatus} </span></p>
                                 </div>

                              </div>
                           </div>

                           <div className="grid grid-cols-1 md:grid-cols-2 text-sm 2xl:text-base">
                              <div className="text-left">
                                 <p className="text-left pt-2">
                                    Public hostname:{" "}
                                    <span className="font-bold text-primary">
                                       {server?.domain}
                                    </span>{" "}
                                    <br />
                                    Location:{" "}
                                    <span className="font-bold  text-primary">
                                       {server?.locationObject?.name || "Montreal, Zone A"}
                                    </span>{" "}
                                    <br />
                                 </p>
                              </div>
                              <div className="text-left md:text-right">
                                 <p className="text-left md:text-right pt-2">
                                    <span className="flex gap-2 items-center justify-end">
                                       <Tooltip 
                                          title={`Ports will be only accessible by the domain. Want to use 
                                                other ports? You can use NGROK, Cloudflare Tunnels or other alternatives.`}
                                       >
                                          <AntButton type="default" shape="circle" icon={<QuestionOutlined />} />
                                       </Tooltip>
                                       Open Ports:
                                       <i className="font-bold text-primary">
                                          {" "}80, 443, 8080, 8443, 3000{" "}
                                       </i>{" "}
                                    </span>
                                    Public IP:{" "}
                                    <span className="font-bold text-primary">
                                       {server?.OpenstackInstanceExtIp}
                                    </span>{" "}
                                    <br />
                                    OS:{" "}
                                    <span className="font-bold text-primary">
                                       {server?.OpenstackInstanceImageName}
                                    </span>{" "}
                                    <br />
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={server?.VpsStatus === "down" && "opacity-[5%]"}>
                        <nav className="flex  border-b border-gray-300 dark:border-gray-800 overflow-x-auto">
                           <TabSelector 
                              isActive={selectedTab === "Overview"}
                              onClick={() => setSelectedTab("Overview")}
                           >
                              Overview
                           </TabSelector>

                           <TabSelector
                              isActive={selectedTab === "Console"}
                              onClick={() => setSelectedTab("Console")}
                           >
                              Console
                           </TabSelector>
                           {/* <TabSelector
                              isActive={selectedTab === "Preview"}
                              onClick={() => setSelectedTab("Preview")}
                           >
                              Preview
                           </TabSelector> */}
                           <TabSelector
                              isActive={selectedTab === "Files"}
                              onClick={() => setSelectedTab("Files")}
                           >
                              Files
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Rebuild"}
                              onClick={() => setSelectedTab("Rebuild")}
                           >
                              Rebuild
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Upgrade"}
                              onClick={() => setSelectedTab("Upgrade")}
                           >
                              Upgrade
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Reset Password"}
                              onClick={() => setSelectedTab("Reset Password")}
                           >
                              Reset Password
                           </TabSelector>
                           <TabSelector
                              isActive={selectedTab === "Delete"}
                              onClick={() => setSelectedTab("Delete")}
                           >
                              Delete
                           </TabSelector>
                        </nav>

                        <div className="pt-5">
                           <TabPanel hidden={selectedTab !== "Overview"}>
                              <div className="flex flex-wrap gap-4  justify-between items-stretch ">
                                 <div className="">
                                    <p className="2xl:text-2xl text-xl ">
                                       <br />
                                       Use your own{" "}
                                       <span className="font-bold">FTP </span>
                                       client
                                       <br />
                                       <a
                                          className="2xl:text-base text-sm"
                                          href="#"
                                          target="_blanc"
                                       >
                                          Connect using an FTP client
                                       </a>
                                    </p>
                                    <div className="py-2 px-4 mt-5 border-2 rounded-md border-gray-300 w-full  shadow-lg">
                                       <div className="text-sm 2xl:text-base font-bold flex gap-4 justify-between ">
                                          <div className="text-center">
                                             <span>CONNECT TO</span>
                                             <br />
                                             <span>
                                                sftp://
                                                {server?.OpenstackInstanceExtIp}
                                             </span>
                                          </div>
                                          <div className="text-center">
                                             <span className="font-medium">
                                                Username
                                             </span>
                                             <br />
                                             <span>{server?.SshUsername}</span>
                                          </div>
                                          <div className="text-center">
                                             <span>Port</span>
                                             <br />
                                             <span>{server?.SshPort}</span>
                                          </div>
                                       </div>

                                       <div className="text-base flex items-center justify-between dark:bg-darkSiderbar mt-5 bg-neutral-300 px-2 py-2 rounded-md">
                                          {shareUrl2}
                                          <Tooltip
                                             title={textTtoooltip2}
                                             color={"#011a43"}
                                          >
                                             <IoIosCopy
                                                onClick={onCopy2}
                                                className="2xl:text-xl text-base text-primary cursor-pointer"
                                             />
                                          </Tooltip>
                                       </div>
                                    </div>

                                    <p className="2xl:text-2xl text-xl pt-4">
                                       <br />
                                       Use your own{" "}
                                       <span className="font-bold">SSH </span>
                                       client
                                       <br />
                                       <a
                                          className="2xl:text-base text-sm"
                                          href="#"
                                          target="_blanc"
                                       >
                                          Connect using an SSH client
                                       </a>
                                    </p>
                                    <div className="py-2 2xl:text-base text-sm px-4 mt-5 border-2 rounded-md border-gray-300 w-full  shadow-lg">
                                       <div className=" font-bold flex gap-4 justify-between ">
                                          <div className="text-center">
                                             <span>CONNECT TO</span>
                                             <br />
                                             <span>
                                                {server?.OpenstackInstanceExtIp}
                                             </span>
                                          </div>
                                          <div className="text-center">
                                             <span className="font-medium">
                                                Username
                                             </span>
                                             <br />
                                             <span>{server?.SshUsername}</span>
                                          </div>
                                          <div className="text-center">
                                             <span>Port</span>
                                             <br />
                                             <span>{server?.SshPort}</span>
                                          </div>
                                       </div>

                                       <div className="text-base flex items-center justify-between dark:bg-darkSiderbar mt-5 bg-neutral-300 px-2 py-2 rounded-md">
                                          {shareUrl}
                                          <Tooltip
                                             title={textTtoooltip}
                                             color={"#011a43"}
                                          >
                                             <IoIosCopy
                                                onClick={onCopy}
                                                className="2xl:text-xl text-base text-primary cursor-pointer"
                                             />
                                          </Tooltip>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="relative">

                                    <iframe
                                       className="w-[105%] h-full lg:w-[600px] fileframe1"
                                       
                                       
                                       src={
                                          encodeURI(
                                             "https://filemanager-a1.keepsec.tech/login" +
                                             "?hostname="+ server?.OpenstackInstanceExtIp +
                                             "&username="+ server?.SshUsername+
                                             "&password="+ server?.SshPassword+
                                             "&port="+ server?.SshPort+
                                             "&type=sftp"
                                          )
                                       }
                                       allow="fullscreen;speaker"
                                    >
                                       
                                    </iframe>
                                    {/* <img src={mac} alt="mac keepsec" />
                                    <iframe
                                       id="iframe"
                                       className="absolute shadow-2xl bg-[black] shadow-zinc-900 dark:shadow-xl dark:shadow-slate-700 rounded-t-2xl left-0 top-0 w-[100%] h-[75%]"
                                       src={
                                          Utils.getUrlConsole()[
                                          Math.floor(Math.random() * 3)
                                          ] +
                                          "?access_token=" +
                                          server?.Token
                                       }
                                       height="700"
                                       border="frameno"
                                       style={{
                                          borderRadius: "20px",
                                          border: "20px solid black",
                                       }}
                                    ></iframe> */}
                                 </div>
                                 <div className="">
                                    <div className="2xl:text-2xl text-xl">
                                       Use your own domain
                                    </div>
                                    <div className="py-2 px-4 mt-5 font-bold  border-2 rounded-md border-gray-300 w-full  shadow-lg">
                                       <span className="font-bold 2xl:text-base text-sm">
                                          Please make sure that you have a type "A" DNS record <br /> pointing to{" "}
                                       </span>
                                       <br />
                                       <span className="font-bold 2xl:text-2xl text-xl">
                                          {server?.OpenstackInstanceExtIp}{" "}
                                       </span>
                                       <div className="h-3" />
                                       <span className="font-bold 2xl:text-xl text-base">
                                          Domain list
                                       </span>
                                       <div className="h-1" />
                                       {!customDomainFind ? (
                                          <div>
                                             {domains && domains.length > 0 ? (
                                                <div>
                                                   {domains.map(
                                                      (domain, id) => {
                                                         return (
                                                            <div
                                                               key={id}
                                                               className="text-base flex justify-between dark:bg-darkSiderbar mt-3 bg-neutral-300 px-5 py-2 rounded-md"
                                                            >
                                                               <span>
                                                                  {
                                                                     domain.DomainName
                                                                  }{" "}
                                                               </span>
                                                               <div className="flex gap-2">
                                                                  {id ===
                                                                     domains.length -
                                                                     1 && (
                                                                        <Tooltip
                                                                           title={
                                                                              "Add new domain"
                                                                           }
                                                                           color={
                                                                              "#011a43"
                                                                           }
                                                                        >
                                                                           {!visibleCustomDOmain ? (
                                                                              <BsPlusCircleFill
                                                                                 onClick={() =>
                                                                                    showCustomDomain(
                                                                                       !visibleCustomDOmain
                                                                                    )
                                                                                 }
                                                                                 className="2xl:text-2xl text-xl dark:text-blue-700 text-primary cursor-pointer"
                                                                              />
                                                                           ) : (
                                                                              <FaMinusCircle
                                                                                 onClick={() =>
                                                                                    showCustomDomain(
                                                                                       !visibleCustomDOmain
                                                                                    )
                                                                                 }
                                                                                 className="2xl:text-2xl text-xl dark:text-blue-700 text-primary cursor-pointer"
                                                                              />
                                                                           )}
                                                                        </Tooltip>
                                                                     )}
                                                                  {!deletedRun[
                                                                     domain
                                                                        .DomainName
                                                                  ] ? (
                                                                     <Tooltip
                                                                        title={
                                                                           "Remove this domain"
                                                                        }
                                                                        color={
                                                                           "#011a43"
                                                                        }
                                                                     >
                                                                        <AiFillDelete
                                                                           onClick={() =>
                                                                              onRemoveCustomDOmain(
                                                                                 domain.DomainName
                                                                              )
                                                                           }
                                                                           className="2xl:text-2xl text-xl text-red-700  cursor-pointer"
                                                                        />
                                                                     </Tooltip>
                                                                  ) : (
                                                                     <Spin
                                                                        indicator={
                                                                           antIcon
                                                                        }
                                                                     />
                                                                  )}
                                                               </div>
                                                            </div>
                                                         );
                                                      }
                                                   )}
                                                </div>
                                             ) : (
                                                <div>
                                                   <div>
                                                      <Tooltip
                                                         title={
                                                            "Add your first domain"
                                                         }
                                                         color={"#011a43"}
                                                      >
                                                         {!visibleCustomDOmain ? (
                                                            <BsPlusCircleFill
                                                               onClick={() =>
                                                                  showCustomDomain(
                                                                     !visibleCustomDOmain
                                                                  )
                                                               }
                                                               className="text-xl 2xl:text-2xl dark:text-blue-700 text-primary cursor-pointer"
                                                            />
                                                         ) : (
                                                            <FaMinusCircle
                                                               onClick={() =>
                                                                  showCustomDomain(
                                                                     !visibleCustomDOmain
                                                                  )
                                                               }
                                                               className="text-xl 2xl:text-2xl dark:text-blue-700 text-primary cursor-pointer"
                                                            />
                                                         )}
                                                      </Tooltip>
                                                   </div>
                                                </div>
                                             )}
                                             {visibleCustomDOmain && (
                                                <div className="w-full py-4">
                                                   <CustomDomainAdd
                                                      server={server}
                                                      listCustomDomain={
                                                         listCustomDomain
                                                      }
                                                   />
                                                </div>
                                             )}
                                          </div>
                                       ) : (
                                          <Spin indicator={antIcon} />
                                       )}
                                    </div>
                                    <div className="pt-8">
                                       <div className="flex gap-2 items-center ">
                                          <img
                                             src={blueprint}
                                             alt=""
                                             className="2xl:w-14 2xl:h-14 w-12 h-12"
                                          />
                                          <p className="2xl:text-2xl xl:text-xl">
                                             Micro Blueprint
                                          </p>

                                       </div>
                                       <div className="py-2 px-4 mt-5 font-bold  border-2 rounded-md border-gray-300 w-full  shadow-lg">

                                          <div className="gap-4 grid grid-cols-2 md:grid-cols-3">

                                             <BlueprintCart 
                                                disabled={
                                                   server?.webserver === "Nginx" 
                                                   || 
                                                   server?.blueprints?.includes("nginx") 
                                                   || 
                                                   server?.blueprints?.includes("wordpress") 
                                                } 
                                                name="Apache" 
                                                img={apache} 
                                                id="Apache" 
                                             />
                                             <BlueprintCart 
                                                disabled={
                                                   server?.webserver === "Apache"
                                                   ||
                                                   server?.blueprints?.includes("apache") 
                                                   || 
                                                   server?.blueprints?.includes("wordpress") 
                                                } 
                                                name="Nginx" 
                                                img={nginx} 
                                                id="Nginx" 
                                             />
                                             <BlueprintCart 
                                                disabled={
                                                   server?.webserver === "Nginx"
                                                   ||
                                                   server?.blueprints?.includes("wordpress") 
                                                } 
                                                name="Wordpress" 
                                                img={wordpress} 
                                                id="Wordpress" 
                                             />
                                            
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </TabPanel>

                           <TabPanel hidden={selectedTab !== "Console"}>
                              <iframe
                                 className="shadow-2xl bg-[black] shadow-zinc-900 dark:shadow-xl  dark:shadow-slate-700 w-full "
                                 src={
                                    Utils.getUrlConsole()[
                                    Math.floor(Math.random() * 3)
                                    ] +
                                    "?access_token=" + 
                                    (server?.fernetToken || server?.Token) 
                                 }
                                 height="700"
                                 border="frameno"
                                 style={{
                                    borderRadius: "20px",
                                    border: "20px solid black",
                                 }}
                              />
                           </TabPanel>

                           <TabPanel hidden={selectedTab !== "Upgrade"}> 
                              <div className="px-5">
                                 <UpgradeVps isMini vpsId={id} loadVps={loadVps} server={server} />
                              </div>
                              {/*<button className="
                                          text-xl  
                                          mt-10
                                          w-72 
                                          text-center  
                                          shadow 
                                          bg-primary  
                                          focus:shadow-outline 
                                          focus:outline-none 
                                          text-white 
                                          font-bold 
                                          py-4 
                                          px-4 
                                          rounded
                                     "
                                   type="submit"
                                   onClick={() => window.open("/preview?serve="+server?.VpsMiniUrl,'Preview','width=2500,height=1000')} 
                               >
                                   Preview here
                               </button>*/}
                           </TabPanel> 
                           <TabPanel hidden={selectedTab !== "Files"}>
                              <div className="w-full">
                                

                                    <iframe 
                                       style={
                                          {
                                             height: "500px", width: '100%', border: "9px solid #0000001a", borderRadius: "5px", boxSizing: "border-box"
                                          }
                                       }
                                       id="appframe" 
                                       frameborder="0" 
                                       src={
                                          encodeURI(
                                             "https://filemanager-a1.keepsec.tech/login" +
                                             "?hostname="+ server?.OpenstackInstanceExtIp +
                                             "&username="+ server?.SshUsername+
                                             "&password="+ server?.SshPassword +
                                             "&port="+ server?.SshPort +
                                             "&type=sftp"
                                          )
                                       }
                                       allow="fullscreen;speaker"
                                    >

                                    </iframe>
                              </div>
                           </TabPanel>
                           <TabPanel hidden={selectedTab !== "Rebuild"}>
                              <RebuildMini onRbuild={onRbuild} server={server} loadVps={loadVps} />
                           </TabPanel>
                           <TabPanel hidden={selectedTab !== "Delete"}>
                              <div className="">
                                 <div className="flex gap-2 items-center">
                                    <PageTitle text="Delete this instance" />
                                    
                                 </div>
                                 <p className="2xl:text-base text-sm pt-2">
                                    Deleting this instance will permanently destroy it including all of its data.
                                 </p>
                                 <p className="2xl:text-base text-sm font-bold pt-2">
                                    Are you sure you want to delete this instance?
                                    <br />
                                    <br />
                                    <Delete
                                       id={"Delete" + server?.Id}
                                       server={server}
                                    />
                                    <OkButton
                                       danger
                                       loanding={onRun["delete"]}
                                       click
                                       server={server}
                                       fun={onDelete}
                                       title={"Delete"}
                                    />
                                 </p>
                              </div>
                           </TabPanel>

                           <TabPanel hidden={selectedTab !== "Reset Password"}>
                              <div className="">
                                 <div className="flex gap-2 items-center">
                                    <PageTitle text="Change password of this instance" />
                                    
                                 </div>
                                 
                                 <div className="w-full pb-10 mt-10 lg:w-[400px] 2xl:w-[500px] pt-2 md:pt-0">
                                    <div className="">
                                       <LabelAdd
                                          htmlFor="password"
                                          toLowerCase
                                          placeholder={"New password"}
                                       />
                                       <InputPassAdd
                                          idx="password"
                                          value={update.password}
                                          name="password"
                                          onChange={handleChange}
                                          required
                                          disabled={updatingPassword}
                                          placeholder='Enter new password'
                                       />
                                    </div>
                                    <div>
                                       <LabelAdd
                                          toLowerCase
                                          htmlFor="confirmation"
                                          placeholder={"New password confirmation"}
                                       />
                                       <InputTextAdd
                                          idx="confirmation"
                                          value={update.confirmation}
                                          name="confirmation"
                                          type="password"
                                          onChange={handleChange}
                                          required
                                          disabled={updatingPassword}
                                          placeholder='Enter the new password confirmation'
                                       />
                                    </div>
                                    <div className="pt-5">
                                       
                                          <AntButton 
                                             onClick={onUpdatePassword} 
                                             size="large" 
                                             type="primary" 

                                             className=" w-56 flex items-center font-bold py-4 px-4 text-center justify-center" 
                                             loading={updatingPassword}>
                                             Change password
                                          </AntButton>
                                       {/*</Popconfirm>*/}
                                    </div>
                                 </div>

                              </div>
                           </TabPanel>
                        </div>
                     </div>
                  </div>
               )}

               {!server && (
                  <div className="flex justify-center mt-28">
                     <Spiner fontSize={50} />
                  </div>
               )}
            </div>
         </div>
      </>
   );
}



function CustomDomainAdd({ server, listCustomDomain }) {
   const [domain, setDomain] = useState("");
   const [loanding, onRun] = useState(false);
   const onSave = async () => {
      if (!domain || domain.trim() == "") return;
      onRun(true);
      const d = {
         domain: domain
      }
      const result = await UserMgt.onVpsAction("add_custom_domain", server?.Id, d);
      // const result = await VpsMini.newCustomDomain(
      //    domain,
      //    server_id: server?.Id,
      //    action: "add_custom_domain",
      //    server?.OpenstackInstanceName
      // );
      // console.log("result custom doamin", result);
      if (!result.error) {
         Toast.fire({
            icon: "success",
            title: result.message,
         });
      } else {
         Toast.fire({
            icon: "error",
            title:
               result?.message ||
               "An error occured, if this persist, please tell us",
         });
      }

      onRun(false);
      return listCustomDomain(server);
   };
   return (
      <Space.Compact
         style={{
            width: "100%",
         }}
      >
         <Input
            placeholder="My own domain here"
            onChange={(e) => setDomain(e.target.value)}
            value={domain}
         />
         <AntButton loading={loanding} type="primary" onClick={onSave}>
            Save
         </AntButton>
      </Space.Compact>
   );
}
