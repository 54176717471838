import { message } from 'antd'
import { Button, Drawer, Space } from 'antd';
import { Typography } from 'antd';
import { Table, Tag } from 'antd';
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Spiner } from 'components/Bloc'
import { OkButton } from 'components/OkButton'
import UserMgt from 'lib/user_managment'
import VULLIFY from 'lib/vullify'
import { updateHeadLink } from 'reducer/action/coreActions'

import Utils from '../../utils'

const { Paragraph, Text, Link  } = Typography;

// const categories = [
//     {title: "Low", count: 23.5, color: "#08673f"},
//     {title: "Medium", count: 67, color: "#b4b709"},
//     {title: "High", count: 132.3, color: "#cf7500"},
//     {title: "Critical", count: 56, color: "#9f0032"}
// ]



export default function VullifyReportDetailled(props){
    const { id } = useParams()
    const { targetId } = useParams()
    const { idScan } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
    const [mapReport, setMapReport] = useState(null);
    const [server, setvps] = useState(null)
    const [objectScan, setObjectScan] = useState(null)
    const [estimationP, setEstimation] = useState(0)
    const [json, setScanJSON] = useState({})
    const [categories, setCategories] = useState([])
    const [screenshots, setScreenShot] = useState([])
    const [messageApi] = message.useMessage()
    const [reportDetailled, setChildrenDrawer] = useState(false);
     const showLargeDrawer = (report) => {
        setMapReport(report);
    };
    const onClose = () => {
        setMapReport(null);
    };

    const onNavigate = async (scan) => {
		// navigate("/security/vullify/"+id+"/connect/target/"+targetId+"/"+scan.id)
	}
    const loadVps = async () => {
        // const t1 = await VULLIFY.test2SCreen();
        // console.log('t1 t1 t1', t1)
		const scanDetailled = await VULLIFY.getOutput(id, idScan, targetId) //.user_vps 
        const output = scanDetailled?.dataScan?.output //.replace("/", "")
		if(!scanDetailled?.error && output){
           
                const json = "[" + output.replace(/\r?\n/g, ",").replace(/,\s*$/, "") + "]";
                const jsondata = JSON.parse(json);
                console.log('JSON DATA JSON DATA ', jsondata)
                setObjectScan(scanDetailled)
                setScanJSON(jsondata)
                const categoriesObject = jsondata.filter(j => j.type === "VULNERABILITY")
                // const screenshotLocal = jsondata.filter(j => j.type === "WEBSCREENSHOT")
                const arrayCat = [
                    {title: "Low", type: "VULNERABILITY", count: categoriesObject.filter(x => x.data?.severity === "LOW").length, arrMap: rangeData(categoriesObject.filter(x => x.data?.severity === "LOW")), color: "#08673f"},
                    {title: "Medium", type: "VULNERABILITY", count: categoriesObject.filter(x => x.data?.severity === "MEDIUM").length, arrMap: rangeData(categoriesObject.filter(x => x.data?.severity === "MEDIUM")), color: "#b4b709"},
                    {title: "High", type: "VULNERABILITY", count: categoriesObject.filter(x => x.data?.severity === "HIGH").length, arrMap: rangeData(categoriesObject.filter(x => x.data?.severity === "HIGH")), color: "#cf7500"},
                    {title: "Critical", type: "VULNERABILITY", count: categoriesObject.filter(x => x.data?.severity === "CRITICAL").length, arrMap: rangeData(categoriesObject.filter(x => x.data?.severity === "CRITICAL")), color: "#9f0032"}
                ]
                
                setCategories(arrayCat)
                

                const estimation = (
                    (
                        150//Utils.randomIntFromInterval(100, 200)
                        *
                        arrayCat.find(c => c.title === "Low")?.count
                    )
                    +
                    (
                        1000 //Utils.randomIntFromInterval(500, 1250)
                        *
                        arrayCat.find(c => c.title === "Medium")?.count
                    )
                    +
                    (
                        1600 //Utils.randomIntFromInterval(1250, 2250)
                        *
                        arrayCat.find(c => c.title === "High")?.count
                    )
                    +
                    (
                        2600 //Utils.randomIntFromInterval(2250, 3000)
                        *
                        arrayCat.find(c => c.title === "Critical")?.count
                    )
                )
                setEstimation(estimation)
                return scanDetailled
            
		}else{
			messageApi.open({
				type: 'error',
				content: 'Scan is still ongoing! Please try again later.',
			});
			return navigate(-1)
		}
	}

    useEffect(() => { 
		loadVps().then((o) => {
			const headLinks = [
				{
					link: "/security/vullify",
					item: 'vullify',
					alt: "Vullify short link"
				},
				{label: o?.server?.DisplayName, link: "security/vullify/"+o?.server?.Id+"/connect",  arrow: true},
                {label: "Target", link: "security/vullify/"+o?.server?.Id+"/connect/target", arrow: true},
                {label: o?.target?.name, link: "security/vullify/"+id+"/connect/target/"+targetId, arrow: true},
                {label: o?.scan?.displayName, arrow: true},
			]
			dispatch(updateHeadLink(headLinks))
				
		})
		return () => dispatch(updateHeadLink([]))
	}, [])

    const rangeData = (arrMap) => arrMap.map((line, k) => {
        return{
            key: k,
            host: line?.data?.host,
            url: line?.data?.url || line?.data,
            resolved_hosts: line?.resolved_hosts,
            description: line?.data?.description,
            ...line

        }
    })
    const toogleChildreenDrawer = (report) => {
        setChildrenDrawer(report);
    };
    const col1 = [
        {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
      },
      {
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
        width: 200,
        render: (text) => <div className="w-[200px]">
          {
            typeof text === "string" ?
              <a target="_blank" href={text}>
                  {text}
              </a>   
              :
              <a target="_blank" href={text?.host}>
                  {text?.host}
              </a>
          }
        </div>,
      },
      {
        title: 'Resolved hosts',
        dataIndex: 'resolved_hosts',
        key: 'resolved_hosts',
        render: (_, { resolved_hosts }) => (
          <>
            {resolved_hosts && resolved_hosts.map((host, i) => {
              let color = typeof host ==="string" && host.length > 5 ? 'geekblue' : 'green';
              if (host === 'loser') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={i}>
                  {typeof host ==="string" && host.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        render: (text) => <Text code copyable>
          {text}
        </Text>
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <a onClick={()=>toogleChildreenDrawer(record)}>Details</a>
          </Space>
        ),
      },
    ];
const col2 = [
    {
    title: 'Host',
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: 'Resolved hosts',
    dataIndex: 'resolved_hosts',
    key: 'resolved_hosts',
    render: (_, { resolved_hosts }) => (
      <>
        {resolved_hosts && resolved_hosts.map((host, i) => {
          let color = typeof host ==="string" && host.length > 5 ? 'geekblue' : 'green';
          if (host === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={i}>
              {typeof host ==="string" && host.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        {/*<a>Invite {record.name}</a>*/}
        <a onClick={()=>toogleChildreenDrawer(record)}>Details</a>
      </Space>
    ),
  },
];



    return(
        <>
            <Drawer
                title={mapReport?.title + " - "+ mapReport?.count}
                placement="right"
                // size={"large"}
                width={1200}
                onClose={onClose}
                open={mapReport}
                
              >
                {mapReport &&
                    <div>
                        {mapReport?.type === "VULNERABILITY" || mapReport?.type === "FINDING" ?
                                <Table 
                                    columns={col1} 
                                    dataSource={mapReport?.arrMap} 
                                />
                                
                            :
                            null
                        }          
                        {(mapReport?.type === "OPEN_TCP_PORT" || mapReport?.type === "DNS_NAME" || mapReport?.type === "URL") ?
                            <Table 
                                    columns={col2} 
                                    dataSource={mapReport?.arrMap} 
                                />
                            :
                            null
                        }
                    </div>
                }
                <Drawer
                  title={reportDetailled?.type+( reportDetailled?.data?.severity ? " - "+reportDetailled?.data?.severity : "")}
                  width={520}
                  closable={false}
                  onClose={() => toogleChildreenDrawer(false) }
                  open={reportDetailled}
                >
                    <div style={{display: !reportDetailled && "none"}} className="text-left">
                        <div className="py-2">
                            <span className="font-bold">URL: </span> 
                            {
                              typeof reportDetailled?.url === "string" ?
                                <a target="_blank" href={reportDetailled?.url}>
                                    {reportDetailled?.url}
                                </a>   
                                :
                                <a target="_blank" href={reportDetailled?.url?.host}>
                                    {reportDetailled?.url?.host}
                                </a>
                            }

                            {/* <a target="_blank" href={reportDetailled?.url}>{reportDetailled?.url}</a> */}
                        </div>
                        <div className="py-2">
                            <span className="font-bold">Host: </span> {reportDetailled?.host}
                        </div>
                        <div className="py-2">
                            <span className="font-bold">Resolved hosts:</span> <br/>
                            {reportDetailled?.resolved_hosts?.map((host, i) => {
                              let color = host && host.length > 5 ? 'geekblue' : 'green';
                              
                              return (
                                <Tag color={color} key={i}>
                                  {host && host.toUpperCase()}
                                </Tag>
                              );
                            })}
                        </div>
                        <div className="py-2">
                            <span className="font-bold">Tags:</span><br/>
                            {reportDetailled?.tags?.map((tag, i) => {
                              let color = tag && tag.length > 5 ? 'geekblue' : 'green';
                              if (tag === 'LOW') {
                                color = '#08673f';
                              }if (tag === 'MEDIUM') {
                                color = '#b4b709';
                              }if (tag === 'HIGH') {
                                color = '#cf7500'; 
                              }if (tag === 'CRITICAL') {
                                color = '#9f0032';
                              }
                              return (
                                <Tag color={color} key={i}>
                                  {tag && tag.toUpperCase()}
                                </Tag>
                              );
                            })} 
                        </div>
                        <div className="py-2">
                            <span className="font-bold">Date:</span> <br/>
                            {new Date(reportDetailled?.timestamp * 1000).toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}
                            {" "}
                            {new Date(reportDetailled?.timestamp * 1000).toLocaleTimeString()}
                            
                        </div>
                        <div style={{display: !reportDetailled?.description && "none"}} className="py-2">
                            <span className="font-bold">Description:</span> <br/>
                            <Text code copyable>{reportDetailled?.description}</Text>
                        </div>
                    </div>
                </Drawer>
            </Drawer>
			<div className="  dark:text-darkTextColor  ">
				<div className="container mx-auto max-w-full px-3 md:px-8 h-full ">
					{objectScan ?
                        <div className='flex justify-between flex-wrap pt-5 pb-20'>
                            <div className='w-full md:w-[77%] bg-neutral-100 rounded-xl p-5'>
                                <span className='font-bold text-base xl:text-xl'>Vulnerabilities </span>
                                <div className='flex justify-between gap-3 flex-wrap pt-5'>
                                    {categories.map((report, i) =>{
                                        return(
                                            <ReportCard showDefaultDrawer={() => showLargeDrawer(report)} report={report}  key={i}/>
                                        )
                                    })}
                                </div>
                                <div className='flex w-full   justify-between gap-4 md:gap-3 flex-wrap mt-10'>
                                    <div className='w-full md:w-[77%] '>
                                        <div  className='flex w-full md:w-[500px] h-[6px]'>
                                            {categories.map((c1, i) =>(
                                                <div
                                                    style={{
                                                        width: String(((c1.count * 100 / categories.reduce((partialSum, a) => partialSum + a.count, 0))))+'%',
                                                        backgroundColor: c1.color
                                                    }}
                                                    className='h-full'
                                                    key={i}
                                                >
                                                </div>
                                            ))}
                                            
                                        </div> 
                                        <div className='mt-5'>
                                            {categories.map((c1, i) =>(
                                                <div
                                                    className='flex gap-3 items-center'
                                                    key={i}
                                                >
                                                    <div style={{backgroundColor: c1.color}} className='rounded-full h-4 w-4' />
                                                    <span className='text-primary text-sm xl:text-base font-bold '>{c1.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className=''>
                                        <ReportCard
                                            showDefaultDrawer={showLargeDrawer} 
                                            report={{title: "Informative", arrMap: rangeData(json.filter(j => j.type === "FINDING")), type: "FINDING", count: json.filter(j => j.type === "FINDING").length, color: "#062077"}}  
                                        />
                                    </div>
                                </div>
                                {/* <div className='py-5'>
                                    {screenshots.length && screenshots.map((screen, id) =>{
                                        return(
                                            <img  
                                                key={id}
                                                src={ VULLIFY.getScreen(objectScan.server?.OpenstackInstanceExtIp, objectScan.scan?.name, screen.data?.filename)} 
                                                alt={screen?.data?.filename} 
                                            />
                                        )
                                    })}
                                </div> */}
                                <div className='mt-3'>
                                    <p className='text-primary text-sm xl:text-base'>
                                        Total market value of vulnerabilities detected by Vullify: 
                                        <span className='font-extrabold'>{" "}
                                            {new Intl.NumberFormat('en-IN', {style: "currency", currency: "USD" }).format(estimationP)}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className='w-full md:w-[20%] bg-neutral-100 rounded-xl  pt-5 px-1'>
                                <p className='font-bold text-base xl:text-xl text-center md:text-primary '>Asset informations</p>
                                <div className='flex flex-wrap md:flex-col justify-between md:justify-center'>
                                    <div className='pt-5 md:mx-auto '>
                                        <ReportCard showDefaultDrawer={showLargeDrawer} small report={{title: "DNS asset", arrMap: rangeData(json.filter(j => j.type === "DNS_NAME")),  type: "DNS_NAME", count: json.filter(j => j.type === "DNS_NAME").length}}  />
                                    </div>
                                    <div className='pt-3 md:mx-auto'>
                                        <ReportCard showDefaultDrawer={showLargeDrawer} small report={{title: "HTTP server", arrMap: rangeData(json.filter(j => j.type === "URL")), type: "URL", count: json.filter(j => j.type === "URL").length}}  />
                                    </div>
                                    <div className='pt-3 md:mx-auto'>
                                        <ReportCard 
                                            showDefaultDrawer={showLargeDrawer} 
                                            small 
                                            report={{title: "Open port", arrMap: rangeData(json.filter(j => j.type === "OPEN_TCP_PORT")), type: "OPEN_TCP_PORT", count: json.filter(j => j.type === "OPEN_TCP_PORT").length}}  />
                                    </div>
                                </div>
                            </div>
							{/* <Target  navigate={onNavigate}  scan={{id: 2}}/> */}
                        </div>
						:
						<div className='flex my-auto h-full justify-center items-center'>
						    <Spiner fontSize={50} />
						</div>
                    }

                    
                </div>
            </div>
        </>
    )
}


function ReportCard({report, small, showDefaultDrawer}){
	return(
		<div 
            onClick={() => typeof showDefaultDrawer ==="function" && report.count !== 0 && showDefaultDrawer(report)} 
            // style={{width: small ? "125px" : "140px", height: small ? "100px" : "130px"}} 
            className={`${small ? " w-[125px] h-[100px] 2xl:w-[150px] 2xl:h-[130px]" : " w-[150px] h-[140px] 2xl:w-[210px] 2xl:h-[195px]"} cursor-pointer rounded-xl border-black border-[2px]`}
        >
            <div style={{borderColor: report.color}} className={"bg-primary h-full w-full  border-4 rounded-xl  flex items-center  text-white   p-1"}>
                <div className={(small ? "pl-2":"pl-4")}>
                    <p className={(small ? "text-sm":"text-base")+' font-semibold  uppercase'}>{report.title}</p> 
                    <p className='font-semibold text-base xl:text-xl'>{report.count  >  999 ? Utils.abbrNum(1030, 3)  : report.count}</p> 
                </div>
            </div>
        </div>
	)
}
