import { DISPLAY_ALL_SIDEBAR_ITEMS, FORCE_RELOAD, SAVE_DATA_LOCATION, SAVE_DELETABLE_SERVICE, SAVE_PAYMENT_METHOD, SET_HEADER_LINK, SET_LOCATION, SET_NETWORKS, SET_NOTIFICATION_HELPER } from "reducer/action/coreActions";

const defaultState = {
  headLinks: null,
  messageApi: null,
  locations: [],
  networks: null,
  forceReload: null,
  dontDisplayAllItems: false,
  paymentMethods: null,
  deletingService: null,
  returnLink: null,
  geoLocation: null
}; 
  

export default function coreApp(state = defaultState, action) {
  
  switch (action.type) {
    case SET_HEADER_LINK:
      return { ...state, headLinks: [...action.headLink] };
    case SET_NOTIFICATION_HELPER:
      return { ...state, messageApi: action?.api };
    case SET_LOCATION:
        return { ...state, locations: action?.locations };
    case SET_NETWORKS:
        return { ...state, networks: {...state?.networks, ...action?.networks} };
    case FORCE_RELOAD:
        return { ...state, forceReload: new Date().getTime() };
    case DISPLAY_ALL_SIDEBAR_ITEMS:
        return { ...state, dontDisplayAllItems: action?.shouldDisplay };
    case SAVE_PAYMENT_METHOD:
        return { ...state, paymentMethods: action?.paymentMethods };
    case SAVE_DELETABLE_SERVICE:
        return { ...state, deletingService: action?.service, returnLink: action.returnLink };
    case SAVE_DATA_LOCATION:
        return { ...state, geoLocation: action?.data };
  
    default:
      return {...state};
  }
}
  