

export default function BlueprintCart({ img, name, version, id, disabled }) {
    function confirmBlueprint(item) {
		console.log('click')
		return window.$("#" + item).modal('show')
	}
    
    return (
        <div 
            className={
                `
                    rounded-md 
                    bg-neutral-100 
                    dark:bg-bgPagedark 
                    px-5 
                    pb-2 
                    pt-4
                    ${disabled && " grayscale "}  
                    text-center 
                    cursor-pointer
                ` 
            }
            onClick={() => !disabled ? confirmBlueprint(id) : null}
        >
            <img src={img} alt="" className='h-16 mx-auto' />
            <p className="text-center">
                <span className="font-semibold"> {name} </span> <br />
                <span className="text-xs"> {version} </span>
            </p>
        </div>
    )
}