const { useState } = require('react')
const { Tooltip } = require("antd");
const { LabelAdd, InputTextAdd, InputPassAdd } = require("../Bloc");


export function AddCloudServiceForm({creating, vps_params, handleChange}){
    const [hoverServerName, setHoverToolTopServerName] = useState(false)

    return(
        <div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                    <LabelAdd
                        htmlFor="serv-name"
                        placeholder={"Identify your instance"}
                    />
                    <Tooltip open={hoverServerName}   title="Must not contain spaces, special characters or symbols">
                        <InputTextAdd
                            idx="serv-name"
                            value={vps_params.server_name}
                            name="server_name"
                            pattern="[A-Za-z0-9]{1,}"
                            onChange={handleChange}
                            required
                            hover
                            onHover={(state) => setHoverToolTopServerName(state)}
                            disabled={creating}
                            placeholder='Your instance resources must have unique names'
                        />
                    </Tooltip>
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full xl:w-1/2 px-3">
                    <LabelAdd
                        htmlFor="serv-username"
                        placeholder={"Server user"}
                    />
                    <InputTextAdd
                        idx='serv-username'
                        value={vps_params.username}
                        name="username"
                        onChange={handleChange}
                        required
                        disabled={creating}
                        pattern="(?!root$)(?!admin$)(?!keepsec$)(?!ubuntu$)(?!almalinux$)([A-Za-z0-9]{3,})$"
                        placeholder='Username used to login via SSH'
                    />
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">

                <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                    <LabelAdd
                        htmlFor="serv-pass"
                        placeholder={"Server password"}
                    />
                    <InputPassAdd
                            
                        idx="serv-pass"
                        value={vps_params.password}
                        name="password"
                        onChange={handleChange}
                        required
                        disabled={creating}
                        placeholder='Password used to login via SSH'
                    />
                </div>
                <div className="w-full xl:w-1/2 px-3">
                    <LabelAdd
                        htmlFor="serv-pass-confirm"
                        placeholder={"Password confirmation"}
                    />
                    <InputTextAdd
                        idx='serv-pass-confirm'
                        type="password"
                        value={vps_params.passwordCon}
                        name="passwordCon"
                        onChange={handleChange}
                        required
                        disabled={creating}
                        placeholder='Password confirmation'
                    />
                </div>
            </div>                                      
        </div>                                                                                     
    )
}
