import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { StarFilled } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import down from '../../assets/img/arrow-down.png'
import up from '../../assets/img/arrow-up.png'
import canada from '../../assets/img/connect/canada.png'
import UTILITY from '../../lib/utility_service'
import{ setLocation } from "../../reducer/action/coreActions"
import Utils from '../../utils';
import { AddServiceSectionTitle } from '../Bloc'

export function ServerLocation({vps_params, setVpsParams, label, location2, desc, isVpsMini}){

	const [click, setClick] = useState(false)
	const { dontDisplayAllItems } = useSelector(app => app.core)
	const locations = useSelector(app => app.core?.locations?.filter(l => {
		if(!!l.onlyVisibleByAdmin && Utils.isAdmin() && dontDisplayAllItems){
			return false
		}else{
			return true
		}
	}).filter(l => {
		if(isVpsMini){
			return !!l.isVpsMini
		}else{
			return !l.isVpsMini
		}
	}))

	const dispatch = useDispatch()

	React.useEffect(() => {
        (async()=>{ 
    		if(!locations?.length){
	           const locations = await UTILITY.getDatacenterLocation(); 
	           if(!locations?.error){
	           	dispatch(setLocation(locations.datacenter?.filter(l => {
	           			if(!!l.available){
	           				if(!!l.onlyVisibleByAdmin){
	           					if(Utils.isAdmin()){
	           						return true
	           					}else{
	           						return false
	           					}
	           				}else{
	           					return true
	           				}
	           			} 
	           			return false
	           		}
	           	)))
	           }
        	}
        })()
   }, [])

	return(
		<div className="mx-auto ">
			<AddServiceSectionTitle
                title={label || "Choose your service location"}
                desc={desc}
            />
            <div className="flex justify-center py-6">

		        <div  className='w-48 xl:w-52 2xl:w-64 relative dark:bg-bgPagedark  mt-5  cursor-pointer rounded-xl bg-neutral-100 dark:hover:shadow-slate-700'>
		        	<div className={'flex px-5 py-3 items-center justify-between'}
		                onClick={() => {
		                	setClick(!click)
		                }} 
		            >
		                <img src={canada} alt="" className='2xl:w-10 2xl:h-10 w-6 h-6' />
		                
		                <p className='text-primary dark:text-darkTextColor font-semibold text-base 2xl:text-xl'>
		                	{"Canada"}
		                </p>
		                
		                <img src={click ? down : up} alt="arrow" className='w-6' />
		            </div>

		            <div style={{display: !click && "none"}} className='absolute z-[1]'> 
		                {locations
		                	.map((location, i) =>{
		                    return(
		                        <div onClick={() => {
		                            // if (!props.creating) {
		                                if(vps_params.location === location?.id ){
		                                		if(location2){
		                                   		setVpsParams({ ...vps_params, location2: null, location2O: null})
		                                		}else{
		                                   		setVpsParams({ ...vps_params, location: null, locationO: null})
		                                		}
		                                }else{
		                                		if(location2){
		                                   		setVpsParams({ ...vps_params, location2: location?.id, location2O: location})
		                                		}else{
		                                   		setVpsParams({ ...vps_params, location: location?.id, locationO: location})
		                                		}
		                                }
		                                setClick(!click)
		                            // }
		                        }}  
		                        key={i}
		                        className={`
			                        	w-48 xl:w-52 2xl:w-64   
			                        	hover:shadow-2xl bg-white 
			                        	shadow-xl dark:bg-bgPagedark  
			                        	cursor-pointer hover:bg-gray-200 
			                        	${i===0 ? " rounded-t-xl " : ""}
			                        	${locations?.length === i+1 ? " rounded-b-xl " : ""}
		                        	`
		                        	}
		                        >

		                            {(vps_params.location === location.id) &&
		                                <svg className="div_check2 checkmark absolute right-0 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 80">
		                                    <path className="" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
		                                </svg>
		                            } 

		                            <div className='flex px-5 py-3 items-center '>
		                                <img src={canada} alt="" className='2xl:w-7 2xl:h-7 w-5 h-5' />
		                                &nbsp;&nbsp;
		                                <p className='text-primary flex dark:text-darkTextColor font-semibold text-sm  2xl:text-base'>
		                                	{location?.name}
		                                	{!!location.onlyVisibleByAdmin ?
		                                		<StarFilled />: null
		                                	}
		                                </p>
		                            </div>
		                        </div> 
		                    )
		                }) }                    
		            </div> 

		        </div>
		    </div>
            {/*<div className="flex justify-center py-6">
				<Dropdown
				    menu={{
				      items,
				    }}
				    className='mx-auto'
				>
				    <Button  className="bg-gray-100 flex gap-1 py-1 items-center">
				    
				        <img src={canada} alt="" className='w-8 h-8' />
				        <span className="font-bold text-base xl:text-xl">Canada</span>
				        <DownOutlined className="text-base xl:text-xl"/>
				     
				    </Button >
				</Dropdown>
            </div>*/}
		</div>
	)
}