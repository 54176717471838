import { Tooltip } from "antd"
import React from 'react'
import { useNavigate } from 'react-router-dom';

import { KSLB_MANAGE_LINK } from 'constants/link';
import { CardServiceLayout, MiniCardServiceLayout, ServiceOptions } from 'layouts/ServiceLayout'
import kslb from '../../assets/img/networking/icon.png'
import { OkButton } from '../OkButton';
import { ArrowRightOutlined } from '@ant-design/icons';
// import { Spiner } from "components/Bloc";

export function NetworkServerCard({server}) {
    const is = (s) => server?.VpsStatus === s

    const navigate = useNavigate()

    function onManage(){
        return navigate(KSLB_MANAGE_LINK(server?.Id))
    }


    return (
        <CardServiceLayout server={server}>
            <div>
                <div className='border-b-2 dark:border-gray-800 pb-4 2xl:pb-8 pt-3'>
                    <div className='flex justify-between items-start'>
                        <div className='flex 2xl:gap-4 gap-2'>
                            <img src={kslb} alt="" className='w-14 h-14' />
                            <div>
                                <span className='2xl:text-xl text-base font-semibold text-primary dark:text-darkTextColor'>{server?.DisplayName || server?.OpenstackInstanceName}</span> <br/>
                                <span className="2xl:text-base text-xs">{'KS lb'}</span>
                            </div> 
                        </div>
                        <div className='flex justify-end '>
                            <OkButton click fun={onManage} title="Manage"   server={server} loanding={false}/>
                            &nbsp;&nbsp;&nbsp;
                            <ServiceOptions
                                options={[
                                    {label: "Manage",  cunstomFun: ()=> onManage(), display: !is('up') && "none"},
                                    {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
                                ]}
                                server={server}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-between pt-2 2xl:pt-4 '>
                    <div className='text-sm 2xl:text-base'>
                        <span className="font-bold text-primary">
                            {
                                is("up")? 
                                "Running" : 
                                server?.VpsStatus
                            }
                        </span>
                    </div> 
                    <div className=''>
                        <p className='text-right'>
                            <span className="text-sm 2xl:text-base">{server?.OpenstackInstanceExtIp}</span> <br/>
                            <span
                                className="text-sm 2xl:text-base">{server?.location?.name || "Montreal, Zone A"}</span>
                        </p>
                    </div>
                </div>
            </div>
        </CardServiceLayout>
    )

}

export function NetworkServerMiniCard({server}){
    const is = (s) => server?.VpsStatus === s

    const navigate = useNavigate()

    function onManage(){
        return navigate(KSLB_MANAGE_LINK(server?.Id))
    }
    return(
        <MiniCardServiceLayout server={server}>
            <div className='flex justify-between items-start'>
                <div className='flex 2xl:gap-4 gap-2'>
                    <img src={kslb} alt="" className='w-14 h-14' />
                    <div>
                        <span className='2xl:text-base text-sm font-bold text-primary dark:text-darkTextColor'>{server?.DisplayName || server?.OpenstackInstanceName}</span> <br/>
                        <span className="2xl:text-sm font-semibold text-xs">{server?.location?.name || "Montreal, Zone A"}</span>
                    </div> 
                </div>
                <div className='flex flex-row-reverse gap-[1px]  justify-end justify-end'>
                    <ServiceOptions
                        options={[
                            {label: "Manage",  cunstomFun: ()=> onManage(), display: !is('up') && "none"},
                            {label: "Delete", case: "onDelete", display: is('Deploying') && "none"},
                        ]}
                        server={server}
                    />

                    <span  
                        className="bg-primary cursor-pointer py-[3px] px-[6px] rounded flex items-center justify-center"
                        onClick={() => onManage()}
                    >
                        <ArrowRightOutlined className=" text-sm text-[#fff]"/>
                        {/* <LinkOutlined className=" text-sm text-[#fff]" /> */}
                    </span>
                    
                </div>
            </div>
        </MiniCardServiceLayout>
    )
}