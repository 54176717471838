import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { MdMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { CONSOLE_LINK } from 'constants/link';

import { Reboot, Start, Stop } from "../components/Modals";
import { save_deletable_service } from 'reducer/action/coreActions';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useDispatch } from 'react-redux';

export function ServiceOptions({server, options}){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const work = (arg) => {
        switch (arg) {
            case "onStop":
                window.$("#Stop"+server?.Id).modal('show')
                break;
            case "onReboot":
                window.$("#Reboot"+server?.Id).modal('show')
                break;
            case "onStart":
                window.$("#Start"+server?.Id).modal('show')
                break;
            case "onDelete":
                //Dispatch delete boolean
                dispatch(save_deletable_service(server))
                break;
            case "onConsole":
                navigate(CONSOLE_LINK(server?.Id))
                break;
            default:
                break;
        }
    }
    return(
        <Menu  
            menuClassName="text-sm dark:text-darkTextColor dark:bg-black" 
            menuButton={()=><MenuButton>
                    <MdMoreVert className='text-primary  dark:text-darkTextColor text-2xl cursor-pointer'/>
                </MenuButton>
            } 
            transition
        >
            {
                options && options.map((opt, j) => (
                    <MenuItem
                        style={{display: opt.display}} 
                        className={`font-semibold dark:hover:bg-darkSiderbar ${opt.case==="onDelete"&&"text-red-700"}`} 
                        onClick={()=>{
                            if(typeof opt.cunstomFun === "function") return opt.cunstomFun()
                            if(typeof opt.link === "string") return navigate(opt.link)
                            else return work(opt.case)
                        }}
                    >   
                        {
                            opt?.href ?
                            <a href={"http://"+opt.href} target='_blank' >{opt.label}</a>
                            :
                            opt.label
                        }
                    </MenuItem>
                ))
            }
        </Menu>
    )
}

export function CardServiceLayout({className, children, server, grayScale}){

    return(
        <div
            className={
                `
                    2xl:py-6 
                    py-3
                    ${(server?.VpsStatus === "Deploying" || grayScale ) && " grayscale "}  
                    server 
                    bg-grey 
                    block  
                    rounded-lg 
                    2xl:px-6 
                    px-4
                    shadow-lg 
                    dark:bg-bgPagedark 
                    dark:text-darkTextColor
                    //2xl:max-w-[450px]
                    //max-w-[350px]
                    ${className}
                `
            } 
        >
            <Reboot id={"Reboot"+server?.Id} server={server} />
            <Stop  id={"Stop"+server?.Id} server={server} />
            <Start  id={"Start"+server?.Id} server={server} />
            <div>
                {children}
            </div>
        </div>
    )
}

export function MiniCardServiceLayout({className, children, server, grayScale}){
    return(
        <div 
            className={
            `
                ${(server?.VpsStatus === "Deploying" || grayScale) && " grayscale "}  
                server 
                bg-grey 
                block  
                p-2
                2xl:w-[270px]
                w-[220px]
                rounded-lg  
                shadow-lg 
                dark:bg-bgPagedark 
                dark:text-darkTextColor
                ${className}
            `}
        >
            <Reboot id={"Reboot"+server?.Id} server={server} />
            <Stop  id={"Stop"+server?.Id} server={server} />
            <Start  id={"Start"+server?.Id} server={server} />
            <div>
                {children}
            </div>
        </div>
    )
}