import { useEffect, useState } from 'react'
import { Spiner } from '../../../components/Bloc'
import VPC from '../../../lib/vpc';
import Utils from "../../../utils"
import { Button } from 'antd';
import {  useNavigate } from 'react-router-dom';
import { FaLeanpub } from "react-icons/fa";
import { Typography } from 'antd';
import { DownOutlined, DeleteFilled, SettingFilled } from '@ant-design/icons';
import { Dropdown, Badge, Modal } from "antd";
import {  useSelector } from 'react-redux';


const { Paragraph, Text  } = Typography;

export default function ListVpc(){
	const navigate = useNavigate()
	const [loadingData, setLoading] = useState(false)
	const vpc = useSelector(app => app?.network?.vpc?.list)
	
	async function listVPC(){
		!vpc && setLoading(true)
        const data = await VPC.getAll()
		setLoading(false)
    }


	useEffect(()=>{ 
        listVPC() 
    }, [])

	return(
		<div className="px-3 md:px-8 pb-10">

			{!loadingData ?
				<div>
					<div>
						<div className=" md:mt-2  " >
							<div className="flex w-full justify-between items-start text-primary">
								<span className="font-semibold text-xl xl:text-2xl 2xl:text-3xl">
									VPC Networks
								</span>
								<Button 
									className="px-4  font-semibold h-10 2xl:h-12 "
									type="primary"
									onClick={
										()=>navigate('/networking/vpc/deploy')
									}
								>
									Create VPC Network
								</Button>
								
							</div>
							<div className="mt-1 md:flex w-full justify-between items-start  text-primary">
								<p className="w-full 2xl:py-3 py-1 md:w-3/4 text-sm 2xl:text-base">
									Ressource asssigned to the same VPC Network can communicate securily
									with each other via private IP. communicate with the ressource outtside
									the VPC must use network IP.
									<br/>
									<br/>
									<a className="" href="javascript:;" target="_blank" >
										<u>How should I use VPC Network</u>
									</a>
								</p>
								
							</div>
						</div>
							<div className="mt-5">
								{vpc && vpc?.length ?
									<div>
										{vpc?.map((oneVpc, id) =>{
											return(
												<div key={id}>
													<div className="flex items-center gap-2 xl:gap-3 2xl:gap-4">
														{oneVpc?.[0] ?
															<img 
																src={require('../../../assets/img/flag/'+oneVpc?.[0]+".webp")}
																className="2xl:h-8 h-5 rounded xl:h-6"  
															/>
															:
															null  
														}
														<span className="font-bold text-base xl:text-xl 2xl:text-2xl">
															{oneVpc?.[0] === "ca" ? "Canada" : "Cameroon"}
														</span>
													</div>
													<div className="py-5">
														{oneVpc?.[1]?.sort((a, b) => b[0].localeCompare(a[0])).map((subVpc, l) => {
															return(
																<div className="2xl:pb-2 pb-1" key={l}>
																	<span className=" font-semibold text-sm xl:text-base 2xl:text-xl ">
																		{subVpc[0]}
																	</span>
																	{subVpc?.[1]?.map((net, c) => {
																		return(
																			<div className="" key={c}>
																				<NetworkCard 
																					vpc={net} 
																					navigate={navigate} 
																				/>
																			</div>
																		)
																	})}
																</div>
															)
														})}
													</div>
												</div>
											)
										})}
									</div>
								     :
									<div>
										<div className="border border-primary border-1 py-8 flex flex-col justify-center">
											<img src={require('../../../assets/img/networking/left.png')} className="w-28 mx-auto" />
											<p className="text-center py-5">
												<span className="font-semibold text-base 2xl:text-xl">
													Look like you don't have any network
												</span>		
											</p>
											<Button 
												className="mx-auto px-4 font-semibold h-10 2xl:h-12 " 
												style={{borderRadius: "0px"}} 
												type="primary"
												onClick={
													()=>navigate('/networking/vpc/deploy')
												}
											>
												Create a first VPC Network
											</Button>
										</div>
									</div>
								}

							</div>
					</div>
				</div>
				:
				<div className='flex justify-center'>
	                <Spiner fontSize={40} />
	            </div>
	        }
		</div>
	)
}


const NetworkCard = ({vpc, navigate}) => {
	const [delConfirm, setDelConfirm] = useState(false)
	const handleButtonClick = (e) => {
	  return navigate("/networking/vpc/"+vpc?.id+"/manage")
	};
	async function onDelete(){
        return  Utils.dispatchToDelete(vpc)
	}

	const handleMenuClick = (e) => {
		if(e.key === "1") return navigate("/networking/vpc/"+vpc?.id+"/manage?act=Ressources")
		if(e.key === "2") return onDelete()
	};

	const items = [
	  {
	    label: 'Ressources',
	    key: '1',
	    icon: <SettingFilled />,
	  },
	  {
	    label: 'Delete',
	    key: '2',
	    icon: <DeleteFilled />,
	    danger: true,
	    disabled: !!vpc?.isDefault,
	  }
	];

	const menuProps = {
	  items,
	  onClick: handleMenuClick,
	};

	return(
		<div className="mb-3 mt-1 rounded-xl border border-1 border-gray-200 bg-neutral-50 p-3">
			<div className="flex flex-wrap items-center ">	
				<div className ="flex gap-3 items-center  w-[40%]">
					<img 
						src={require('../../../assets/img/networking/left.png')} 
						className="rounded-full 2xl:h-10 xl:h-8 h-6" 
					/>
					<span className="font-semibold text-xs  xl:text-sm 2xl:text-xl">
						{vpc?.network?.name} {" "}
						{vpc?.isDefault ? 
							<Badge  
								count={" DEFAULT "}
							/> 
							: 
							null
						}
					</span>	 	
				</div>	
				<div className="w-[30%]">
					<p className="text-xs  xl:text-sm 2xl:text-base text-center">
						<span className="text-primary flex justify-start items-center font-bold">
							<Text  copyable>
								{vpc?.network?.subnet}
						    </Text >
						</span>
					</p>	
				</div>	
				<div className="w-[30%] flex justify-end gap-4 xl:gap-6 items-center ">
					<div className="">
						{vpc?.ressources?.length ?
							<div className="flex gap-1">
								<span className="font-bold">{vpc?.ressources?.length}</span>
								<img src={require('../../../assets/img/networking/server.png')} />	
							</div>	
							:
							<span className="text-xs 2xl:text-sm">No Ressources</span>
						}	
					</div>	
					<div className="">
						<Dropdown.Button menu={menuProps} onClick={handleButtonClick}>
					      Manage
					    </Dropdown.Button>	
					</div>	
				</div>	
			</div>	
		</div>
	)
}
