import { useEffect } from 'react'
import { BsFillLightbulbFill } from "react-icons/bs";

import Utils from '../../utils';

export default function AddBotom(props){
    
   
    const myObserver = new ResizeObserver(entry => {
        if(props.setBottomCalculedHeight){
            props.setBottomCalculedHeight(window.$('#BotomAdd').height())
        }
    });
    useEffect(() =>{
        const someEl = document.getElementById('BotomAdd');
        myObserver.observe(someEl);
        // return myObserver.disconnect();
    }, [])
    function calculVat(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return (Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (14.975 / 100)).toFixed(2)
    }

    function finalPrice(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return ( Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (1 + 14.975 / 100)).toFixed(2)
    }

    const renderCurency = (veryMin) => (
        <span className={`${!veryMin ? "text-xs": "text-[11px]"}  font-bold`}>
            {Utils.renderCurencyCode()}
        </span>
    )
    return(
        <div className='' >
            <div id="BotomAdd" className={' fixed py-3 bottom-0 z-[5] dark:opacity-70 left-0 md:left-60 2xl:left-72 right-3 dark:text-darkTextColor dark:bg-bgPagedark   shawdow  '}>
                
                <div className='flex flex-wrap gap-4 items-end justify-center'>
                    
                    <div className='w-60 xl:w-64 2xl:w-72  text-left text-sm 2xl:text-base text-primary dark:text-darkTextColor'>
                        <span className='text-base 2xl:text-xl font-bold'>Summary:</span> 
                        {!props?.isKube ?
                            <>
                                <>
                                    <br/>
                                    Location:{" "}
                                    <span className='font-semibold'>
                                        <>{props.vps_params?.locationO?.name || "(Not defined)" }</>
                                    </span>
                                </>
                                {!props?.isKps && !props?.isSecurity && !props?.isNetwork ?
                                    <>
                                        <br/>
                                        Image:{" "}
                                        <span className='font-semibold'>
                                             <>{props.vps_params?.image || "(Not defined)" }</>
                                        </span>
                                    </>
                                    :
                                    null
                                } 
                                <br/>
                                Plan:{" "}
                                <span className='font-semibold'>
                                    <>{
                                        props.packSelected?
                                            <>
                                                {props.packSelected?.Name}
                                                &nbsp;-&nbsp;
                                                {renderCurency(true)}
                                                {Utils.priceConvert(props.packSelected?.Price).toFixed(2)}
                                                /month
                                            </>
                                            : 
                                            "(Not defined)" 
                                        }
                                    </>
                                </span> 
                            </>
                            :
                            null
                        }
                        <br/>
                        TPS + TVQ:{" "}
                        <span className='font-semibold'>
                            {calculVat() ? 
                                <>
                                    {renderCurency(true)}
                                    {calculVat()}
                                </> 
                                : 
                                "(Not defined)"
                            }
                        </span> 
                        <br/>
                        <>
                            <span className='text-sm 2xl:text-base font-bold'>Total amount: &nbsp;</span>
                            {finalPrice () ?
                                <>
                                    {renderCurency()}
                                    <span className='text-base xl:text-xl 2xl:text-2xl font-bold'>
                                        {finalPrice()}
                                    </span>
                                </>
                                :
                                <span className='font-semibold'>
                                    (Not defined)
                                </span>
                            }
                            <span className="font-semibold text-xs xl:text-sm">
                                { finalPrice () ? "/month" : ""}
                            </span>
                        </>
                    </div>
                    <div className=''>
                        <button className="
                            flex 
                            mx-auto 
                            2xl:text-xl 
                            xl:text-base 
                            text-sm  
                            w-60 
                            xl:w-64  
                            2xl:w-72 
                            text-center
                            items-center
                            shadow
                            bg-primary
                            focus:shadow-outline 
                            focus:outline-none 
                            text-white 
                            font-bold 
                            2xl:py-4 2x:px-4 
                            xl:py-3 xl:px-3
                            py-2 px-2
                            rounded
                            "
                            type="submit"
                            disabled={props.creating}
                        >
                            {!props.creating ?
                                <span className='mx-auto flex  items-center'>
                                    <BsFillLightbulbFill className='text-2xl' />
                                    { props.soon ? " Coming soon " : "Deploy Now"}
                                </span>
                                :
                                <span className='mx-auto flex gap-1 items-center'>
                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {!props.paypal ? " Deploying" :  " Waiting for paypal"}
                                </span>

                            }
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
