export const LIST_VPS = "@@VPS/LIST_VPS"
export const LIST_FLAVORS = "@@VPS/LIST_FLAVORS"
export const LIST_IMAGES = "@@VPS/LIST_IMAGES"
export const VPS_PACKAGES = "@@VPS/VPS_PACKAGES"

export function list_vps(data){
    return {
        type: LIST_VPS,
        list : data, 
      }
}
export function list_flavors(data){
    return {
        type: LIST_FLAVORS,
        list : data, 
      }
}
export function list_images(data){
    return {
        type: LIST_IMAGES,
        list : data, 
      }
}
export function vps_packages(data){
    return {
        type: VPS_PACKAGES,
        data : data, 
      }
}