import {  useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import VPSapi from '../../../lib/vps_service';
import { Toast } from '../../../components/Notify';
import { Block, InputTextAdd, LabelAdd } from '../../../components/Bloc';
import UserMgt from '../../../lib/user_managment';
import VPC from '../../../lib/vpc';
import { useNavigate } from "react-router-dom";
import Utils from '../../../utils';
import { KsLbPack } from '../../../components/vps/Packages';
import { Checkbox, Tooltip, Radio, Badge } from 'antd';
import {ServerLocation} from 'components/vps/ServerLocation';
import  DeployServerWrapper from '../../DeployServerWrapper';

function border(val, inspect){
	return val === inspect ? "border-primary bg-[#011a430d] " : "border-gray-200"
}
export default function NewVpc(props){
	const {isTrial, isPromo} = props
    const { vps_packages } = useSelector(app => app.vps)
    const user = useSelector(app => app?.user?.user)
    const [hoverServerName, setHoverToolTopServerName] = useState(false)
    const [selected, setSelected] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [packSelected, setPack] = useState(null)
    const [paypalpack, setPackPaypal] = useState(null)
    const [codeApplied, setCodeApplied] = useState(null)
    const [link, setlink] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [heightBottomC, setBottomCalculedHeight] = useState(0)


    const [vps_params, setVpsParams] = useState({
        name: '',
        description: '',
        gateway: false,
        location: null,
        trialCode: null,
        promoCode: null,
        isWithPromo: isPromo,
        isAdminFreeDeployment: null
    })

 
    const [creating, setCreating] = useState(false)
    const [paypal, setPaypal] = useState(false)

    

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    
    async function getVpsTypes() {
        if(!vps_packages) {
            const r =  await UserMgt.get_vps_types()
            return r?.vpsTypes || []
        } 
            
        return vps_packages
    }
 


    useEffect(() => {
        if (Utils.getUrlParam("code")) {
            setVpsParams({ ...vps_params, trialCode: Utils.getUrlParam("code"), promoCode: Utils.getUrlParam("code") })
            onApplyCode(Utils.getUrlParam("code"))
        }

        getVpsTypes()
        
    }, [])


    
    async function onApplyCode(code){
        if(!isPromo) return
        try{
            let c = code || vps_params.promoCode
            if(c && c.trim() !== ""){
                const result = await UserMgt.check_promo_code(c);
                if(result && result.code){
                    return setCodeApplied(result?.code)
                }else{
                  return Toast.fire({
                        icon: 'error',
                        title: "Invalid promotion code"
                    })
                }
            }else{
                return Toast.fire({
                    icon: 'error',
                    title: "Please enter promotion code first !"
                })
            }

        }catch(e){
            console.log('error applying code', e)
        }

        return Toast.fire({
            icon: 'error',
            title: "An error occured. If this persist, please tell us"
        })
    }

    async function deployVps(paymentId, dataPayment) {
      
        const result = await VPC.create({ ...vps_params, paymentId }, dataPayment)
        setCreating(false)
        if (!result || result.error) {
            Toast.fire({
                icon: 'error', 
                title: result.message || "An error occurred while running. If this persist, please contact us"
            })
        } else {
            const links = result?.result?.links
            const link = links && links.length && links.find(l => l.rel === "approve")
            if(link){
                setlink(link?.href)
                window.open(link?.href, "Confirm", "width=500,height=500, fullscreen=yes")
            }
            if(result&& !result.needToConfirm){
                Toast.fire({
                    icon: 'success',
                    title: result.message || "New VPC network created"
                })
                await VPC.getAll()
                navigate('/networking/vpc')
            }
        }

    }
    async function onPay(ev) {
        ev.preventDefault()
        if(link){
            return  window.open(link, "Confirm subscription", "width=500,height=500, fullscreen=yes")
         }
      
        if (!vps_params.name){
            return Toast.fire({
                icon: 'error',
                title: "VPC name required!"
            })
        }
        if (!vps_params.location) {
            return Toast.fire({
                icon: 'error',
                title: "Service location not selected"
            })
        }
        if (!vps_params.description) {
            return Toast.fire({
                icon: 'error',
                title: "VPC description is required field"
            })
        }
        if (isPromo && !codeApplied) {
            return Toast.fire({
                icon: 'error',
                title: "You should apply promo code !"
            })
        }
        setCreating(true)
        if(!vps_params.isAdminFreeDeployment){
            if(!isPromo){
                if(vps_params.trialCode && isTrial){
                    const r = await UserMgt.check_trial_code(vps_params.trialCode)
                    const isValid = r && r.code && (!!r.code.IsMulti ||  !(!!r.code.IsUsed ));
                    if(!isValid ){
                        setCreating(false)
                        setPaypal(false)
                        return Toast.fire({
                            icon: 'error',
                            title: "Trial code is not valid !"
                        })
                    }
                }
                setPaypal(true)
            }else{
                return deployVps()
            }
        }else{
            return deployVps()
        }
    }

    function calCulFinalPrice(){
        if(packSelected){

            return parseFloat((packSelected.Price) * (1 - (codeApplied ? codeApplied.Percent / 100 : 0))).toFixed(2)
        }else{
            return 0;
        }
    }

    
    function onDismiss(){
        setCreating(false)
        setPaypal(false)

    }
    function createPaymentAndDeploy(paymentID, dataPayment){
        setPaypal(false)
        const dataPayment2 = {
            ...dataPayment,
            withFirstTrial: isTrial,
            amountPaid:  parseFloat(packSelected?.Price),

        }
        return  deployVps(paymentID, dataPayment2)
         
         

    }
    //PaypalPackId
    const concertPack = vps_packages &&  
                        vps_packages.filter(p => p.Package_Type ==="KS-LB" && !p.IsMini  && !p.IsSec)
                        .sort((a, b) => parseInt(a.Price ) -  parseInt(b.Price))
    return (
        <DeployServerWrapper
            createPaymentAndDeploy={createPaymentAndDeploy}
            paypal={paypal}
            packSelected={packSelected}
            onDismiss={onDismiss}
            isTrial={isTrial}
            isPromo={isPromo}
            calCulFinalPrice={calCulFinalPrice}
            vps_params={vps_params}
            setVpsParams={setVpsParams}
            creating={creating}
            concertPack={concertPack}
            onPay={onPay}
            handleChange={handleChange}
            setBottomCalculedHeight={setBottomCalculedHeight}
            isNetwork
            isVPC
            title="Choose your VPC Type"
            label="Choose your datacenter location region"
            locationDesc="VPC networks can only contain ressource that at the same datacenter region

"
        >  

        	<Radio.Group required  value={vps_params?.gateway} className="w-full pb-5 mt-12">
    			<div className="flex gap-5 flex-wrap justify-center ">
    				<div 
    					className={`
    						border
    						border-2
    						${border(false, vps_params?.gateway)}
    						px-6
    						py-2
    						cursor-pointer
    						items-start
    						flex
    						w-[350px]
    						gap-3
    					`}
    					onClick={() => setVpsParams({...vps_params, gateway: false})}
    				>
    			    	<Radio value={false}></Radio>
    					<div className="text-sm -mt-1 2xl:text-base">
    						<p>
    							<span className="font-semibold text-primary text-base">
    								No gateway
    							</span>
    							<br />
    							<Badge
    						        count={"Recommended"}
    						        style={{
    						          backgroundColor: '#011a43',
    						        }}
    						    />
    						</p>
    					</div>
    				</div>
    				<div 
    					className={`
    						border
    						border-2
    						${border(true, vps_params?.gateway)}
    						px-6
    						py-2
    						w-[350px]
    						items-start
    						cursor-pointer
    						flex
    						gap-3
    					`}
    					onClick={() => setVpsParams({...vps_params, gateway: true})}
    				>
    			    	<Radio value={true}></Radio>
    			    	<div className="text-sm -mt-1 2xl:text-base">
    						<p>
    							<span className="font-semibold text-primary text-base">
    								External gateway
    							</span>
    							<br/>
    							<span className="">
    								You will need to specify a subnet IP or size
    								
    							</span>
    						</p>
    					</div>
    				</div>
    				
    			</div>
    	    </Radio.Group>

            
        
            <p className='text-center text-2xl font-bold text-primary dark:text-darkTextColor pt-10'>
                Chose a name and description
                <br />
                <span className="text-xs font-normal xl:text-sm">
                	You can edit the default name to something meaningful to you
                </span>
            </p>

            <div className={'md:w-5/6 mx-auto pt-10 px-20 '} style={{paddingBottom: heightBottomC+25}}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="serv-name"
                            placeholder={"Name"}
                        />
                        <Tooltip open={hoverServerName}   title="Must not contain spaces, special characters or symbols">
                            <InputTextAdd
                                idx="name"
                                value={vps_params.name}
                                name="name"
                                pattern="[A-Za-z0-9]{1,}"
                                onChange={handleChange}
                                required
                                hover
                                onHover={(state) => setHoverToolTopServerName(state)}
                                disabled={creating}
                                placeholder='Your instance resources must have unique names'
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">


                    <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                        <LabelAdd
                            htmlFor="desc"
                            placeholder={"Description"}
                        />
                        <InputTextAdd
                            idx="description"
                            value={vps_params.description}
                            name="description"
                            onChange={handleChange}
                            required
                            disabled={creating}
                            placeholder='Your instance resources must have unique names'
                        />
                    </div>
                </div>  
            </div>
        </DeployServerWrapper>
    );
}



