import { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { BsFillLightbulbFill } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Spiner } from "components/Bloc";

import debian from '../../../assets/img/debian.png'
import ubuntu from '../../../assets/img/ubuntu.png'
import { RBuildVpsConfirm } from "../../../components/Modals";
import { Toast } from "../../../components/Notify";
import VpsImage from '../../../components/VpsImage'
import VPSapi from '../../../lib/vps_service';
import { PageTitle } from 'components/Bloc';

const imgs = {
    ubuntu,
    debian
}


export default function RebuildMini({server, onRbuild, loadVps}) {
    const navigate = useNavigate()
    const { flavors } = useSelector(app => app.vps)
    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)
    const [clicked, setClicked] = useState(null)
    const [visiblePass, setVissiblePass] = useState(false)
    const [creating, setCreating] = useState(false)
    const  images  = useSelector(app => {
        const imgs = app.vps.images
        const OS = ["Ubuntu", "Debian"];
        const Tab = []
        if(app.vps.images){
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i]; 
                const concerts = imgs.filter(img => (img.name.toLowerCase()).includes(os.toLowerCase()))
                Tab.push({name: os, list: concerts, id: i})
            }
            return Tab
        }
        return null;
    })


    const [vps_params, setVpsParams] = useState({
        clientos: '',
        clientusername: null,
        clientpassword: '',
        passwordCon: '',
        serviceType: "VPS_INSTANCE"
    })

    function handleChange(ev) {
        const { name, value } = ev.target
        setVpsParams({ ...vps_params, [name]: value })
    }

    async function loadFlavors() {
        !flavors && VPSapi.flavor_list() 
    }
    async function formSubmited(ev) {
        ev.preventDefault()
        
        try {
            if(!vps_params.clientusername){
                return Toast.fire({
                    icon: 'error',
                    title: "Username is not valid !"
                 })
            }
            if(!vps_params.image){
                return Toast.fire({
                    icon: 'error',
                    title: "Image is not valid !"
                 })
            }
             if(!vps_params.clientpassword ||  vps_params.clientpassword !== vps_params.passwordCon){
                return Toast.fire({
                    icon: 'error',
                    title: "Passwords did not match !"
                 })
            }
            
            //loadVps(true)
            return window.$("#Rebuild" + server?.Id).modal('show')
        } catch (error) {
        }
    }

    async function onContinue(){
        try {
            setCreating(true)
            const data = { 
                ...vps_params, 
                Id: server?.Id,
                clientos: vps_params?.image && vps_params.image?.split('.')[0].split(' ').join('').toLowerCase()
            }
            const result = await onRbuild("rebuild", data)
            setCreating(false)
            if(result){
                loadVps()
                navigate('/compute/vpsmini')
            }
        } catch (error) {
            
        }
    }
    async function loadImages() {
        !images &&   VPSapi.image_list()
    }


     useEffect(() => {
        loadFlavors()
        loadImages()
        
        
    }, [])

    return (
        <div className=' '>

            <RBuildVpsConfirm id={"Rebuild" + server?.Id} server={server}  onContinue={onContinue} />
            {images ?
                <form onSubmit={formSubmited} className="">
                    
                    <div className="flex gap-2 items-center">
                        <PageTitle text="Pick your rebuild image" />
                    </div>

                    <div className="mx-auto justify-center gap-2 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  md:w-11/12 mt-10">
                        {images && images.filter(image => image.name !== "KPS_cloud").map((image, k) =>
                            <VpsImage 
                                key={k}
                                image={image}
                                creating={creating}
                                clickedImage={clickedImage} 
                                setClickedImage={setClickedImage}
                                setVpsParams={setVpsParams}
                                vps_params={vps_params}
                                setSelectedImage={setSelectedImage}
                                selectedImage={selectedImage}
                                uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                            /> 
                        )} 
                    </div>

                     <div className="flex gap-2 items-center">
                        <p className="text-2xl">Server Informations</p> 
                    </div>

                    <div className={'md:w-11/12 mx-auto pt-5 pb-5'}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full xl:w-1/2 px-3">
                                <label
                                    className="
                                        block
                                        uppercase
                                        tracking-wide
                                        text-gray-700 text-xs
                                        dark:text-darkTextColor
                                        font-bold
                                        mb-2
                                    "
                                    htmlFor="serv-username"
                                >
                                    Server user
                                </label>
                                <input
                                    className="
                                        appearance-none
                                        block
                                        w-full
                                        bg-gray-200
                                        text-gray-700
                                        border border-gray-200
                                        rounded
                                        py-3
                                        px-4
                                        leading-tight
                                        dark:bg-bgPagedark
                                        dark:text-darkTextColor
                                        focus:outline-none focus:bg-white focus:border-gray-500
                                    "
                                    id='serv-username'
                                    value={vps_params.clientusername}
                                    name="clientusername"
                                    onChange={handleChange}
                                    required
                                    disabled={creating}
                                    pattern="(?!root$)(?!admin$)(?!keepsec$)(?!ubuntu$)(?!almalinux$)([A-Za-z0-9]{3,})$"
                                    placeholder='Username used to login via ssh'
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">

                            <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                                <label
                                    className="
                                        block
                                        uppercase
                                        tracking-wide
                                        text-gray-700 text-xs
                                        dark:text-darkTextColor
                                        font-bold
                                        mb-2
                                    "
                                    htmlFor="serv-pass"
                                >
                                    Server password
                                </label>

                                <div className="flex h-12 mb-3">
                                    <input
                                        className="
                                            appearance-none
                                            block
                                            w-full
                                            bg-gray-200
                                            text-gray-700
                                            border
                                            rounded
                                            py-3
                                            px-4
                                            leading-
                                            dark:bg-bgPagedark
                                            dark:text-darkTextColor
                                            focus:outline-none focus:bg-white
                                        "
                                        id="serv-pass"
                                        value={vps_params.clientpassword}
                                        name="clientpassword"
                                        onChange={handleChange}
                                        required
                                        type={!visiblePass&& "password"}
                                        disabled={creating}
                                        placeholder='Password used to login via ssh'
                                    />
                                    <div onClick={()=> setVissiblePass(!visiblePass)} className="flex px-4 cursor-pointer bg-gray-200 dark:bg-bgPagedark items-center justify-center  border">
                                        {visiblePass ? <AiFillEyeInvisible className="text-2xl text-primary dark:text-darkTextColor"/> : <AiFillEye className="text-2xl text-primary dark:text-darkTextColor"/>}
                                    </div>
                                </div>


                                <p className=" font-semibold text-red-700 -mt-3" style={{fontSize: "14px"}} >
                                    <i> Must contain only alphanumeric, dashes or undescore symbols</i>
                                </p>
                            </div>
                            <div className="w-full xl:w-1/2 px-3">
                                <label
                                    className="
                                        block
                                        uppercase
                                        tracking-wide
                                        text-gray-700 text-xs
                                        dark:text-darkTextColor
                                        font-bold
                                        mb-2
                                    "
                                    htmlFor="serv-pass-confirm"
                                >
                                    Password confirmation
                                </label>
                                <input
                                    className="
                                        appearance-none
                                        block
                                        w-full
                                        bg-gray-200
                                        text-gray-700
                                        border border-gray-200
                                        rounded
                                        py-3
                                        px-4
                                        leading-tight
                                        dark:bg-bgPagedark
                                        dark:text-darkTextColor
                                        focus:outline-none focus:bg-white focus:border-gray-500
                                    "
                                    id='serv-pass-confirm'
                                    type="password"
                                    value={vps_params.passwordCon}
                                    name="passwordCon"
                                    onChange={handleChange}
                                    required
                                    disabled={creating}
                                    placeholder='Password confirmation'
                                />
                            </div>
                        </div>

                    </div>

                    <div className="mx-auto">
                        <div className=''>
                            <button className="flex  mx-auto text-xl   w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                type="submit"
                                disabled={creating} 
                            >
                                {!creating ?
                                    <span className='mx-auto flex  items-center'>
                                        <BsFillLightbulbFill className='text-2xl' />
                                        Rebuild
                                    </span>
                                    :
                                    <span className='mx-auto flex gap-1 items-center'>
                                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Ongoing
                                    </span>
                                }

                                   

                                
                            </button>
                        </div>
                    </div>
                </form>
                :
                <div className='flex justify-center pt-48'>
                    <Spiner fontSize={40} />
                </div>
            }
        </div> 
    );
}
