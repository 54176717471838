import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Spiner } from "components/Bloc";
import { PageTitle } from "components/Bloc";
import { Toast } from "components/Notify";
import UserMgnt from "lib/user_managment";

import almalinux from '../../../assets/img/almalinux.png'
import centos from '../../../assets/img/centos.png'
import debian from '../../../assets/img/debian.png'
import docker from '../../../assets/img/docker.png'
import linux from '../../../assets/img/linux.png'
import ubuntu from '../../../assets/img/ubuntu.png'
import VpsImage from '../../../components/VpsImage'
import VPSapi from '../../../lib/vps_service';

const imgs = {
    ubuntu,
    debian,
    almalinux,
    docker,
    linux,
    centos
}


export default function RebuildVPS({server_id, Id}) {

    const { flavors } = useSelector(app => app.vps)
    const [selectedImage, setSelectedImage] = useState(null)
    const [clickedImage, setClickedImage] = useState(null)
    const dispatch  = useDispatch()
    const navigate = useNavigate()
    const [creating, setCreating] = useState(false)
    const  images  = useSelector(app => {
        const imgs = app.vps.images
        const OS = ["Ubuntu", "AlmaLinux", "Debian", "CentOS"];
        const Tab = []
        if(app.vps.images){
            for (let i = 0; i < OS.length; i++) {
                const os = OS[i];
                const concerts = imgs.filter(img => (img.name.toLowerCase()).includes(os.toLowerCase()))
                Tab.push({name: os, list: concerts, id: i})
            }
            return Tab
        }
        return null;
    })


    const [vps_params, setVpsParams] = useState({
        image: null,
        // username: "",
        // password: "",
        // passwordCon: "",
        serviceType: "VPS_INSTANCE"
    })

    function handleChange(ev) {
        const { name, value } = ev.target
        // if(name === "password"){
        //     if((String(value).trim()).length >= 6){
        //         setDisplayPassToolTip(false)
        //     }else{
        //         setDisplayPassToolTip(true)
        //     }
        // }
            setVpsParams({ ...vps_params, [name]: value })
        
    }

    async function loadFlavors() {
        !flavors && VPSapi.flavor_list() 
    }
    async function loadImages() {
        !images &&   VPSapi.image_list()
    }

    async function onRebuild(ev){
        if(ev) ev.preventDefault()
        // if(passwordTooltip){
        //     return Toast.fire({
        //         icon: 'error',
        //         title: "Password must be at least 6 characters"
        //     })
        // }
        // if(vps_params.password !== vps_params.passwordCon){
        //     return Toast.fire({
        //         icon: 'error',
        //         title: "The passwords didn't match."
        //     })
        // }
        if(!vps_params.image){
            return Toast.fire({
                icon: 'error',
                title: "Server image not selected"
            })
        }
        // if(!vps_params.username){
        //     return Toast.fire({
        //         icon: 'error',
        //         title: "Server ssh username is empty"
        //     })
        // }
        const data = {
            ...vps_params,
            server_id,
            Id
        }
        // delete data.passwordCon
        setCreating(true)
        const result = await UserMgnt.rebuildNormlVps(data) 
        setCreating(false)
        console.log('Rebuild Vps result', result)
        if(!result.error){
            Toast.fire({
                icon: 'success',
                title: "Your server has been rebuilded"
            })
            dispatch({ type: "@DEPLOYED_VPS", state: true })
            await UserMgnt.get_user_vps();
            return navigate(-1)
        }else{
            return Toast.fire({
                icon: 'error',
                title: result.message || "An errors occurs, please contact us througth live chat or create a ticket"
            })
        }
    }


     useEffect(() => {
        loadFlavors()
        loadImages()
        
        
    }, [])

    return (
        <div className=' '>

            {images ?
                <form onSubmit={onRebuild} className="">
                    
                    <div className="flex gap-2 items-center">
                        
                        <PageTitle text="Pick your rebuild image" />
                    </div>

                    <div className="mx-auto justify-center gap-2 pb-5 border-b-2 dark:border-gray-800 flex flex-wrap gap-2 justify-center  md:w-11/12 mt-10">
                        {images && images.filter(image => image.name !== "KPS_cloud").map((image, k) =>
                            <VpsImage 
                                key={k}
                                image={image}
                                creating={creating}
                                clickedImage={clickedImage} 
                                setClickedImage={setClickedImage}
                                setVpsParams={setVpsParams}
                                vps_params={vps_params}
                                setSelectedImage={setSelectedImage}
                                selectedImage={selectedImage}
                                uri={imgs[Object.keys(imgs).find(k => image.name.toLowerCase().includes(k)) || 'linux']}
                            /> 
                        )} 
                    </div>

                     {/* <div className="flex gap-2 items-center">
                        <p className="text-2xl">Server Informations</p> 
                    </div>

                    <div className={'md:w-11/12 mx-auto pt-5 pb-5 border-b-2 dark:border-gray-800'}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full xl:w-1/2 px-3">
                                <LabelAdd
                                    htmlFor="serv-username"
                                    placeholder={"Server user"}
                                />
                                <InputTextAdd
                                    idx='serv-username'
                                    value={vps_params.username}
                                    name="username"
                                    onChange={handleChange}
                                    required
                                    disabled={creating}
                                    pattern="(?!root$)(?!admin$)(?!keepsec$)(?!ubuntu$)(?!almalinux$)([A-Za-z0-9]{3,})$"
                                    placeholder='Username used to login via ssh'
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">

                            <div className="w-full xl:w-1/2 px-3 mb-6 md:mb-0">
                                <LabelAdd
                                    htmlFor="serv-pass"
                                    placeholder={"Server password"}
                                />
                                <Tooltip open={passwordTooltip}   title="The password must be at least 6 characters">
                                    <InputPassAdd
                                        
                                        idx="serv-pass"
                                        value={vps_params.password}
                                        name="password"
                                        onChange={handleChange}
                                        required
                                        disabled={creating}
                                        placeholder='Password used to login via ssh'
                                    />
                                </Tooltip>
                            </div>
                            <div className="w-full xl:w-1/2 px-3">
                                <LabelAdd
                                    htmlFor="serv-pass-confirm"
                                    placeholder={"Password confirmation"}
                                />
                                <InputTextAdd
                                    idx='serv-pass-confirm'
                                    type="password"
                                    value={vps_params.passwordCon}
                                    name="passwordCon"
                                    onChange={handleChange}
                                    required
                                    disabled={creating}
                                    placeholder='Password confirmation'
                                />
                            </div>
                        </div>

                    </div> */}

                    <div className="mx-auto pt-5">
                        <Button 
                            onClick={onRebuild} 
                            size="large" 
                            type="primary" 
                            className=" w-72 flex items-center justify-center font-bold py-4 px-4 m-auto" 
                            loading={creating}>
                            Rebuild
                        </Button>
                    </div>
                </form>
                :
                <div className='flex justify-center pt-48'>
                    <Spiner fontSize={40} />
                </div>
            }
        </div> 
    );
}
