import React from "react"
import { SiWikiquote,  } from "react-icons/si";
import { useState, useContext } from 'react';

import { ThemeContext } from '../../theme/ThemeContext';

export default function Wiki() {
    const [isVisible, setIsVisible] = useState(false)
    const { theme, setTheme } = useContext(ThemeContext);



    React.useEffect(()=>{
        //UserMgt.get_user_payment() 
    }, [])

    return (
        <div className='h-full '>
             <div className={"px-3 md:px-8 h-full  dark:text-darkTextColor pt-10  "+ (theme !== "dark" ? "  support-back " : "")}>
                <div className="container h-full mx-auto max-w-full">
                    <div className="block dark:bg-bgPagedark p-6 rounded-lg shadow-2xl bg-white max-w-sm text-center mx-auto">
                        <div>
                            <SiWikiquote  className='text-5xl text-primary dark:text-darkTextColor mx-auto'/>
                        </div> 
                        <h5 className="text-primary dark:text-darkTextColor font-semibold text-xl leading-tight font-medium mb-2">
                             KeepSec Docs
                        </h5>
                        <p className="text-gray-700 text-base mb-4 dark:text-darkTextColor">
                             Browse through our documentation for any technical guidance or usage tips
                        </p>
                        <a target="_blank" href="https://docs.keepsec.ca">
                            <button
                                className="font-bold mx-auto lg:block px-4 py-1 text-sm text-gray-700 dark:text-darkTextColor transition duration-150 ease-in-out  bg-transparent  border dark:border-[3px] border-primary rounded focus:outline-none hover:text-white  hover:bg-primary hover:border-primary sm:px-8 sm:py-3"
                            >
                                 Go to Docs
                            </button>
                        </a>
                    </div>
                </div>
                {/*<div className={'absolute bottom-0 w-screen left-0 support-back bg-no-repeat md:bg-repeat'} style={{ height: "400px", zIndex: -1 }}>
                </div>*/}
            </div>
        </div> 
    );
}
