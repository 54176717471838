export const pages = {
    "vps": {
        title: "VPS in a minutes.",
        desc: "KeepSec VPS are Linux-based virtual machines (VMs) that run on top of virtualized hardware. Each VPS you create is a new server you can use, either standalone or as part of a larger, cloud-based infrastructure.",
    	items: [
    		{
				src: "manag.jpg",
    			title: "Managed Services",
    			text: "Managing self-hosted clusters requires expertise and time. We do the heavy-lifting by managing key services and settings on your behalf."
    		},
    		{
				src: "color.jpg",
    			title: "CPU pinning and supports nested virtualization",
    			text: "Nesting Enables complex testing, isolated training, and ability to create multiple virtual environments. CPU Pinning Optimizes performance, reduces latency, ensures stability, and efficient utilization and isolation."
    		},
    		{
				src: "carr.png",
    			title: "No egress fees, ever",
    			last: true,
    			text: "You get free unlimited data transfer as standard at KeepSec. We'll never charge you ingress or egress fees - whether you stay or ever wish to leave."
    		},
    	],
    	link: "/compute/vps/deploy",
    	buttonLabel: "Create a VPS",
    	options: [
    		"Super-fast launch times",
    		"Multi-region support",
    		"24/7 technical support",
    		"Flexible automation tools",
    		"Connect with SSH or our in-app console",
    	]
    },
    "vpsmini": {
        title: "Secure and scalable containerized VPS",
        desc: "VPSMini offers an affordable, unique mix of shared and dedicated resources, ideal for small workloads. You always have guaranteed access to dedicated CPU and RAM, with generous amounts of easily accessible extra resources at no additional cost.",
    	items: [
    		{
				src: "manag.jpg",
    			title: "Significant price-to-performance ratio",
    			text: "VPSM plans are ideal for development servers, staging servers, low traffic websites, personal blogs, and production applications that may not be affected by resource contention."
    		},
    		{
				src: "color.jpg",
    			title: "File Manager and SSH Web Console",
    			text: "Upload file and connect via SSH directly from your web browser, using our web-based SSH clients and file manager for easy, secure server management."
    		},
    		{
				src: "carr.png",
    			title: "Integrated WAF and Security by design",
    			last: true,
    			text: "Possibility to use your own domain with the integrated WAF, no firewall to manage, the most important port are open 80, 443, 8080, 8443, 3000 are accessible via the keepsec domain our own, SSH and FTP port are accessible directly via the IP."
    		},
    	],
    	link: "/compute/vpsmini/deploy",
    	buttonLabel: "Create a VPSM",
    	options: [
    		"One-click app install",
    		"Real-time protection",
    		"Lightning speed",
    		"Rapid Reconfiguration",
    		"Scalable",
    	]
    },
    "khks": {
        title: "Business-ready Kubernetes",
        desc: "Harness the power of automation and leave the Kubernetes infrastructure to us so you can focus on building world-changing apps.",
    	items: [
    		{
				src: "manag.jpg",
    			title: "Managed services",
    			text: "Managing self-hosted clusters requires expertise and time. We do the heavy-lifting by managing key services and settings on your behalf."
    		},
    		{
				src: "color.jpg",
    			title: "Full Kubernetes API",
    			text: "Provision a KeepSec Kubernetes cluster and we provide a control plane and endpoint to use with the kubectl CLI or any Kubernetes ecosystem tool."
    		},
    		{
				src: "carr.png",
    			title: "High Availability",
    			last: true,
    			text: "It possible to have you cluster in two location to achieve real high availability."
    		},
    	],
    	link: "/kubernetes/kskubhybr/deploy",
    	buttonLabel: "Create a Kubernetes Cluster",
    	options: [
    		"Mulitple control plane",
    		"Portable and flexible",
    		"Affordable pricing",
    		"Automatic scaling",
    		"Integrated load balancers and persistent storage",
    	]
    },
    "vullify": { 
        title: "Vullify a Vulnerability Scanner.",
        desc: "Vullify is a security diagnosis service that uses weakness detection and intelligent correlation analysis technologies to help discover security risks in your websites or servers",
    	items: [
    		{
				src: "manag.jpg",
    			title: "Website Vulnerability Scan",
    			text: "Website vulnerabilities can cripple your business. They can result in tremendous financial losses if not discovered and addressed quickly."
    		},
    		{
				src: "color.jpg",
    			title: "Scans for Common Vulnerabilities",
    			text: "All types of websites are scanned based on an extensive vulnerability library, and comprehensive, professional reports are produced."
    		},
    		{
				src: "carr.png",
    			title: "Scans for Most Recent Critical Vulnerabilities",
    			last: true,
    			text: "Security experts are constantly analyzing the latest critical vulnerabilities and updating scan rules to provide the fastest and most-complete CVE vulnerability scan possible."
    		},
    	],
    	link: "/security/vullify/deploy",
    	buttonLabel: "Create a Vullify",
    	options: [
    		"Asset Discovery",
    		"Advanced Detection",
    		"Agentless Onboarding",
    		"Easy-To-Deploy",
    		"Cost Effective",
    	]
    },
    "vpsminis": {
        title: "",
        desc: "",
    	items: [
    		{
    			title: "",
    			text: ""
    		},
    		{
    			title: "",
    			text: ""
    		},
    		{
    			title: "",
    			text: ""
    		},
    	],
    	link: "/compute/vps/deploy",
    	buttonLabel: "Create a VPS",
    	options: [
    		"Asset Discovery",
    		"Advanced Detection",
    		"Agentless Omboarding",
    		"Easy-To-Deploy",
    		"Cost Effective",
    	]
    },
	"kps": {
        title: "Deploy Applications Effortlessly",
    	desc: "KPS enables you to deploy containerized applications seamlessly. With our platform, you can focus on development while we handle the infrastructure, offering you the scalability and flexibility needed to grow your business.",
        items: [
            {
				src: "manag.jpg",
                title: "Fully Managed Platform",
                text: "Our PaaS handles all infrastructure management tasks, including automated scaling, efficient load balancing, and robust security, allowing you to concentrate solely on building your application."
            },
            {
				src: "color.jpg",
                title: "Seamless Integration and Deployment",
                text: "Easily integrate with popular CI/CD tools for smooth and continuous deployment. Our platform supports major container orchestration tools, ensuring optimal performance for your applications."
            },
            {
				src: "carr.png",
                title: "Pay-as-you-go Pricing",
                last: true,
                text: "With KeepSec PaaS, you only pay for the resources you use. Our transparent pricing model ensures no hidden fees or unexpected charges."
            }
        ],
        link: "/app-platform/kps/deploy",
        buttonLabel: "Create a KPS",
        options: [
            "Quick and easy setup",
            "Scalable infrastructure",
            "Integrated monitoring and logging",
            "200+ One-click app deployment",
            "Custom domain with automated SSL management",
        ]
    },
	kwaf: {
        title: "Protect Your Web Applications ",
        desc: "K-WAF provides advanced protection for your web applications using AI-driven security measures. Our firewall ensures that your applications remain safe from malicious attacks while maintaining high performance and reliability.",
        items: [
            {
				src: "manag.jpg",
                title: "AI-Driven Security",
                text: "K-WAF employs cutting-edge AI algorithms to detect and mitigate threats in real-time. This proactive approach ensures that your applications are always protected against the latest vulnerabilities."
            },
            {
				src: "color.jpg",
                title: "Comprehensive Threat Detection",
                text: "Our platform offers extensive coverage against various attack vectors, including SQL injection, cross-site scripting (XSS), and other common web vulnerabilities, ensuring comprehensive protection."
            },
            {
				src: "carr.png",
                title: "High Performance and Reliability",
                last: true,
                text: "With K-WAF, you can achieve top-notch security without compromising on performance. Our firewall is optimized for minimal latency, ensuring a smooth user experience for your applications."
            }
        ],
        link: "/security/kwaf/deploy",
        buttonLabel: "Create a K-WAF",
        options: [
            "Real-time threat intelligence",
            "Automated threat mitigation",
            "Customizable security policies",
            "Detailed security analytics",
            "24/7 monitoring and alerts",
            "Scalable protection for growing applications",
            "User-friendly management console",
        ]
    }
}