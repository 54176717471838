import React from 'react'
import home from "../assets/img/link/home-logo.png"
import homeW from "../assets/img/link/home-logo-white.png"

import vps from "../assets/img/link/VPS-logo.png"
import vpsW from "../assets/img/link/VPS-logo-white.png"

import kps from "../assets/img/link/KPS-logo.png"
import kpsW from "../assets/img/link/KPS-logo-white.png"

import billingW from "../assets/img/link/billing-logo.png"
import billing from "../assets/img/link/billing-logo-white.png"

import profileW from "../assets/img/link/PROFILE-logo.png"
import profile from "../assets/img/link/PROFILE-logo-white.png"

import wikiW from "../assets/img/link/WIKI-logo.png"
import wiki from "../assets/img/link/WIKI-logo-white.png"

import ticketW from "../assets/img/link/TICKET-logo.png"
import ticket from "../assets/img/link/TICKET-logo-white.png"

import forumW from "../assets/img/link/forum-logo.png"
import forum from "../assets/img/link/forum-logo-white.png"

import vpsmini from "../assets/img/link/VPSmini-logo.png"
import vpsminiW from "../assets/img/link/VPSmini-logo-white.png"

import kpsmini from "../assets/img/link/kpsminib.png"
import kpsminiW from "../assets/img/link/kpsmini.png"

import rewardW from "../assets/img/link/REWARD-logo.png"
import reward from "../assets/img/link/REWARD-logo-white.png"

import trial from "../assets/img/link/TRIAL-logo.png"
import trialW from "../assets/img/link/TRIAL-logo-white.png"

import vpssec from "../assets/img/link/VPSsec-logo.png"
import vpssecW from "../assets/img/link/VPSsec-logo-white.png"

import vpssecmini from "../assets/img/link/VPSsecmini-logo.png"
import vpssecminiW from "../assets/img/link/VPSsecmini.png"

import infrafusion from "../assets/img/link/infrafusionb.png"
import infrafusionW from "../assets/img/link/infrafusion.png"

import kpssec from "../assets/img/link/kpsecb.png"
import kpssecW from "../assets/img/link/kpssec.png"

import kskub from "../assets/img/link/kssubb.png"
import kskubW from "../assets/img/link/kssub.png"

import vullify from "../assets/img/link/vullifyb.png"
import vullifyW from "../assets/img/link/vullify.png"

import kwaf from "../assets/img/kwaf/kwaf.webp"
import kwafW from "../assets/img/kwaf/kwaf.webp"

import proxy from "../assets/img/connect/touch.png"
import proxyb from "../assets/img/connect/touch.png"

import volume from "../assets/img/storage/volume.png"
import volumeW from "../assets/img/storage/volume.png"

import kskubhybr from '../assets/img/k8/kubernetes-kp.webp'
import kskubhybrW from '../assets/img/k8/kubernetes-kp.webp'

import kslb from '../assets/img/networking/icon.png'
import kslbW from '../assets/img/networking/icon.png'

import vpc from '../assets/img/networking/left.png'
import vpcW from '../assets/img/networking/left.png'

import { ThemeContext } from '../theme/ThemeContext'; 

import { useLocation } from "react-router-dom"
import { RightOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'
const icons = {
    home, homeW, vps, vpsW, kpsW, kps, billingW, billing, profileW, profile, 
    wikiW, wiki, ticket, ticketW, forumW, forum, vpsminiW, vpsmini, kpsmini,
    kpsminiW, rewardW, reward, trialW, trial, vpssecW, vpssec, vpssecminiW,
    vpssecmini, infrafusionW, infrafusion, kpssecW, kpssec, kskubW, kskub, vullify, 
    vullifyW, proxy, proxyb, kskubhybr, kskubhybrW, kwaf, kwafW, volume, volumeW,
    kslb, kslbW, vpc, vpcW
}
const staticLinks = {
    "/":"home",
    "/console": 'console',
    //vps
    "/compute/vps": 'list/vps',
    // "compute/vps/manage/": 'managevps',
    "/compute/vps/deploy": "addvps",
    "/compute/vps/deploy/trial": "trialvps",
    "/compute/vps/deploy/promo": "promovps",
    //vpsmini
    "/compute/vpsmini": 'list/vpsmini',
    // "compute/vpsmini/manage/": 'managevpsmini',
    "/compute/vpsmini/deploy": "addvpsmini",
    "/compute/vpsmini/deploy/promo": "promovpsmini",
    //vpssec
    "/compute/vpssec": 'list/vpssec',
    "/compute/vpssec/deploy": "addvpssec",
    "/compute/vpssec/deploy/trial": "trialvpssec",
    "/compute/vpssec/deploy/promo": "promovpssec",
    //vpssecmini
    "/compute/vpssecmini": 'list/vpssecmini',
    "/compute/vpssecmini/deploy": "addvpssecmini",
    "/compute/vpssecmini/deploy/trial": "trialvpssecmini",
    "/compute/vpssecmini/deploy/promo": "promovpssecmini",
    //kps
    "/app-platform/kps": 'list/kps',
    "/app-platform/kps/deploy": "addkps",
    "/app-platform/kps/deploy/trial": "trialkps",
    "/app-platform/kps/deploy/promo": "promokps",
    //kpsmini
    "/app-platform/kpsmini": 'list/kpsmini',
    "/app-platform/kpsmini/deploy": "addkpsmini",
    //kpssec
    "/app-platform/kpssec": 'list/kpssec',
    "/app-platform/kpssec/deploy": "addkpssec",
    //kpsmini
    "/app-platform/kpsmini": 'list/kpsmini',
    "/app-platform/kpsmini/deploy": "addkpsmini",
    //kpsdev
    // "/app-platform/kpsdev": 'list/kpsdev',
    // "/app-platform/kpsdev/deploy": "addkpsdev",

    //kskub
    "/kubernetes/kskub": 'list/kskub',
    "/kubernetes/kskub/deploy": "addkskub",

    //kskubops
    // "/kubernetes/kskubmini": 'list/kskubmini',
    // "/kubernetes/kskubmini/deploy": "addkskubmini",
    //kskubhybr
    "/kubernetes/kskubhybr": 'list/kskubhybr',
    "/kubernetes/kskubhybr/deploy": "addkskubhybr",
    //kskubops
    // "/kubernetes/kskubops": 'list/kskubops',
    // "/kubernetes/kskubops/deploy": "addkskubops",

    //storage
    "/storage/volume": 'list/volume',
    "/storage/volume/deploy": "addvolume",

    //infrafusion
    "/infrastructure/infrafusion": 'list/infrafusion',
    "/infrastructure/infrafusion/deploy": "addinfrafusion",
    //infrafusionmini
    // "/infrastructure/infrafusionmini": 'list/infrafusionmini',
    // "/infrastructure/infrafusionmini/deploy": "addinfrafusionmini",
    //infrafusionbmetal
    // "/infrastructure/infrafusionbmetal": 'list/infrafusionbmetal',
    // "/infrastructure/infrafusionbmetal/deploy": "addinfrafusionbmetal",

    //vullify
    "/security/vullify": 'list/vullify',
    "/security/vullify/deploy": 'addvullify',
    //kwaf
    "/security/kwaf": 'list/kwaf',
    "/security/kwaf/deploy": 'addkwaf',
    //kslb
    "/networking/kslb": 'list/kslb',
    "/networking/kslb/deploy": 'addkslb',

    //vpc
    "/networking/vpc": 'list/vpc',
    "/networking/vpc/deploy": 'addvpc',
}
const otherPage = [
    "billing",
    "reward",
    "profile",
    "wiki",
    "forum",
    "ticket"
]


export default function Breadcrumb(){
    const { theme, setTheme } = React.useContext(ThemeContext);
    const location = useLocation().pathname;
    const name = staticLinks[location]
    const { headLinks } = useSelector(app => app.core)

    return(
        <h4 className="text-primary flex items-center dark:text-darkTextColor text-sm md:text-base xl:text-xl 2xl:text-2xl tracking-wider mt-1  md:pr-8 md:ml-60   2xl:ml-72 font-extrabold">
            {
                name && name.includes('list/')  ?
                    <img className="2xl:h-10 md:h-8 h-7 mx-auto" src={theme!=="dark" ? icons[name.split('/')[1]] : icons[name.split('/')[1]+'W']} alt={name}/>
                    :
                name === "home" ?
                    <img className="2xl:h-10 md:h-8 h-7 mx-auto" src={theme!=="dark" ? icons[name] : icons[name+'W']} alt={name}/>

                :
                location && location.includes('deploy') && !location.includes('trial')?
                    <span className="flex items-center">
                        <NavLink
                            to={("/"+location.split('/')[1]+'/'+location.split('/')[2])}
                            className=""
                        >
                            <img className="2xl:h-10 md:h-8 h-7 mx-auto" src={theme !=="dark" ? icons[location.split('/')[2]] : icons[location.split('/')[2]+'W']} alt={""}/>
                        </NavLink>
                        <RightOutlined className='text-sm xl:text-base'/>
                        <span>
                            New 
                        </span>
                    </span>
                    :
                location && location.includes('deploy') && location.includes('trial')?
                    <img className="2xl:h-10 md:h-8 h-7 mx-auto" src={theme !=="dark" ? trial : trialW} alt={""}/>
                :
                otherPage.find((elt) => "/"+elt === location) ?
                    <img className="2xl:h-10 md:h-8 h-7 mx-auto" src={theme ==="dark" ? icons[location.split('/')[1]] : icons[location.split('/')[1]+'W']} alt={location}/>
                :

                headLinks&& headLinks.length ? 
                
                headLinks.map((link, k) => {
                    return(
                        <span className="flex items-center" key={k}>
                            {(k != 0 || link.second || link.arrow?
                                <RightOutlined className='text-sm xl:text-base'/>
                                :
                                null
                            )} 
                            {link.link ?
                                <NavLink
                                    to={link.link}
                                    className=""
                                >
                                    {link.item ?
                                        <img className="2xl:h-10 md:h-8 h-7 mx-auto" src={theme==="dark" ? icons[link.item+"W"] : icons[link.item]} alt={link.alt}/>
                                        :
                                        <span>{link.label}</span>

                                    }
                                    
                                </NavLink>
                                :
                                <span className='font-bold'>
                                    {link.label} 
                                </span>
                            }
                        </span>
                        )
                    }
                )
                :
                <div className="py-5"/>
            }
        </h4>
    )
}
//<div className="py-5"/>