import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Divider, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ServerLocation } from 'components/vps/ServerLocation';

import { PaymentMethodWorker } from '../../../components/billing/Payment'
import { SizeCard } from '../../../components/volume/VolumeSection'
import { VPS_MANAGE } from '../../../constants/link'
import { STANDARD_ERROR_MESSAGE } from '../../../constants/message'
import STORAGE from '../../../lib/storage';
import UserMgt from '../../../lib/user_managment';
import Utils from "../../../utils"

export default function AddVolumeStorage(){ 
	const Id = Utils.getUrlParam('selected')
	const list = useSelector(app => {
        return app.vps?.list?.filter(s => s.service?.task_name !== "vpsmini") || null
    })
  	const  paymentMethods  = useSelector(app => app.core?.paymentMethods?.filter(m => !!m.isValidated))


   const {messageApi} = useSelector(app => app.core )
   const navigate = useNavigate()

	function border(id, inspect){
		return id === inspect ? "border-primary bg-[#011a430d] " : "border-gray-200"
	}

	const [sizes, setSizeP] = useState(null)
	const [creating, setCreating] = useState(false)
	const [createSubscriptionStart, setCreateSubscriptionStart] = useState(false)
	const [addNewPaymentM, setAddPaymentMedthod] = useState(false)

	const [data, setData] = useState({
		type: "ssd",
		configOption: "auto",
		fileSystem: "ext4"
	});

  	const onChange = (e) => {
	    setData({
	    	...data,
	    	type: e.target.value
	    });
  	};

  	

  	// async function getVolumeSize(){
  	// 	const data = await STORAGE.getVolumeSize()
  	// 	if(!data?.error){ 
  	// 		setSize(data?.sizes?.sort((a, b) => -b.id + a.id))
  	// 	}
  	// }

  	async function loadVps(){
        !list && await UserMgt.get_user_vps() 
        if(Id){
        	setData({
        		...data,
        		serverId: Id
        	})
        }
    }

    async function volumeOnCrete(ev){
    	ev.preventDefault()
    	let wrong = false
    	if(!data?.sizeId){
    		messageApi.open({
              type: 'error',
              content: 'Volume size field is required!',
            });
            wrong=true
    	}
    	const size = sizes.find(s => s.id == data?.sizeId)
    	if(size && size?.isCustom && !data?.customSize){
    		messageApi.open({
              type: 'error',
              content: 'Size in GB field is required!',
            });
            wrong=true
    	}
    	if(!data?.location){
    		messageApi.open({
              type: 'error',
              content: 'You must specify a region where you are going to create volume',
            });
            wrong=true
    	}

        console.log('data VOLUME CREATION ', data)
        if(wrong){
        	return //
        }
        if(!paymentMethods || !paymentMethods.length){
    		return setCreateSubscriptionStart(true)
        }else{
			return payAndDeploy()
		}
        // return setAddPaymentMedthod(true)
    }

    async function payAndDeploy(paymentMethodId){
    	setCreating(true)
    	setCreateSubscriptionStart(false)
        const result = await STORAGE.createVolume(
        	{
        		...data,
        		// paymentMethodId
        	}
        )
        setCreating(false)
        if(result?.volume && !result?.error){
          await STORAGE.list()
          if(Id){
          	 navigate(VPS_MANAGE(Id)+"?act=s")
          }else{	
          	navigate(-1)
          }
          messageApi.open({
            type: 'success',
            content: 'Volume created!',
          });
        }else{
        	messageApi.open({
	            type: 'error',
	            content: result?.message || STANDARD_ERROR_MESSAGE,
	        });
        }
    }
   

    useEffect(()=>{ 
        loadVps() 
    }, [])

  	
	return (
		<div className="px-3 md:px-8 volume pb-10">
				<PaymentMethodWorker
					open={createSubscriptionStart}
					setOpen={setCreateSubscriptionStart}
					payAndDeploy={payAndDeploy}
				/>
				<form className="mx-auto lg:w-[880px]" onSubmit={volumeOnCrete}>
					<div className="w-full">
						<div className="mt-5 pb-5">
							<ServerLocation 
								vps_params={data} 
								setVpsParams={(toSet)=> setData({...data, ...toSet, serverId: null})} 
							/>
						</div>
						<div className="mt-5 pb-5">
							<span className="font-semibold text-base 2xl:text-xl">
								SELECT VOLUME TYPE
							</span>
						</div>
						<div className=" w-full">
							<Radio.Group required onChange={onChange} value={data?.type} className="w-full">
								<div className="flex flex-wrap justify-between ">
									<div 
										className={`
											border
											border-2
											${border("ssd", data?.type)}
											px-6
											py-8
											cursor-pointer
											flex
											w-[430px]
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, type: 'ssd'})}
									>
								    	<Radio value={"ssd"}></Radio>
										<div className="text-sm 2xl:text-base">
											<p>
												<span className="font-semibold text-base 2xl:text-xl">
													Solid state drive (SSD)
												</span>
												<br/>
												SSDs deliver high-speed, reliable storage with faster data access.
											</p>
										</div>
									</div>
									<div 
										className={`
											border
											border-2
											${border("hdd", data?.type)}
											px-6
											py-8
											w-[430px]
											cursor-pointer
											flex
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, type: 'hdd'})}
									>
								    	<Radio value={"hdd"}></Radio>
										<div className="text-sm 2xl:text-base">
											<p>
												<span className="font-semibold text-base 2xl:text-xl">
													Hard disk drive (HDD)
												</span>
												<br/>
												HDDs provide cost-effective, high capacity storage
												ideal for data intensive tasks.
											</p>
										</div>
									</div>
									
								</div>
						    </Radio.Group>
						</div>
					</div>
					<div className="w-full">						
						<SizeCard setSizeP={setSizeP} data={data} setData={setData} />
					</div>
					<div className="w-full flex flex-wrap justify-between gap-3 mt-5">
						<div className="w-[430px] ">
							<div className="mt-5 pb-5">
								<span className="font-semibold text-base 2xl:text-xl">
									SELECT SERVICE TO ATTACH
								</span>
							</div>
							<div> 
								<Select
								    // showSearch
										size="large"
										value={data?.serverId}
										className="w-full rounded-none"
										// dropdownStyle={{borderRadius: "0px"}}
								    placeholder="Select a Service"
								    // optionFilterProp="children"
								    onChange={(selected) => setData({...data, serverId: selected})}
								    // onSearch={onSearch}
								    // filterOption={filterOption}
								    options={list &&list.filter(vps => vps.VpsStatus !== "Deploying" && vps.VpsStatus !== "Pending" && vps.locationId === data?.location).map(vps=>{
								    		return {value: vps.Id, label: vps?.DisplayName}
								    	})
								    }
								    required
								/>
							</div>

						</div>

						<div className="w-[430px]">
							<div className="mt-5 pb-5">
								<span className="font-semibold text-base 2xl:text-xl">
									NAME VOLUME
								</span>
							</div>
							<div>
								<Input 
									onChange={(e) => setData({...data, name: e.target.value})} 
									placeholder="Name of volume" 
									size="large"
									required
									className="w-full rounded-none"
								/>
							</div>
						</div>
					</div>

					<div className="w-full mt-5 ">
						<div className="pb-5">
							<span className="font-semibold text-base 2xl:text-xl">
								CHOOSE CONFIGURATION OPTIONS
							</span>
						</div>
						<div className=" w-full">
							<Radio.Group 
								onChange={(e)=>setData({...data, configOption: e.target.value})} 
								value={data?.configOption} 
								className="w-full"
								required
							>
								<div className="flex flex-wrap justify-between ">
									<div 
										className={`
											border
											border-2
											${border("auto", data?.configOption)}
											px-6
											py-8
											cursor-pointer
											flex
											w-[430px]
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, configOption: 'auto'})}
									>
								    	<Radio value={"auto"}></Radio>
										<div className="text-sm 2xl:text-base">
											<p>
												<span className="font-semibold text-base 2xl:text-xl">
													Automatically Format & Mount
												</span>
												<br/>
												We will choose the appropriate default configurations.
												These setting can be changed later via SSH
											</p>
										</div>
									</div>
									<div 
										className={`
											border
											border-2
											${border("manual", data?.configOption)}
											px-6
											py-8
											w-[430px]
											cursor-pointer
											flex
											gap-3
											justify-between
										`}
										onClick={() => setData({...data, configOption: 'manual'})}
									>
								    	<Radio value={"manual"}></Radio>
										<div className="text-sm 2xl:text-base">
											<p>
												<span className="font-semibold text-base 2xl:text-xl">
													Manually  Format & Mount
												</span>
												<br/>
												We will still attach the volume. You can then manually format
												and mount the volume
											</p>
										</div>
									</div>
									
								</div>
						    </Radio.Group>
						</div>
					</div>

					<div className="w-full mt-5 ">
						<div className="w-full pb-5 flex gap-12">
							<span className="font-semibold text-base 2xl:text-xl">
								Choose a file system
							</span>
							<Radio.Group 
								onChange={(e)=>setData({...data, fileSystem: e.target.value})} 
								value={data?.fileSystem} 
								className=""
								required
							>
						    	<Radio value={"ext4"}>EXT4</Radio>
								
						    	<Radio value={"xfs"}>XFS</Radio>
					
						    </Radio.Group>
						</div>
					</div>
					<div className={`mt-5`}>
						<div className="pb-5">
							<span className="font-semibold text-base 2xl:text-xl">
								ADDITIONNAL OPTIONS
							</span>
						</div>
            <div className="pb-4 my-5 border-2 rounded-md border-gray-300 w-full   shadow-lg">
                <div className="text-base bg-neutral-100 p-6 2xl:text-xl flex gap-2">
                    <InfoCircleFilled className="text-blue-500 text-2xl" />
                    <span className="font-semibold">
                        We recommend encryption to add a security layer and increase peace of mind.
                    </span>

                </div>
                <div className="p-4 text-sm 2xl:text-base border-t-2 border-gray-300">
                    <span className="font-bold text-base 2xl:text-xl">
                        Get extra security for critical data
                    </span>
                    <p className="mt-3 font-semibold ">
                    	An encrypted volumes with a LUKS with extra aes-xts-plain64 and 256bits keys cipher.
                    </p>
                </div>
                <div className="pt-4 px-4 flex justify-between border-t-2 border-gray-300">
                    <div>
                        
                        <Checkbox 
                            className="text-sm md:text-base xl:text-xl font-bold" 
                            size='large'
                            disabled={creating} 
                            onChange={(e) =>{
                                setData({
                                		...data, 
                                		encrypted: e.target.checked
                                	})
                            } }
                        >
                            Add encryption
                        </Checkbox>
                    </div>
                    <div className="text-primary text-sm md:text-base xl:text-xl">
                    </div>
                </div>
            </div>
          </div>
					<div className="w-full mt-5 ">
						<span className="font-semibold text-base 2xl:text-xl">
							Block storage volumes are created in the same region
							and project as the service they are attached to.
						</span>
					</div>
					<div className="w-full py-3 ">
						<Divider />
					</div>
					<div className="w-full py-3 ">
						<Button 
							className="mx-auto w-full font-semibold h-10 2xl:h-12 " 
							style={{borderRadius: "0px"}} 
							type="primary"
							htmlType="submit"
							loading={creating}
							// onClick={
							// 	//()=>volumeOnCrete()
							// }
						>
							Create Volume
						</Button>
					</div>
				</form>
				
		</div>
	)
}