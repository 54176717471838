import { useState } from 'react'
import { useSelector } from 'react-redux';

import { AddServiceSectionTitle, DeployAdAdmin } from 'components/Bloc';
import { Spiner } from 'components/Bloc';
import Paypal from 'components/PaypalModal';
import UserAccountConfirmPrompt from 'components/UserAccountConfirmPrompt'
import AddBotom from 'components/vps/AddBotom';
import PromoBottom from 'components/vps/PromoBottom';
import { ServerLocation } from 'components/vps/ServerLocation';
import TrialBottom from 'components/vps/TrialBottom';

export default function DeployServerWrapper(props){
    const user = useSelector(app => app?.user?.user)
    const [heightBottomC, setBottomCalculedHeight] = useState(0) 

    return( 
        <div className='relative pb-5'>
            <div 
                className={user && !user?.EmailConfirmed ?'opacity-[20%]'  : ''} 
                style={{paddingBottom: heightBottomC+10}}
            >
                <>
                    <Paypal
                        createPaymentAndDeploy={props.createPaymentAndDeploy}
                        open={props.paypal}
                        vpsType={props.packSelected}
                        onDismiss={props.onDismiss}
                        withFirstTrial={props.isTrial}
                        withPromo={props.isPromo}
                        finalPrice={props.calCulFinalPrice}
                    />

                    <div className="px-3 md:px-8 relative  dark:text-darkTextColor ">
                        <div className="container max-w-full">
                            <div className='mx-auto'>
                                <ServerLocation 
                                    vps_params={props.vps_params} 
                                    setVpsParams={props.setVpsParams} 
                                    desc={props.locationDesc} 
                                    label={props.label} 
                                    isVpsMini={props.isVpsMini} 
                                />
                                 
                                <AddServiceSectionTitle
                                    title={props?.title || "Choose your instance plan"}
                                />
                                <DeployAdAdmin
                                    title="Choose your instance plan"
                                    creating={props.creating}
                                    vps_params={props.vps_params}
                                    setVpsParams={props.setVpsParams}
                                />
                                   
                                {props.concertPack ?
                                    <form onSubmit={props.onPay} className="">
                                        {props.children}
                                        <div className={''}>
                                            {
                                                props.isTrial?
                                                    <TrialBottom 
                                                        isKps={props?.isKps}
                                                        isSecurity={props?.isSecurity}
                                                        isNetwork={props?.isNetwork}
                                                        creating={props.creating}
                                                        vps_params={props.vps_params}
                                                        paypal={props.paypal}
                                                        packSelected={props.packSelected}
                                                        setBottomCalculedHeight={setBottomCalculedHeight}
                                                        handleChange={props.handleChange}
                                                    />
                                                :
                                                props.isPromo ?
                                                    <PromoBottom 
                                                        isKps={props?.isKps}
                                                        isSecurity={props?.isSecurity}
                                                        isNetwork={props?.isNetwork}
                                                        creating={props.creating}
                                                        vps_params={props.vps_params}
                                                        paypal={props.paypal}
                                                        packSelected={props.packSelected}
                                                        setBottomCalculedHeight={setBottomCalculedHeight}
                                                        handleChange={props.handleChange}
                                                        codeApplied={props.codeApplied}
                                                        onApplyCode={props.onApplyCode}
                                                        codeApplying={props.codeApplying}
                                                    />
                                                :
                                                <AddBotom 
                                                    isKps={props?.isKps}
                                                    isSecurity={props?.isSecurity}
                                                    isNetwork={props?.isNetwork}
                                                    creating={props.creating}
                                                    vps_params={props.vps_params}
                                                    paypal={props.paypal}
                                                    packSelected={props.packSelected}
                                                    setBottomCalculedHeight={setBottomCalculedHeight}

                                                />

                                            }

                                        </div>
                                    </form>
                                    :
                                    <div className='flex justify-center items-center h-48 '>
                                        <Spiner fontSize={50} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            </div>
            {user && !user?.EmailConfirmed ?
                <UserAccountConfirmPrompt user={user} />
                :
                null
            }
        </div>
    )
}