import { LogoutOutlined, QuestionCircleFilled, SettingFilled, UserOutlined } from '@ant-design/icons';
import { NovuProvider, PopoverNotificationCenter, useSocket } from "@novu/notification-center";
import { Badge, Checkbox, Divider, Popover, Radio, Select, Space } from 'antd';
import { Crisp } from "crisp-sdk-web";
import React, { useEffect } from 'react';
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdNotifications } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// import LanguageDetector from 'i18next-browser-languagedetector';
import { dontDisplayAllItemsItems, forceReload } from "reducer/action/coreActions";

import K8S from '../lib/k8s';
import UserAPI from "../lib/keepsec_auth_service"
import UserMgt from "../lib/user_managment"
import { ThemeContext } from '../theme/ThemeContext';
import Utils from '../utils'
import Breadcrumb from './Breadcrumb';
import { Toast as Toast1 } from './Notify';

const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID || "bd6c8852-9c10-4a07-be44-d0a696adfbdf"

function CustomNotificationCenter() {
    const dispatch = useDispatch()
    const { socket } = useSocket();
    async function getServerList(){
        await UserMgt.get_user_vps()
        await K8S.getK8sInstance()
    }

    useEffect(() => {
        if (socket) {
          socket.on("notification_received", (data) => {
            if(data?.message?.templateIdentifier === "test-notification"){ 
                Toast1.fire({
                    icon: 'success',
                    title: data.message?.content
                })
                getServerList()
                dispatch(forceReload())

            }  
          });
        }

        return () => {
          if (socket) {
            socket.off("notification_received");
          }
        };
    }, [socket]);

  return <></>;
}

const Content = () =>{
    const { t, i18n } = useTranslation();
    const { theme, setTheme } = React.useContext(ThemeContext);
    const [lang, setLang] = useState("en");
    const dispatch = useDispatch();
    const { dontDisplayAllItems } = useSelector(app => app.core)


    const handleLangChange = (value) => {
        setLang(value);
    };

    const detectTheme = () => {
        if (window.matchMedia) {
          if(window.matchMedia('(prefers-color-scheme: dark)').matches){
            return "dark"
          } else {
            return "light"
          }
        } else {
            return null
        }
    }


    useEffect(() => {
    }, [])

    return(
        <div className="w-[22rem] px-3 py-2 ">
            <div className="mt-3">
                <span className="font-semibold">Language</span>
                <div className="mt-3">
                    <Select
                        size="large"
                        className="w-full rounded-none"
                        placeholder="Browser default"
                        onChange={(lang) => {
                            setLang(lang)
                            let newLan = lang
                            if(lang==='default'){
                                newLan = navigator.language || navigator.userLanguage;
                            }
                            i18n.changeLanguage(newLan)
                        }}
                        options={[
                          {
                            value: 'default',
                            label: 'Browser default',
                          },
                          {
                            value: 'en',
                            label: 'English',
                          },
                          {
                            value: 'fr',
                            label: 'Français',
                          },
                        ]}
                    />
                </div>
            </div>

            <div className="mt-5">
                <span className="font-semibold">View mode - beta</span>
                <div className="mt-2">
                    <Radio.Group onChange={(e)=>setTheme(e.target.value)} value={theme}>
                        <Space direction="vertical">
                            <Radio 
                                className="font-bold" 
                                value={detectTheme()}
                            >
                                Browser default
                            </Radio>
                            <Radio className="font-bold" value={"light"}>Light</Radio>
                            <Radio className="font-bold" value={"dark"}>Dark</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </div>
            <div className={`pt-3 ${!Utils.isAdmin() && "hidden"}`}>
                <Checkbox
                    checked={!!dontDisplayAllItems}
                    onChange={(e) => {
                        dispatch(dontDisplayAllItemsItems(!!e.target.checked))
                    }}
                >
                    Demo mode
                </Checkbox>
            </div>
            <div className="pt-2">
                <Divider />
            </div>
            <div className="pt-2">
                <Link to="/profile" className="font-semibold">More user settings</Link>
            </div>
        </div>
    )
}

export default function AdminNavbar({ setShowSidebar }) {
    const navigate = useNavigate()
    const user = useSelector(app => app.user)
     const { theme } = React.useContext(ThemeContext);

    async function onLogout() {
        const result = await UserAPI.logout({ token: user?.refreshToken })
        if(result){
            if(window.$crisp){
                window.$crisp.push(["do", "session:reset"])
            }
            window.location.reload()
        }else{
            Toast1.fire({
                icon: 'error',
                title: "An error occured, if this persist, please tell us"
            })
        }
    }

    useEffect(() => {
        Crisp.configure(CRISP_WEBSITE_ID);
        window.$crisp?.push(["set", "user:email", [user?.user?.EmailAddr, user?.user?.emailSigned]]);
        window.$crisp?.push(["set", "user:nickname", [user?.user?.FirstName + (" " + user?.user?.LastName || "")]]);
        if (!!user?.user?.PhoneNumber) {
            window.$crisp?.push(["set", "user:phone", [user?.user?.PhoneNumber]]);
        }
        if (!!user?.user?.Address) {
            window.$crisp?.push(["set", "user:address", [user?.user?.Address + (", " + user?.user?.Address2 || "") + (", " + user?.user?.City || "") + (", " + user?.user?.ZipCode || "")]]);
        }
        if (!!user?.user?.Company) {
            window.$crisp?.push(["set", "user:company", [user?.user?.Company || "", {
                url: user?.user?.Website || "",
                description: user?.user?.Description || ""
            }]]);
        }
    }, [user])

    const items = [
        {
          label: 'My Account',
          key: '1',
          icon: <UserOutlined />,
        },
        {
          label: 'Logout',
          key: '2',
          icon: <LogoutOutlined />,
        }
    ]
    
    
    
    const handleMenuClick = (e) => {
        // message.info('Click on menu item.');
        console.log('click', e.key);
        if(e.key === "1") return navigate('/profile')
        if(e.key === "2") return onLogout()
    
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const langs = [
        {label: "English", value: "en"},
        {label: "French", value: "fr"}
    ];

    


    

    return (
        <nav className="bg-neutral-100 z-10 absolute w-screen top-0 left-0 dark:bg-darkSiderbar pl-2 pr-2 md:pl-8 md:pr-10">
            
            <div className="container max-w-full mx-auto flex items-center justify-between">
                <div className="flex justify-between items-center w-full">
                    <div className='flex items-center py-3  gap-2'>
                        <Breadcrumb />
                    </div>
                    <div className="flex items-center gap-4">
                        <NovuProvider 
                            subscriberId={user?.user?.Id} 
                            applicationIdentifier={"wXYj6y4VCfMW"}
                            
                        >
                            <PopoverNotificationCenter 
                                colorScheme={theme  }
                              
                            >
                                {({ unseenCount }) =>  {
                                        if(unseenCount){
                                            return (
                                                <Badge count={unseenCount} className="mt-2" >
                                                    <MdNotifications className="cursor-pointer text-primary text-2xl" /> 
                                                </Badge>
                                            )
                                        }
                                        return <MdNotifications className="cursor-pointer text-primary text-2xl" /> 
                                    }
                                }
                            </PopoverNotificationCenter>
                            <CustomNotificationCenter />
                        </NovuProvider>


                        <QuestionCircleFilled className="text-primary text-xl cursor-pointer" />

                        <Popover className="" placement="bottomRight" trigger="click" content={() =><Content />} title="">
                            <div className="w-7  flex items-end">   
                                <SettingFilled className=" text-primary text-xl cursor-pointer" />
                            </div>
                        </Popover>

                        <div className="hidden md:flex ">
                            {/* <NavbarInput placeholder="Search" /> */}
                            <div className="-mr-4 ml-6">
                                <div className="flex justify-center">
                                    <div>
                                        <div className="dropdown relative">
                                            <button
                                                className="
                                            dropdown-toggle
                                            px-6 
                                            py-2.5
                                            bg-primary
                                            text-white
                                            font-medium
                                            text-xs
                                            leading-tight
                                            uppercase
                                            rounded
                                            shadow-md
                                            hover:shadow-lg
                                            focus:shadow-lg focus:outline-none focus:ring-0
                                            active:bg-blue-800 active:shadow-lg active:text-white
                                            transition
                                            duration-150
                                            ease-in-out
                                            flex
                                            items-center
                                            whitespace-nowrap
                                            "
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {user?.user?.FirstName}
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="caret-down"
                                                    className="w-2 ml-2"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 320 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                                    ></path>
                                                </svg>
                                            </button>
                                            <ul
                                                className="
                                            dropdown-menu
                                            min-w-max
                                            absolute
                                            hidden
                                            bg-white
                                            dark:bg-darkSiderbar
                                            text-base
                                            z-50
                                            float-left
                                            py-2
                                            list-none
                                            text-left
                                            rounded-lg
                                            shadow-lg
                                            mt-1
                                            hidden
                                            m-0
                                            bg-clip-padding
                                            border-none
                                            "
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <span
                                                        className="
                                                dropdown-item
                                                text-sm
                                                py-2
                                                px-4
                                                font-normal
                                                block
                                                w-full
                                                whitespace-nowrap
                                                bg-transparent
                                                text-gray-700
                                                hover:bg-gray-100
                                                dark:hover:bg-black
                                                dark:text-darkTextColor
                                                "
                                                        role="button"
                                                    >
                                                        <Link to="/profile">
                                                        My Account
                                                        </Link>
                                                    </span>
                                                </li>
                                                {/* <li>
                                                <Link to="/compute/vps/deploy/trial?code=test">
                                                        My pro
                                                        </Link>
                                                </li> */}
                                                <li>
                                                    <span
                                                        className="
                                                dropdown-item
                                                text-sm
                                                py-2
                                                px-4
                                                font-normal
                                                block
                                                w-full
                                                cursor-pointer
                                                whitespace-nowrap
                                                bg-transparent
                                                text-gray-700
                                                hover:bg-gray-100
                                                dark:hover:bg-black
                                                dark:text-darkTextColor
                                                "
                                                role="button"
                                                onClick={()=>onLogout()}
                                                    >Logout</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="-mr-4 ml-6">
                            <div className="flex justify-center">
                                <div>
                                <Dropdown menu={menuProps}>
                                    <Button className='min-w-[160px]' type="primary">
                                        <Space>
                                            {user?.user?.FirstName}
                                        <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                                    
                                </div>
                            </div>
                        </div> */}

                        <div className="md:hidden">
                            <div>
                                <input className="menu_hamburger1"  onClick={() => setShowSidebar()} type="checkbox" id="menu_hamburger1"/>
                                <label htmlFor="menu_hamburger1">
                                    <span></span>
                                </label>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

