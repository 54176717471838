import { CheckOutlined } from '@ant-design/icons'
import { Button } from 'antd';
import React from 'react'
import { useContext } from 'react';
import { NavLink } from "react-router-dom";

import { ThemeContext } from '../../theme/ThemeContext';
import { CardLayout } from 'layouts/CardLayout';

const icon = require('../../assets/img/tap.png')

const Title = ({title}) => (
	<span className="text-gray-700 w-full xl:w-[500px]  text-3xl md:text-4xl  font-bold xl:text-5xl text-left pb-2">
		{title}
	</span>
)

const Desc = ({desc}) => (
	<p className="lg:pt-8 pt-5 leading-8 w-full w-full 2xl:w-[670px]  flex pt-5 text-left text-base 2xl:text-[18px] text-gray-700 font-semibold ">
		{desc}
	</p>
)

const SubTitle = ({ title, src}) => (
	<div className="flex h-auto lg:h-12 gap-2  items-start text-primary font-semibold text-base 2xl:text-[17px]">
		<img src={src ? require('../../assets/img/preview/'+src) : icon} className="w-6 h-6   " />

		<span> {title} </span>
	</div>
)

const SubDesc = ({text}) => (
	<p className="text-gray-500 flex text-sm 2xl:text-base font-semibold ">
		{text}
	</p>
)

const SubCard = ({title, text, icon, src, last}) => (
	<div 
		className={`
				
				flex-col
                items-center  
                items-start
                text-left
				mt-3 lg:mt-0
			`}
		>
		<SubTitle src={src} icon={icon} title={title} />
		<div className="h-2"/>
		<SubDesc text={text} />
	</div>
)

const CardBottom = ({ items }) => (
	<div className="grid xl:grid-cols-3 gap-3 items-start">
		{
			items && items?.length && items.map((item, k) =>{
				return(
					
					<SubCard 
						{...item}
						key={k}
					/>
				)
			})
		}
	</div>
)

const OptionCard = ({ link, options, buttonLabel }) => (
	<CardLayout className=" w-full lg:w-[400px] w-full my-3 px-4 py-3">
		{
			options && options?.length && options.map((option, k) =>{
				return(
					<p key={k} className="flex py-2 items-start gap-2 font-semibold text-primary text-sm xl:text-base">
							<CheckOutlined className="mt-[4px]" style={{ color: 'blue' }}/>
							{option}
					</p>
				)
			})
		}
		<div className="pt-6">
			<NavLink to={link}>
				<Button type="primary" className="w-full h-10 font-semibold text-sm 2xl:text-base" >
					{buttonLabel}
	            </Button>
			</NavLink>
		</div>
	</CardLayout>
)

export const Preview = ({page}) => {
	const { theme } = useContext(ThemeContext);

	return(
		<div className={'h-full overflow-y-auto '+ (theme !== "dark" ? "  preview-back " : "")}>
			<div className={"h-full mt-6 w-full xl:w-full 2xl:w-[1200px] mx-auto dark:text-darkTextColor"}>
				<div className="px-3 md:px-5">
					<div className="lg:flex justify-center">
						<div className="pr-3 w-full  flex flex-col lg:items-start">
							<Title title={page.title} />
							<div className="">
								<Desc desc={page.desc} />
							</div>
						</div>
						<div className="lg:w-2/5 w-full  flex justify-center lg:justify-start">
							<OptionCard
								options={page.options} 
								link={page.link} 
								buttonLabel={page.buttonLabel} 
							/>
						</div>
					</div>
					<div className="pt-2 xl:pt-4">
						<div className="text-gray-700 text-xl md:text-2xl font-semibold  text-left pb-2">
							Key features
						</div>
						<div className="py-5 md:py-8">
							<CardBottom items={page.items} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}