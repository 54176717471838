const fr =  {
	//login
	"Email": "E-mail",
	"Email here": "Votre e-mail ici",
	"Password": "Mot de passe",
	"Password here": "Votre mot de passe ici",
	"Log in": "Se connecter",
	"Ongoing": "En cours",
	"Not a member yet ?": "Pas encore de compte ?",
	"Sign up": "S'enregistrer",
	"Forgot your password?": "Mot de passe oublié?",
	"Signed in successfully": "Connecté avec succès",
	//Register
	"Success! Please check your a mailbox and active your account !": "Succès! Veuilez activez votre compte au travers du liens qui vous a été envoyer dans un courrier !",
	"The password must be at least 6 characters !": "Le mot de passe doit contenir au-moins 6 charactères",
	"Sign Up with Email": "S'enregistrer par e-mail",
	"Sign up with": "S'enregistrer avec",
	"instead": "plutôt",
	"or": "ou",
	"Full name": "Nom complet",
	"Enter a full name": "Entrez votre nom complet",
	"Email address": "Addresse e-mail",
	"Enter your email address": "Entrez votre addresse e-mail",
	"Enter a password": "Entrez le mot de passe",
	"Already have account ?": "Vous avez déja un compte ?",
	"By signing up, you agree to the": "En vous enregistrent, vous acceptez les ",
	"Terms of Service": "Conditions d'utilisations",
	"and": "et",
	"Privacy Policy": "Politique de confidentialité",
	//Sign up method
	"Create your account": "Créer votre compte",
	"KeepSec is securing your future, one byte at a time!": "KeepSec sécurise votre future, un byte à la fois!",
	//Reset pass
	"New  password reset form": "Réinitialisation de mot de passe",
	"New password": "Nouveau mot de passe",
	"Confirmation": "Confirmation",
	"Reset now": "Réinitialiser",
	//Request reset pass
	"Request password reset form": "Démande de réinitialisation de mot de passe",
	"Enter the email addres": "Entrez votre addresse e-mail",
	"Send now": "Envoyer maintenant",
	//ACCOUNT VERIFICATION
	"Account verification": "Vérification du compte",
	"login now": "Se connecter",

	//dashboard
	"Good morning": "Bon matin",
	"Good afternoon": "Bonne après-midi",
	"Good evening": "Bonne soirée"

}

export default fr