import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, DownOutlined, EyeFilled, MoreOutlined } from '@ant-design/icons';
import { Button, Collapse, Dropdown, Modal, Popover, Table, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { CardLayout } from 'layouts/CardLayout';

import Utils from '../../utils'
import PaymentMethods from "./PaymentMethods"

const paypal = require('../../assets/img/billing/paypal.png')
const credit = require('../../assets/img/billing/credit-card.png')


export function Overview({payments}){

	const collapseItems = [
	  {
	    key: '1',
	    label: <div className="flex justify-between">
	    		<div>Kubernetes(1)</div>
	    		<div> <Link className="hover:underline"  to="/">View Kubernetes </Link></div>
	    		<div className="font-semibold">$0.00</div>
	    </div>,
	    children: <p>{""}</p>,
	  },
	  {
	    key: '2',
	    label: <div className="flex justify-between">
	    		<div>Networking(1)</div>
	    		<div> <Link className="hover:underline"  to="/">View Networking </Link></div>
	    		<div className="font-semibold">$0.00</div>
	    </div>,
	    children: <p>{""}</p>,
	  },
	];

	return(
		<div className="">
			<div>
				<span className="font-bold text-primary text-xl 2xl:text-2xl">
					Overview
				</span>
			</div>
			<div className="pt-5 pb-2">
				<div className="w-full ">
					<CardLayout className=" px-4 py-4  mt-2 "> 
						<span className="text-base 2xl:text-xl font-semibold">
							Estimated Due
						</span>
						<br />
						<span className="text-2xl 2xl:text-3xl  font-semibold">
							$0.00
						</span>
						<br />
						<p className="text-sm 2xl:text-base text-gray-500 pb-2">
							This is an estimate of the amount you own based curent month-to-date usage
							after credit & prepayments.
						</p>
						<hr/>
						<div className="pt-3">
							<div className="flex items-center justify-between">
								<p>
									<span className="text-sm 2xl:text-base text-gray-500">Payment due</span>
									<br/>
									<span className="text-sm xl:text-base font-semibold">
										April, 01 2025
									</span>
								</p>
								<p>
									<span className="text-sm 2xl:text-base text-gray-500">Prepayments</span>
									<br/>
									<span className="text-sm xl:text-base font-semibold">
									$0.00
									</span>
								</p>
								<p>
									<span className="text-sm 2xl:text-base text-gray-500">Total Usage</span>
									<br/>
									<span className="text-sm xl:text-base font-semibold">
										$0.00
									</span>
								</p>
							</div>
						</div>
					</CardLayout>
				</div> 
			</div>
			<div className="w-full pt-5">
				<div className="flex flex-wrap justify-between items-end ">
					<p>
						<span className="text-md 2xl:text-xl font-semibold">
							Month-to-Date summary March 1 - 5, 2024
						</span>
						<br/>
						<span className="text-sm 2xl:text-base text-gray-500 pb-2">
							These charges are factored into your account balance.
						</span>
					</p>
					<div>
						<Button type="default" icon={<DownOutlined />}>
				            Expand all
				        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
				        <Button type="default" icon={<DownloadOutlined />}>
				            Download
				        </Button>
					</div>
				</div>
				<div className="w-full mt-4  bg-gray-50">
					<Collapse 
						items={collapseItems} 
						defaultActiveKey={['1']} 
						// onChange={onChange} 
					/>
					<div className="w-full p-4">
						<div className="flex justify-between">
							<div className="w-[60%]"></div>
							<div className="text-left  w-1/5 font-semibold">Subtotal</div>
							<div className="w-1/2 md:w-1/3 text-right font-semibold  w-1/5">$0.00</div>
						</div>
						<div className="flex justify-between">
							<div className="w-[60%]"></div>
							<div className=" text-left  w-1/5 font-semibold">Taxes</div>
							<div className="w-1/2 md:w-1/3 text-right font-semibold  w-1/5">$0.00</div>
						</div>
						<div className="flex mt-2 justify-between">
							<div className="w-[60%]"></div>
							<div className="text-left w-1/5 font-semibold">
								<hr/>
								TOTAL
							</div>
							<div className="w-1/2 md:w-1/3 text-right font-semibold  w-1/5">
								<hr/>
								$0.00
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5 pb-2"> 
                <PaymentMethods /> 
            </div>
			<div className="pt-5 pb-2">
	    		{/*<Table loading={!payments} dataSource={payments} columns={columns} />*/}
			</div>
	    </div>
	)
}
export function Payments({payments}){

    const { messageApi } = useSelector(app => app.core)


	const items = [
      {
        label: 'View invoice',
        key: '1',
        icon: <EyeFilled />,
        // disabled: !!data?.isPrimary,
      }
    ];
    async function handleMenuClick(e){
      let result;
      if(e.key === "1"){
        // setLoandingAction({[data.id]: true})
        // result = await UTILITY.deletePaymentMethod({paymentMethodId: data.id, update: true})
        // setLoandingAction({})
      }
      if(!result?.error){
        messageApi.success('Done!')
      }else{
        // messageApi.error(STANDARD_ERROR_MESSAGE)
      }
    }

	const menuProps = {
      items: items.reverse() ,
      onClick: handleMenuClick,
    };

	const columns = [
	  {
	    title: 'Type',
	    render: (_, record) => (
		    <div>
				{(record?.paymentMethod?.type === "paypal" || !record?.paymentMethodId)?
		            <img src={paypal} className="w-6" />
				    :
				    record?.paymentMethod?.type === "stripe" ?
				    <div className="stripe">
				        <img src={credit} className="w-6" />
				    </div>
				    :
				    "Unknow"
				}
			</div>
		)
	  },
	  {
	    title: 'Date',
	    render: (_, record) => (
	    	<div>
	    		
                {Utils.formatDate(record?.PaymentDate, 'Do MMMM YYYY')}
          	</div>
	    )
	  },
	  {
	    title: 'Description',
	    dataIndex: 'description',
	  },
	  {
	    title: 'Amount',
	    render: (_, record) => (
	    	<span>
                ${record?.AmountPaid}
          	</span>
	    )
	  },
	  {
	    title: 'Status',
	    render: (_, record) => (
	    	<span>
	    	{record?.Status === "success"?
                <Tag icon={<CheckCircleOutlined />} color="success">
			        Success
			    </Tag>
			    :
			    <Tag icon={<CloseCircleOutlined  />} color="error">
			        Error
			    </Tag>
			}
          	</span>
	    )
	  },
	  {
	    title: '',
	    render: (_, record) => (
	    	<Dropdown trigger={['click']} menu={menuProps}>
                <MoreOutlined />
          	</Dropdown>
	    )
	  }
	];

	return(
		<div className="">
			<div>
				<span className="font-bold text-primary text-xl 2xl:text-2xl">
					Billing history
				</span>
			</div>
			<div className="pt-5 pb-2">
	    		<Table loading={!payments} dataSource={payments} columns={columns} />
			</div>
	    </div>
	)
}