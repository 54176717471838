import { createStore, combineReducers } from 'redux';
import user from './store/userReducer';
// import captain from './store/captainReducer';
import vps from './store/vpsReducer';
import webapp from './store/webappRedurcer';
import coreapp from './store/coreReducer';
import vullify from './store/vullifyReducer';
import k8s from './store/kubernetesRedurcer';
import kwaf from './store/kwafReducer';
import kslb from './store/kslbReducer';
import network from './store/networkReducer';

const rootReducer = combineReducers({
    user: user,
    // captain: captain,
    vps: vps,
    vullify: vullify,
    webapp: webapp,
    core: coreapp,
    k8s: k8s,
    kwaf: kwaf,
    kslb: kslb,
    network: network
})
export default createStore(rootReducer);