import React, { useState, useEffect } from 'react'
import { 
		Button, Drawer, Steps, Radio, 
		Input, Typography, InputNumber, Badge,
		Popconfirm
    } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import UserAPI from "../../lib/keepsec_auth_service"
import { CopyFilled, CopyOutlined, DownloadOutlined } from '@ant-design/icons'; 
import { PinInput } from 'react-input-pin-code';
import Utils from "../../utils"
import { useSelector } from 'react-redux';

const { Paragraph, Text } = Typography;

const steps = [
      { title: 'Choose method' },
      {
        title: 'Verify'
      },
      {
        title: 'Backup codes '
      },
]

function border(id, inspect){
	return id === inspect ? "border-primary bg-[#011a430d] " : "border-gray-200"
}
export default function TwoFactorSetup(props){
	const {messageApi} = useSelector(app => app.core )
	const [open, setOpen] = useState(false);
	const [loadingNext, setLoadingNext] = useState(false);
	const [current, setCurrent] = useState(0);
	const [data, setData] = useState({method: "app", token: ['', '', '', '', '', '']});
	const [dataQR, setSrcQr] = useState({});
	const [recoveryCode, setRecoveryCode] = useState(null);

  	const toogleDrawer = () => {
    	setOpen(!open);
  	};

  	const next = async (current) => {
  		setLoadingNext(true)
  		if(current === 0){
  			if(data.method === "app"){
  				const resultCreate2fa = await UserAPI.create2fa(data)
	  			if(resultCreate2fa?.qrcode){
		  			setSrcQr({
		  				src: resultCreate2fa?.qrcode,
		  				tempSecret: resultCreate2fa?.base62
		  			})
	  			}else{
	  				messageApi.open({
		              type: 'error',
		              content: 'An error occurs, please try again.',
		            });
		            return setLoadingNext(false)
	  			}
	  		}else{
	  			messageApi.open({
	              type: 'error',
	              content: 'This 2FA method is not ready yet.',
	            });
	            return setLoadingNext(false)
	  		}
  		}
  		if(current === 1){
  			if(data.method === "app"){
	  			const token = data?.token?.join('')
	  			if(token && token.length === 6){
		  			const resultVerify2fa = await UserAPI.verifyToken({
		  				token,
		  				validateSecret: true
		  			})
		  			console.log('resultVerify2fa resultVerify2fa', resultVerify2fa)
		  			if(!resultVerify2fa?.isVerified){

		  				messageApi.open({
			              type: 'error',
			              content: "An errors occurs, please it check again.",
			            });
		  				return setLoadingNext(false)
		  			}
		  			setRecoveryCode(resultVerify2fa?.recovCode)
		  		}else{
		  			setLoadingNext(false)
		  			return messageApi.open({
		              type: 'error',
		              content: "6 digits code is incorrect.",
		            });
		  		}
	  			setData({...data, token: ['', '', '', '', '', '']})
	  		}else{
	  			return setLoadingNext(false)
	  		}
  		}
    	setCurrent(current + 1);
  		setLoadingNext(false)
  	};
  	const prev = () => {
    	setCurrent(current - 1);
  	};



	return(
		<div>
			<Button 
	            className="mx-auto w-32 font-semibold h-10 2xl:h-12 rounded" 
	            type="primary"
	            onClick={toogleDrawer}
	        >
	            Set Up 2FA
	        </Button>
			<Drawer 
				style={{backgroundColor: "#FAFAFA"}}
				width="100VW"
				title={<span className="font-bold text-base 2xl:text-xl">2FA Setup</span>}
				onClose={toogleDrawer}  
				closeIcon={null}
				open={open}
			>
		        <div className="flex h-full items-center justify-center">
		        	<div className="">
                        <h3 className="text-base  text-primary xl:text-xl font-bold">
                        	Set up two factor authentification
                        </h3>
				        <div 
				        	className="
				        		border 
				        		shadow
				        		border-1 
				        		border-gray-200 
				        		p-6
				        		mt-3 
				        		bg-white
				        		w-[850px] 
				        		h-[82VH]
				        		flex 
				        	"
				        >
				        	<div className="border-r border-r-2 w-[25%]">
					        	<Steps
								    direction="vertical"
								    // size="small"
								    className={"font-semibold text-primary"}
								    current={current}
								    items={steps}
								/>
							</div>
							<div className="w-[75%] grid grid-cols-1 content-between pl-5">
								<div className="">
									{current === 0 ?
										<div className="">
											<div className="">
												<h3 className="font-bold text-base 2xl:text-xl text-primary">
						                        	Choose an authentification method
						                        </h3>
						                        <p className="mt-1 text-sm 2xl:text-base">
						                        	In addition to your username and password,
						                        	you will have to enter a code
						                        	(delivered via app or SMS) to sign in to your account
			                                    </p>
											</div>

											<div className=" w-full pt-5">
												<Radio.Group required  value={data?.method} className="w-full">
													<div className=" ">
														<div 
															className={`
																border
																border-2
																${border("app", data?.method)}
																px-3
																h-24
																cursor-pointer
																flex
																w-[430px]
																gap-3
																justify-between
																items-center
															`}
															onClick={() => setData({...data, method: 'app'})}
														>
													    	<Radio value={"app"}></Radio>
															<div className="text-sm 2xl:text-base">
																<p>
																	<span className="font-semibold text-primary text-base">
																		Authentification App&nbsp;
																		<Badge
																	        count={"Recommended"}
																	        style={{
																	          backgroundColor: '#011a43',
																	        }}
																	    />
																	</span>
																	<br/>
																	<span className="">
																		Get codes from an app like Authly, 1Password, 
							                        					Microsoft Authentificator or Google Authentificator
																	</span>
																</p>
															</div>
														</div>
														<div 
															className={`
																border
																border-2
																${border("sms", data?.method)}
																px-3
																h-24
																mt-3
																w-[430px]
																cursor-pointer
																flex
																gap-3
																items-center
															`}
															onClick={() => setData({...data, method: 'sms'})}
														>
													    	<Radio value={"sms"}></Radio>
															<div className="text-sm 2xl:text-base">
																<p>
																	<span className="font-semibold text-primary text-base">
																		SMS
																	</span>
																	<br/>
																	<span className="">
																		Receive codes via SMS
																	</span>
																</p>
															</div>
														</div>
														
													</div>
											    </Radio.Group>
											</div>

										</div>
										:
										null
									}
									{current === 1 ?
										<div className="">
											{data?.method === "sms" ?
												<div className="">
													<div className="">
														<h3 className="font-bold text-base 2xl:text-xl text-primary">
								                        	Verify your phone number
								                        </h3>
								                        <p className="mt-1 text-sm 2xl:text-base">
								                        	Enter you cell phone number and we will send you a 
								                        	verification code
					                                    </p>
													</div>
													<div className="mt-5">
														<PhoneInput
														  country={'us'}
														  value={data.phone}
														  placeholder="### ### ###"
														  onChange={phone => setData({ ...data, phone })}
														/>

														<div className="mt-3">
															<Button type="primary">
													            Send SMS
													        </Button>
														</div>
													</div>
												</div>
												:
												<div>
													<div className="">
														<h3 className="font-bold text-base 2xl:text-xl text-primary">
								                        	Connect your app
								                        </h3>
								                        <p className="mt-1 text-sm 2xl:text-base">
								                        	Using an authentification app, scan the QR code below:
					                                    </p>
													</div>
													<div className="mt-5">
														<div className="">
															<img src={dataQR?.src} className="h-44 w-44" />
														</div>
														<p className="mt-1 text-sm 2xl:text-base">
								                        	Or copy the secret key for manual entry:
					                                    </p>
					                                    <div className="my-3 -ml-1">
					                                    	<Text className="text-sm 2xl:text-base" copyable code>
					                                    		{dataQR?.tempSecret}
					                                    	</Text>
					                                    </div>
					                                    <p className="mt-1 text-sm 2xl:text-base">
								                        	Once you have connected your app,
								                        	enter your most recent 6 digits verification
								                        	code for <b>KeepSec</b>
					                                    </p>
					                                    <div className="mt-3">
						                                    <span>
						                                    	<b>6 digits code</b>
						                                    </span>
						                                    <br/>
						                                    <div className="mt-1">
							                                    <PinInput
															      values={data?.token}
															      length={6}
															      onChange={(value, index, values) => setData({...data, token: values})}
															      // onComplete={(values) => next(current, values)}
															    />
														    </div>
					                                    </div>

													</div>
												</div>
												}
										</div>
										:
										null
									}
									{current === 2 ?
										<div className="">
											<div className="">
												<h3 className="font-bold text-base 2xl:text-xl text-primary">
						                        	Save backup codes
						                        </h3>
						                        <p className="mt-1 text-sm 2xl:text-base">
						                        	Make a copy of your codes, store them using a password
						                        	manager or in a encrypted file:
			                                    </p>
			                                    <div className="my-5 bg-gray-50">
				                                    <div className="p-3">
				                                     <table className="w-full">
				                                     	{recoveryCode && recoveryCode.split('-').map(codes =>{
				                                     		return(
							                                    <tr>
							                                    	{codes.split(',').map(code =>{
							                                    		return <td className="w-44 py-2 text-primary font-bold">{code}</td>
							                                    	})}
							                                    </tr>
				                                     		)
				                                     	})}
					                                     
				                                     </table>

				                                    </div>

				                                    <div className="border-t-2 border-t p-3">
				                                    	<Button 
				                                    		type="link" 
				                                    		className="font-bold"
				                                    		icon={<CopyOutlined />}
													     	onClick={() => recoveryCode && Utils.onCopyText(recoveryCode)}
				                                    	>
													        Copy
													     </Button>

													     <Button 
													     	type="link" 
													     	className="font-bold"
													     	icon={<DownloadOutlined />}
													     	onClick={() => recoveryCode && Utils.createK8SFile(null, recoveryCode)}
													     >
													        Download as .txt file
													     </Button>

				                                    </div>
			                                    </div>

			                                    <p className="mt-3 text-sm 2xl:text-base">
						                        	If you lose access to your default authentification
						                        	method, use one of these codes to regain access to
						                        	your account.
			                                    </p>
											</div>
										</div>
										:
										null
									}
								</div>

								<div className="flex gap-3">
							          <Button
							            onClick={() => current > 0 ? prev() : setOpen(false)}
							            disabled={current === 2}
							          >
							            {current > 0 ? "Previous" : "Cancel"}
							          </Button>
									{current < steps.length - 1 && (
							          <Button 
							          	type="primary" 
							          	onClick={() => next(current)}
							          	loading={loadingNext}
							          >
							            Next
							          </Button>
							        )}
							        {current === steps.length - 1 && (
							        	<Popconfirm
								          placement="top"
								          title={"Confirm"}
								          description={"Are you really sure that you saved your backup codes ? "}
								          okText="Yes, I am"
								          onConfirm={() => {
								          	setOpen(false)
								          	setData({method: "app", token: ['', '', '', '', '', '']})
								          	setCurrent(0)
								          	props.onFetchProfile()
								          }}
								          cancelText="No, I'm not"
								        >
								          <Button 
								          	type="primary" 
								          	>
								            Finish
								          </Button>
								        </Popconfirm>
							        )}
								</div>
							</div>
				        	
				        </div>
				    </div>
		        </div>
		    </Drawer>
		</div>
	)
}
